import { knownPreferences } from '../../user/preferences-factory';

class StudentLicensesBarController {
    // @ngInject
    constructor($state, preferences, userAccountService) {
        this.$state = $state;
        this.preferences = preferences;
        this.userAccountService = userAccountService;

        this.fteTooltipIsOpen = false;

        if (this.schoolId && preferences.shouldDisplayFTELimitTooltip()) {
            this.openFTELimitReachedTooltip();
        }
    }

    openFTELimitReachedTooltip() {
        this.fteTooltipIsOpen = true;
        this.fteTooltipOffset = 20;
        this.fteTooltipTarget = '.license-count-in-use';
        const studentsUrl = this.$state.href('admin.school.students.index', {
            schoolId: this.schoolId,
        });
        this.fteLimitReachedTooltipBody = `
                You've reached the trial accounts limit. Manage your accounts on
                the <a href='${studentsUrl}'>Student Accounts page</a>.
        `;
        this.onFTETooltipClose = () => {
            this.preferences.setDisplayFTELimitTooltipDismissed(true);
            let prefs = {
                [knownPreferences.teacher_trial_fte_limit_tooltip_dismissed]: true,
            };
            this.userAccountService.storePreferences(prefs);
        };
    }
}

export default {
    templateUrl: 'admin/student-licenses-bar/student-licenses-bar-component.html',
    bindings: {
        schoolId: '@',
        schoolName: '@',
        licenseMax: '<',
        licenseInUse: '<',
        licenseTermStart: '<',
        licenseTermEnd: '<',
        showMaxLicenses: '<',
        showLicenseCountOnly: '<',
        fteLimitReached: '<',
    },
    controller: StudentLicensesBarController,
};
