import angular from 'angular';

import CreateAdminAccountSuccessController from './create-admin-account-success-controller';

const createAdminAccountSuccess = angular.module('createAdminAccountSuccess', []);
createAdminAccountSuccess.controller(
    'CreateAdminAccountSuccessController',
    CreateAdminAccountSuccessController
);

export default createAdminAccountSuccess.name;
