//remotes
import angular from 'angular';
//locals
import DistrictAdminHomeController from './district-admin-home-controller';
import MultiSchoolAdminHomeController from './multi-school-admin-home-controller';
import studentLicensesBar from '../student-licenses-bar/student-licenses-bar';
import googleSsoBar from '../google-sso-bar/google-sso-bar';
import schoolsTable from '../schools-table/schools-table';
import adminShared from '../../admin/shared/shared';
import payment from '../../payment/payment';

//module
const adminHome = angular.module('adminHome', [
    googleSsoBar,
    studentLicensesBar,
    schoolsTable,
    adminShared,
    payment,
]);

//injections
adminHome.controller('DistrictAdminHomeController', DistrictAdminHomeController);
adminHome.controller('MultiSchoolAdminHomeController', MultiSchoolAdminHomeController);
//exports
export default adminHome.name;
