import { POLLING_FORBIDDEN } from '../payment-service';

export default class PaymentPendingController {
    // @ngInject
    constructor(
        shouldPoll,
        $location,
        $log,
        $scope,
        $state,
        $stateParams,
        paymentService,
        user,
        preferences
    ) {
        this.$location = $location;
        this.$log = $log;
        this.$scope = $scope;
        this.$state = $state;
        this.paymentService = paymentService;
        this.userFactory = user;
        this.user = user.getUser();
        this.preferences = preferences;

        this.order = {
            number: $stateParams.orderNumber,
            processed: false,
            status: 'pending',
            details: null,
        };
        this.isPolling = true;

        // This is to trigger polling when the state provider enters the
        // payment-pending state and *not* when initializing this controller
        // during tests.
        if (shouldPoll === true) this.pollForOrderStatus();
    }

    pollForOrderStatus() {
        const { result, cancel } = this.paymentService.pollOrderStatus(this.order.number);
        this.cancel = cancel;

        result.then(data => {
            this.$log.debug('<PaymentPendingController.pollForOrderStatus> order data');
            this.$log.debug(data);
            this.isPolling = false;
            this.order.processed = true;
            this.order.status = data.status;
            this.order.details = data.details;

            if (this.order.status === 'success') {
                this.$log.debug('unsetting teacher trial preference');
                this.preferences.setTeacherTrialPromotionUpgradeComplete();
                this.userFactory.refreshPermissions();
            }
        });

        result.catch(exc => {
            this.$log.debug(exc);
            this.isPolling = false;
            if (exc.type === POLLING_FORBIDDEN) this.$state.go('unauthorized');
        });

        this.$scope.$on('$destroy', () => this.cancel());
    }

    showPollingForOrderStatus() {
        return !this.order.processed && this.isPolling;
    }

    showNotPollingForOrderStatus() {
        return !this.order.processed && !this.isPolling;
    }

    showOrderWasProcessedWithSuccess() {
        return this.order.processed && this.order.status === 'success';
    }

    showOrderWasProcessedWithFailure() {
        return this.order.processed && this.order.status === 'failure';
    }
}
