export const assignmentTypes = {
    signalCheck: 'signal check',
    spotCheck: 'spot check',
    expansionPack: 'expansion pack',
};

export default function() {
    // TODO: This will eventually no longer be needed once we move over all assignment types
    const legacyToCurrentAssignmentTypes = {
        RA: 'signal check',
        TM: 'spot check',
        BC: 'expansion pack',
    };

    return {
        // TODO: This will eventually no longer be needed once we move over all assignment types
        convertLegacyToCurrentAssignmentType(type) {
            if (legacyToCurrentAssignmentTypes.hasOwnProperty(type)) {
                return legacyToCurrentAssignmentTypes[type];
            }
            return type;
        },
    };
}
