import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from 'angular-ui-router';

import notifyDataLossService from './notify-data-loss-service';

const formUtils = angular.module('formUtils', [eventMediator, uiBootstrap, uiRouter]);
formUtils.factory('notifyDataLossService', notifyDataLossService);

export default formUtils.name;
