export const MIN_PING_DELAY_IN_MS = 1000;

/**
 * Provides the ability to actually download the usage report.
 * This is a multi step process exposed as a single promise:
 *
 * 1. HTTP POST to "create" the report - server responds with a URL to the
 *    finalized report.
 * 2. HTTP GET continually pinging the URL from step 1 until it returns `status: complete`
 * 3. HTTP POST (via postDownloadFactory) to the URL from step 1 once step 2 completes
 *
 * A consumer of this code does NOT need to know that though.
 * Calling `download` returns you a promise that resolves when the report has been downloaded.
 */
export default ($http, $timeout, $q, urls, postDownloadFactory, dateFilter) => {
    'ngInject';

    const formatDate = date => dateFilter(date, 'yyyy-MM-dd');

    function queueReport(districtId, data) {
        const url = `${urls.base}/ra/admin/district/${districtId}/report`;
        return $http({ url, method: 'POST', data }).then(x => x.data.report_url);
    }

    function pingUntilReportIsCompleted(url) {
        const request = $http({ url });
        const minDelay = $timeout(MIN_PING_DELAY_IN_MS);

        return $q.all([request, minDelay]).then(([res]) => {
            const { status } = res.data;
            if (status === 'complete') {
                return url;
            }

            return pingUntilReportIsCompleted(url);
        });
    }

    function downloadFinalizedReport(reportUrl) {
        return postDownloadFactory.download(reportUrl);
    }

    function downloadFailure(details) {
        const message = 'Oops! There was a problem generating your report. Please try again later.';
        const error = { message, details };
        return $q.reject(error);
    }

    function download(districtId, params) {
        const postData = {
            start_date: formatDate(params.startDate),
            end_date: formatDate(params.endDate),
            schools: params.schools,
        };

        return queueReport(districtId, postData)
            .then(pingUntilReportIsCompleted)
            .then(downloadFinalizedReport)
            .catch(downloadFailure);
    }

    return {
        download,
    };
};
