import _ from 'lodash';

class SchoolSelectionController {
    /* @ngInject */
    constructor(urls) {
        this.urls = urls;
    }

    $onInit() {
        this.schoolToAdd = {};
    }

    $onChanges(changes) {
        const model = _.get(changes, 'model.currentValue');
        this.uiModel = model ? this.createUiModel(_.cloneDeep(model)) : this.uiModel;
    }

    sortAvailableSchools(availableSchools) {
        return _.sortBy(availableSchools, 'name');
    }

    createUiModel(setupInfo) {
        const [selectedSchools, availableSchools] = _.partition(
            setupInfo.availableSchools,
            school => setupInfo.suggestedSchools.includes(school.id)
        );

        return {
            district: setupInfo.districtName,
            selectedSchools,
            availableSchools: this.sortAvailableSchools(availableSchools),
        };
    }

    handleSchoolSelected() {
        const available = _.reject(
            this.uiModel.availableSchools,
            ({ id }) => id === this.schoolToAdd.id
        );
        this.uiModel.availableSchools = this.sortAvailableSchools(available);
        this.uiModel.selectedSchools = [...this.uiModel.selectedSchools, this.schoolToAdd];
        this.schoolToAdd = null;
    }

    handleSchoolRemoved(schoolToRemove) {
        this.uiModel.selectedSchools = _.reject(
            this.uiModel.selectedSchools,
            ({ id }) => id === schoolToRemove.id
        );

        const available = [...this.uiModel.availableSchools, schoolToRemove];
        this.uiModel.availableSchools = this.sortAvailableSchools(available);
    }

    createOutGoingModel(uiModel) {
        const schools = _.map(uiModel.selectedSchools, 'id');
        return {
            suggestedSchools: schools,
            membershipGroupIds: schools,
        };
    }

    back() {
        const model = this.createOutGoingModel(this.uiModel);
        this.onBack({ model });
    }

    submit() {
        if (this.uiModel.selectedSchools.length <= 0) {
            return;
        }

        const model = this.createOutGoingModel(this.uiModel);
        this.onSubmit({ model });
    }
}

export default {
    templateUrl: 'login/shared/school-selection/school-selection-component.html',
    controller: SchoolSelectionController,
    bindings: {
        model: '<',
        isBackVisible: '<',
        onBack: '&',
        onSubmit: '&',
    },
};
