import angular from 'angular';

import tiDateDirective from './ti-date-directive';
import passwordMatchDirective from './password-match-directive';
import optionListComponent from './option-list-component';
import emailDirective from './email-directive';

const utilsForm = angular.module('utilsForm', [tiDateDirective]);
utilsForm.directive('passwordMatch', passwordMatchDirective);
utilsForm.directive('email', emailDirective);
utilsForm.component('optionList', optionListComponent);

export default utilsForm.name;
