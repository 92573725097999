import _ from 'lodash';

export default function bulkUploadFactory(urls) {
    'ngInject';

    const statusCssClasses = _.reduce(
        [
            'received',
            'validating',
            'created',
            'invalid',
            'processing',
            'unsuccessful',
            'canceled',
            'successful',
            'deleting',
            'deleted',
        ],
        (aggregate, status) =>
            _.assign({}, aggregate, { [status]: `order-status-${_.kebabCase(status)}` }),
        {}
    );

    function getUploadLinks(upload) {
        const { status, id } = upload;
        const { base } = urls;
        const baseUploadLink = `${base}/ra/idbatch/uploads/${id}`;

        const downloadLink = `${baseUploadLink}/original`;
        const errorFileLink = `${baseUploadLink}/validation`;
        const resultFileLink = `${baseUploadLink}/accounts`;

        const createdLink = `${baseUploadLink}/created`; // Accounts created
        const reviewLink = `${baseUploadLink}/review`; // Accounts requiring review
        const unchangedLink = `${baseUploadLink}/unchanged`; // Accounts not modified

        return {
            downloadLink,
            errorFileLink: _.includes(['unsuccessful', 'invalid'], status) ? errorFileLink : null,
            resultFileLink: _.includes(['successful'], status) ? resultFileLink : null,
            createdLink,
            reviewLink,
            unchangedLink,
        };
    }

    function getUploadId(order) {
        if (!order.firstUpload) return;

        // TODO move this to a more centralized place (probably the user module)
        const membershipTypes = {
            instructor: 'Teacher',
            learner: 'Student',
        };
        const upload = order.firstUpload;
        const humanReadableMembership = membershipTypes[upload.membershipType];
        return `${humanReadableMembership}-${order.code}`;
    }

    function decorateUpload(upload) {
        return _.flow([
            () => upload,
            transformKeysToCamelCase,
            upload =>
                _.assign({}, upload, getUploadLinks(upload), {
                    recordsUnchangedCount:
                        upload.recordsTotalCount -
                        upload.recordsCreatedCount +
                        upload.recordsInvalidCount,
                }),
        ])();
    }

    function decorateOrder(order, uploadDecorator = decorateUpload) {
        return _.flow([
            () => order,
            transformKeysToCamelCase,
            order => {
                const uploads = _.map(order.uploads, u => uploadDecorator(u));

                return _.assign({}, order, {
                    uploads,
                    firstUpload: _.head(uploads),
                    lastUpload: _.last(uploads),
                });
            },
            order =>
                _.assign({}, order, {
                    // TODO [Jesse N -- Nov 2017] check if we can remove the temp code below
                    code: order.code || order.identifier, // Temporary for transitional renaming
                    statusCssClass: statusCssClasses[order.status] || statusCssClasses.unknown,
                }),
            order => _.assign({}, order, { uploadId: getUploadId(order) }),
        ])();
    }

    const SHARED_ROLE_STATUSES = {
        'accounts created': {
            statuses: ['successful'],
            description: o => {
                if (!o.lastUpload) return '';

                return o.lastUpload.recordsCreatedCount >= 0
                    ? `${o.lastUpload.recordsCreatedCount} Accounts Created`
                    : 'Accounts Created';
            },
        },
        'accounts deleted': {
            statuses: ['deleted'],
            description: o => {
                if (!o.lastUpload) return '';

                return o.lastUpload.recordsDeletedCount >= 0
                    ? `${o.lastUpload.recordsDeletedCount} Accounts Deleted`
                    : 'Deleted';
            },
        },
        unknown: {
            statuses: [],
            description: _.constant('Please contact a customer support specialist.'),
        },
        canceled: {
            statuses: ['canceled'],
            description: _.constant('Canceled'),
        },
    };

    const IMPLEMENTATION_SPECIALIST_STATUSES = _.assign({}, SHARED_ROLE_STATUSES, {
        processing: {
            statuses: ['received', 'validating'],
            description: _.constant('Processing'),
        },
        'creation processing': {
            statuses: ['processing'],
            description: _.constant('Creation Processing'),
        },
        'deletion processing': {
            statuses: ['deleting'],
            description: _.constant('Deletion Processing'),
        },
        'new upload': {
            statuses: ['created'],
            description: _.constant('Ready to Process'),
        },
        'errors encountered': {
            statuses: ['invalid', 'unsuccessful'],
            description: _.constant('Errors Encountered'),
        },
    });

    const DISTRICT_ADMIN_STATUSES = _.assign({}, SHARED_ROLE_STATUSES, {
        'creation processing': {
            statuses: [
                'received',
                'validating',
                'created',
                'invalid',
                'processing',
                'unsuccessful',
            ],
            description: _.constant('Creation Processing'),
        },
    });

    function getStatusDescription(order, statusDescriptions = SHARED_ROLE_STATUSES) {
        return _.flow([
            () =>
                _.findKey(statusDescriptions, v => _.includes(v.statuses, order.status)) ||
                'unknown',
            category =>
                statusDescriptions[category].description ||
                statusDescriptions['unknown'].description,
            func => func(order),
        ])();
    }

    const IMPLEMENTATION_SPECIALIST = {
        getStatusDescription: order =>
            getStatusDescription(order, IMPLEMENTATION_SPECIALIST_STATUSES),
        decorateUpload,
        decorateOrder,
    };

    const DISTRICT_ADMIN = {
        getStatusDescription: order => getStatusDescription(order, DISTRICT_ADMIN_STATUSES),
        decorateUpload: upload => _.assign({}, decorateUpload(upload), { errorFileLink: null }),
        decorateOrder: (order, uploadDecorator) =>
            _.assign({}, decorateOrder(order, uploadDecorator), {
                reuploadable: false,
                cancelable: false,
            }),
    };

    function transformKeysToCamelCase(obj) {
        return _.mapKeys(obj, (value, key) => _.camelCase(key));
    }

    function decorateOrderForRole(order, role) {
        return _.flow([
            () => order,
            order =>
                _.assign({}, order, {
                    reuploadable: _.includes(['created', 'invalid', 'unsuccessful'], order.status),
                    cancelable: _.includes(['created', 'invalid', 'unsuccessful'], order.status),
                    deletable: _.includes(['successful'], order.status),
                }),
            order => role.decorateOrder(order, role.decorateUpload),
            order =>
                _.assign({}, order, {
                    statusDescription: role.getStatusDescription(order, role.statusDescriptionFns),
                }),
        ])();
    }

    function decorateOrderForIS(order) {
        return decorateOrderForRole(order, IMPLEMENTATION_SPECIALIST);
    }

    function decorateOrderForDA(order) {
        return decorateOrderForRole(order, DISTRICT_ADMIN);
    }

    function decorateOrderBasic(order) {
        return _.flow([() => order, () => decorateOrder(order, decorateUpload)])();
    }

    function getOrderDecorator(user) {
        const orderDecorationFn =
            _.find(
                {
                    isImplementationSpecialist: decorateOrderForIS,
                    isDistrictAdministrator: decorateOrderForDA,
                },
                (value, key) => user[key]()
            ) || decorateOrderBasic;

        return {
            decorateOrder: o => orderDecorationFn(o),
            decorateUpload,
        };
    }

    return { getOrderDecorator };
}
