import { isAuthorizedForTeacherTrialPayment } from './payment-authorization';

// @ngInject
export default function paymentRoutes($stateProvider) {
    $stateProvider
        .state('payment', {
            url: '/payment',
            templateUrl: 'payment/form/payment-form.html',
            controller: 'PaymentFormController as $ctrl',
            data: {
                isAuthorized: isAuthorizedForTeacherTrialPayment,
            },
            params: {
                trialExpired: false,
            },
            resolve: {
                salesContactEmail: /* @ngInject */ ($log, paymentService) => {
                    return paymentService.getSalesContact().then(
                        email => {
                            $log.debug('Retrieved: ', email);
                            return email;
                        },
                        fallbackEmail => {
                            $log.debug('Fallback: ', fallbackEmail);
                            return fallbackEmail;
                        }
                    );
                },
                stripeCheckoutInformation: /* @ngInject */ paymentService => {
                    return paymentService
                        .getStripeCheckoutInformation()
                        .then(stripeData => stripeData);
                },
            },
        })
        .state('payment-pending', {
            url: '/payment/:orderNumber',
            templateUrl: 'payment/pending/payment-pending.html',
            controller: 'PaymentPendingController as $ctrl',
            data: {
                isAuthorized: isAuthorizedForTeacherTrialPayment,
            },
            resolve: {
                shouldPoll: _.constant(true),
            },
        });
}
