export default ($state, preferences) => {
    'ngInject';

    /**
     * Determines if the given user is authorized for the given route.
     * Returns an object so that a message can be included for debugging purposes
     * as opposed to directly returning a bool.
     */
    function isAuthorizedForState(state, user, stateParams) {
        const isAuthorized = _.get(state, 'data.isAuthorized');
        if (!isAuthorized) {
            return {
                isAuthorized: false,
                message: `Denying access to ${state.name} because no isAuthorized method is defined.`,
            };
        }

        if (!isAuthorized(user, preferences, stateParams)) {
            return {
                isAuthorized: false,
                message: `User is unauthorized to view ${state.name}`,
            };
        }

        return {
            isAuthorized: true,
            message: null,
        };
    }

    /**
     * Resolves the given stateName and returns only the `isAuthorized` bool.
     */
    function isAuthorizedForStateName(stateName, user, params) {
        return isAuthorizedForState($state.get(stateName), user, params).isAuthorized;
    }

    return {
        isAuthorizedForState,
        isAuthorizedForStateName,
    };
};
