import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import urlConfig from '../../../url-config/url-config';
import EventMediator from 'gong-event-mediator';

import SendEmailController from './send-email-controller';
import sendEmailService from './send-email-service';
import utilsForm from '../../../utils-form/utils-form';

const sendEmail = angular.module('sendEmail', [
    uiRouter,
    urlConfig,
    uiBootstrap,
    EventMediator,
    utilsForm,
]);
sendEmail.factory('sendEmailService', sendEmailService);
sendEmail.controller('SendEmailController', SendEmailController);

export default sendEmail.name;
