class AdminBulkUploadStudentController {
    // @ngInject
    constructor(bulkUploadService, school, previousConfig) {
        this.onBulkUpload = bulkUploadService.createStudentAccountsInBulk;
        this.school = school;
        this.previousConfig = previousConfig;
    }
}

export default AdminBulkUploadStudentController;
