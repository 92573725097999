export default {
    template: `
        <div class="admin admin-home container-fluid ra-container processing-accounts">
            <div class="row">
                <div class="col-xs-12">
                    <div class="processing-accounts-content">
                        <h1>
                            Processing your file.
                        </h1>
                        <p>
                            This might take a little while. You can stay here
                            <br/>
                            until the file has been processed or go
                            <br/>
                            eat an ice cream and we’ll email you when we’re done.
                        </p>
                        <uib-progressbar class="active progress-striped"
                            ng-if="$ctrl.model.type === 'indeterminate'"
                            value="100"></uib-progressbar>
                        <uib-progressbar
                            ng-if="$ctrl.model.type === 'percent'"
                            value="$ctrl.model.percentComplete"></uib-progressbar>
                    </div>
                </div>
            </div>
        </div>
    `,
    bindings: {
        model: '<', // { type: 'indeterminate' } OR { type: 'percent', percentComplete: Int }
    },
};
