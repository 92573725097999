import angular from 'angular';

import teacherOnboardingCarouselComponent from './teacher-onboarding-carousel-component';

const teacherOnboardingCarousel = angular.module('teacherOnboardingCarousel', []);
teacherOnboardingCarousel.component(
    'teacherOnboardingCarousel',
    teacherOnboardingCarouselComponent
);

export default teacherOnboardingCarousel.name;
