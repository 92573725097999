// @ngInject
export default function studentRoutes($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/student', '/student/home');
    $stateProvider
        .state('student', {
            abstract: true,
            url: '/student',
            template: '<ui-view class="student" />',
            data: {
                isAuthorized(user) {
                    return user.hasLearnerRole();
                },
            },
        })
        .state('student.home', {
            url: '/home',
            templateUrl: 'student/student.html',
            controller: 'StudentController as studentController',
            resolve: {
                studentClassesData: /* @ngInject */ function(studentService) {
                    return studentService.getClasses();
                },
            },
        });
}
