import angular from 'angular';

import userAccount from '../../user-account/user-account';

import assignmentUtils from './assignment-utils';
import teacherService from './teacher-service';

const teacherShared = angular.module('teacherShared', [userAccount]);
teacherShared.factory('assignmentUtils', assignmentUtils);
teacherShared.factory('teacherService', teacherService);

export default teacherShared.name;
