import angular from 'angular';

import loginSharedModule from '../shared/shared';

import googleSignInFactory from './google-sign-in-factory';
import gapiFactory from './gapi-factory';

const googleSignIn = angular.module('googleSignIn', [loginSharedModule]);

googleSignIn.factory('googleSignInFactory', googleSignInFactory);
googleSignIn.factory('gapi', gapiFactory);

export default googleSignIn.name;
