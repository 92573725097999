import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import fp from 'lodash/fp';

import { AdminOnboardingCarousel, Tooltip } from 'frontend-ra-shared';

class AdminOnboardingWalkthroughController {
    // @ngInject
    constructor($element, $scope, $timeout, preferences) {
        this.$element = $element;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.preferences = preferences;
    }

    $onInit() {
        this.walkthroughComponents = fp.compact([
            () => this.getCarousel(),
            () => this.getPromptLibraryTooltip(),
            () => this.getReportsTooltip(),
            () => this.getAccountsTooltip(),
        ]);
        this.currentWalkthroughIndex = 0;
    }

    moveNext() {
        this.currentWalkthroughIndex++;

        if (this.currentWalkthroughIndex >= this.walkthroughComponents.length) {
            this.handleDone();
        } else {
            this.renderReact();
        }
    }

    getButtonText() {
        if (this.currentWalkthroughIndex < this.walkthroughComponents.length - 1) {
            return 'Next';
        }

        return 'Got It';
    }

    getAccountsTooltip() {
        const getTarget = () => document.querySelector('.accounts-icon');

        return (
            <Tooltip
                className="ra-tooltip"
                getTarget={getTarget}
                getPlacement={fp.constant('bottom')}
                offset={20}
            >
                <div className="tooltip-content">Select the account type to create.</div>
                <div className="button-wrapper">
                    <button className="btn btn-primary" onClick={this.moveNext.bind(this)}>
                        {this.getButtonText()}
                    </button>
                </div>
            </Tooltip>
        );
    }

    getReportsTooltip() {
        const getTarget = () => document.querySelector('.reports-icon');
        return (
            <Tooltip
                className="ra-tooltip"
                getTarget={getTarget}
                getPlacement={fp.constant('bottom')}
                offset={20}
            >
                <div className="tooltip-content">
                    Get a high-level look at usage and performance in your school as a whole.
                </div>
                <button className="btn btn-primary" onClick={this.moveNext.bind(this)}>
                    {this.getButtonText()}
                </button>
            </Tooltip>
        );
    }

    getPromptLibraryTooltip() {
        const getTarget = () => document.querySelector('.prompt-library-icon');
        return (
            <Tooltip
                className="ra-tooltip"
                getTarget={getTarget}
                getPlacement={fp.constant('bottom')}
                offset={20}
            >
                <div className="tooltip-content">
                    Explore the Prompt Library available to your instructors.
                </div>
                <button className="btn btn-primary" onClick={this.moveNext.bind(this)}>
                    {this.getButtonText()}
                </button>
            </Tooltip>
        );
    }

    handleDone() {
        this.onDone();
        this.$timeout(() => {
            this.$scope.$digest();
            this.currentWalkthroughIndex = 0;
        });
    }

    getCarousel() {
        return (
            <AdminOnboardingCarousel
                onDone={() => this.moveNext()}
                onViewLater={() => {
                    this.onViewLater();
                    this.$timeout(() => this.$scope.$digest());
                }}
                sourceImgDir="/images/ra-shared"
                isModal
            />
        );
    }

    getActiveWalkthroughComponent() {
        return this.walkthroughComponents[this.currentWalkthroughIndex]();
    }

    renderReact() {
        const component = (
            <IntlProvider locale="en">{this.getActiveWalkthroughComponent()}</IntlProvider>
        );
        ReactDOM.render(component, this.$element[0]);
    }

    $postLink() {
        this.renderReact();
    }
}

export default {
    controller: AdminOnboardingWalkthroughController,
    template: '<div class="admin-onboarding-walkthrough"></div>',
    bindings: {
        onViewLater: '&',
        onDone: '&',
    },
};
