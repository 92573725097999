import _ from 'lodash';

/**
 * Angular ships with a much more full featured _directive_ for handling plurals:
 *  https://docs.angularjs.org/api/ng/directive/ngPluralize
 * If you are in markup you should most likely be using that!
 *
 * This factory exists for when you need this logic _outside_ of markup.
 */
export default /* @ngInject */ () => {
    const pluralizeOrNull = _.curry((singlularFn, pluralFn, count) => {
        if (count < 1) {
            return null;
        }

        return count === 1 ? singlularFn(count) : pluralFn(count);
    });

    return {
        pluralizeOrNull,
    };
};
