import getUiState from './abu-order-outcome-component-helper';

const wizardRouteNameMap = {
    learner: 'admin.school.students.bulk-upload',
    instructor: 'admin.school.teachers.bulk-upload',
};

const orderDetailRouteNameMap = {
    learner: 'admin.school.students.bulk-upload-order.details',
    instructor: 'admin.school.teachers.bulk-upload-order.details',
};

class AbuOrderOutcomeController {
    // @ngInject
    constructor($state, bulkUploadService) {
        this.$state = $state;
        this.bulkUploadService = bulkUploadService;
    }

    $onInit() {
        const { availableDownloads, buttonStates, copy } = getUiState(this.upload);
        this.copy = copy;
        this.availableDownloads = availableDownloads;
        this.buttonStates = buttonStates;
    }

    downloadAvailableFiles() {
        this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, this.availableDownloads);
    }

    handleViewUploadClick(order) {
        const stateName = orderDetailRouteNameMap[this.upload.uploadType];
        const params = { schoolId: this.school.id, orderId: order.id };
        return this.$state.go(stateName, params, { reload: true });
    }

    restartWizard() {
        const stateName = wizardRouteNameMap[this.upload.uploadType];
        const params = { schoolId: this.school.id };
        return this.$state.go(stateName, params, { reload: true });
    }
}

export default {
    bindings: {
        upload: '<',
        school: '<',
        role: '@',
    },
    controller: AbuOrderOutcomeController,
    templateUrl: 'admin/bulk-upload/abu-order-outcome/abu-order-outcome-component.html',
};
