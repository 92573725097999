class RoleDrawerController {
    // @ngInject
    constructor(eventMediator, interactionShieldService, $scope) {
        this.eventMediator = eventMediator;
        this.interactionShieldService = interactionShieldService;

        this.expanded = false;

        // figure out why $onChanges isn't working so that this $scope.$watch can go away
        $scope.$watch('$ctrl.expandOverride', newValue => {
            if (newValue !== undefined) {
                this.setExpanded(newValue);
            }
        });

        $scope.$watch('$ctrl.isExpanded()', () => {
            if (this.isExpanded()) {
                this.interactionShieldService.raiseShield().then(() => {
                    this.toggle();
                });
            } else {
                this.interactionShieldService.lowerShield();
            }
        });
    }

    isExpanded() {
        return this.expanded || (this.expandOverride !== undefined && this.expandOverride)
            ? true
            : false;
    }

    expand() {
        this.setExpanded(true);
    }

    close() {
        this.setExpanded(false);
    }

    setExpanded(expanded) {
        if (_.isEqual(expanded, this.expanded)) return;

        this.expanded = expanded;
    }

    toggle() {
        this.setExpanded(!this.isExpanded());
        this.onToggle({ expanded: this.expanded });
    }

    selectRole(role) {
        if (!this.currentRole || role.toLowerCase() !== this.currentRole.toLowerCase()) {
            this.interactionShieldService.raiseTransitionShield();
            this.onRoleSelection({ role });
            this.interactionShieldService.lowerTransitionShield();
        }

        this.toggle();
    }
}

const roleDrawerComponent = {
    templateUrl: 'role-drawer/role-drawer.html',
    bindings: {
        onToggle: '&',
        onRoleSelection: '&',
        roles: '<',
        currentRole: '<',
        expandOverride: '<',
    },
    controller: RoleDrawerController,
};

export default roleDrawerComponent;
