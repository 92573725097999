import angular from 'angular';

import assignmentShared from '../shared/shared';

import assignmentTileComponent from './assignment-tile-component';
import assignmentTileHelper from './assignment-tile-helper';

const assignmentTileModule = angular.module('assignmentTileModule', [assignmentShared]);
assignmentTileModule.factory('assignmentTileHelper', assignmentTileHelper);
assignmentTileModule.component('assignmentTile', assignmentTileComponent);

export default assignmentTileModule.name;
