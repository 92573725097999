/**
 * Provides a form to collect user input for generating a usage report.
 * Data is provided to this component via the `model` binding and data
 * is passed back to the parent via the `onSubmit` callback.
 * Similarly, `onReset` is just a callback and expects the parent to handle this
 * by "resetting" the model when called.
 */
class UsageReportFormComponent {
    /* @ngInject */
    constructor() {}

    reset() {
        this.onReset();
    }

    createOnSubmitData(model) {
        const dates = _.pick(model, ['startDate', 'endDate']);
        const schools = _(model.schoolListModel.items)
            .filter('isSelected')
            .map('id')
            .value();

        return _.assign({}, dates, { schools });
    }

    submit(model) {
        this.onSubmit({
            model: this.createOnSubmitData(model),
        });
    }
}

export default {
    templateUrl: 'admin/usage-report/components/usage-report-form/usage-report-form-component.html',
    controller: UsageReportFormComponent,
    bindings: {
        model: '<',
        onReset: '&',
        onSubmit: '&',
    },
};
