import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import ngSanitize from 'angular-sanitize';

import highlightTermComponent from './highlight-term-component';

const highlightTerm = angular.module('highlight-term', [eventMediator, ngSanitize]);
highlightTerm.component('highlightTerm', highlightTermComponent);

export default highlightTerm.name;
