import _ from 'lodash';

const ERROR_CONTENT = {
    useEmail: {
        required: 'Please indicate if student will use an email address or username.',
    },
    username: {
        required: 'Please enter a username.', // Please enter a valid username.
        invalid: 'Please enter a different username.',
        duplicate: 'A user with this username already exists.',
    },
    email: {
        required: 'Please enter an email address.', // Please enter a valid email address.
        invalid: 'Please enter a different email address.',
        duplicate: 'A user with this email address already exists.',
    },
};

const MAXIMUM_USERNAME_LENGTH = 75;
const MAXIMUM_EMAIL_LENGTH = 100;

class LoginIdentityController {
    // @ngInject
    constructor($scope) {
        this.showUsername = !!this.showUsername;
        this.showEmail = !!this.showEmail;

        this.maxUsernameLength = this.maxUsernameLength || MAXIMUM_USERNAME_LENGTH;
        this.maxEmailLength = this.maxEmailLength || MAXIMUM_EMAIL_LENGTH;

        this.errors = _.cloneDeep(ERROR_CONTENT);

        this.usernameCache = this.username;
        $scope.$watch('$ctrl.useEmail', newVal => {
            if (newVal === true) {
                this.usernameCache = this.username;
                this.username = '';
            } else {
                this.username = this.usernameCache;
            }
        });
    }
}

const loginIdentityComponent = {
    templateUrl: 'admin/school/components/login-identity/login-identity.html',
    controller: LoginIdentityController,
    bindings: {
        useEmail: '=',
        emailAddress: '=',
        username: '=',
        showUsername: '@',
        showEmail: '@',
        maxUsernameLength: '@',
        maxEmailLength: '@',
        promptCopy: '@',
        confirmCopy: '@',
        rejectCopy: '@',
        form: '<',
    },
};

export default loginIdentityComponent;
