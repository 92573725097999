export default class ChangePasswordController {
    // @ngInject
    constructor($scope, model, eventMediator, $timeout, $state, authService, user, $log, $q) {
        this.$scope = $scope;
        this.model = model;
        this.eventMediator = eventMediator;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$log = $log;
        this.$q = $q;
        this.authService = authService;
        this.user = user;

        this.email = '';
        this.newPass = '';
        this.newPassConfirm = '';
        this.errorMessage = '';
    }

    handleCancelClick() {
        return this.$state.go('login');
    }

    changePassword() {
        if (this.form.newPassConfirm.$pristine || this.form.$invalid) {
            this.form.newPassConfirm.$setValidity('passwordMatch', false);
            return this.$q.reject();
        }

        return this.authService
            .changePassword(this.newPass, this.email, this.model.resetToken)
            .then(() => {
                if (this.model.showMessageOnSuccess) {
                    // Show message for 5 seconds before redirecting
                    this.renderSuccessMessage();
                    return this.$timeout(_.noop, 5000);
                }
            })
            .then(() => this.eventMediator.emit('login', { user: this.user }))
            .then(() => {
                const eulaComplete = this.authService.performLoginPostActions(this.user);
                const redirectComplete = this.redirectToHomePage();
                return this.$q.all([eulaComplete, redirectComplete]);
            })
            .then(() => this.eventMediator.emit('login-complete'))
            .catch(this.onChangePasswordFailure.bind(this));
    }

    onChangePasswordFailure(err) {
        this.$log.debug('login error');
        this.errorMessage = 'Sorry, please check your information and try again.';

        return this.$q.reject(err);
    }

    renderSuccessMessage() {
        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message:
                'Your password has been changed! You will be automatically redirected to your homepage.',
        });
    }

    redirectToHomePage() {
        const homeState = this.user.getHomeState();
        return this.$state.go(homeState.toState, homeState.toParams);
    }
}
