const NOTIFICATIONS = {
    success: 'A link to reset your password has been sent to your email address.',
    error: 'Please check your email address and try again.',
};

export default class SendEmailController {
    // @ngInject
    constructor(sendEmailService, $uibModal, eventMediator) {
        this.sendEmailService = sendEmailService;
        this.$uibModal = $uibModal;
        this.eventMediator = eventMediator;

        this.email = '';
        this.hideCancel = false;

        this.errors = {};
    }

    fireNotification(type, message) {
        this.eventMediator.emit('messageNotification', {
            type,
            message,
        });
    }

    showHelpModal() {
        this.$uibModal.open({
            templateUrl: 'login/password-reset/send-email/teacher-help-modal.html',
        });
    }

    isValid() {
        if (this.form.email.$error.required) {
            this.fireNotification('danger', NOTIFICATIONS.error);
        } else if (this.form.email.$error.email) {
            this.showHelpModal();
        }

        return this.form.$valid;
    }

    sendEmail() {
        if (!this.isValid()) return;

        this.sendEmailService
            .resetPassword(this.email)
            .then(() => {
                this.fireNotification('success', NOTIFICATIONS.success);
                this.email = '';
            })
            .catch(() => {
                this.fireNotification('danger', NOTIFICATIONS.error);
            })
            .finally(() => {
                this.hideCancel = true;
            });
    }
}
