class RaPaginationComponentController {
    $onChanges(changes) {
        if (changes.currentPage) this.pageNum = changes.currentPage.currentValue;
    }
    isBackDisabled() {
        return this.currentPage <= 1;
    }
    isForwardDisabled() {
        return this.currentPage >= this.totalPages;
    }
    previousPage() {
        if (this.isBackDisabled()) return;
        this.handlePageChange({ page: this.currentPage - 1, search: this.searchTerm });
    }
    firstPage() {
        if (this.isBackDisabled()) return;
        this.handlePageChange({ page: 1, search: this.searchTerm });
    }
    nextPage() {
        if (this.isForwardDisabled()) return;
        this.handlePageChange({ page: this.currentPage + 1, search: this.searchTerm });
    }
    lastPage() {
        if (this.isForwardDisabled()) return;
        this.handlePageChange({ page: this.totalPages, search: this.searchTerm });
    }
    updatePage() {
        const pageNum = parseInt(this.pageNum);
        if (!pageNum || pageNum == this.currentPage || pageNum > this.totalPages || pageNum < 1) {
            this.pageNum = this.currentPage;
            return;
        }
        this.handlePageChange({ page: this.pageNum, search: this.searchTerm });
    }
}

const raPaginationComponent = {
    bindings: {
        totalPages: '<',
        currentPage: '<',
        searchTerm: '<',
        handlePageChange: '&',
    },
    controller: RaPaginationComponentController,
    templateUrl: 'ra-pagination/ra-pagination-component.html',
};

export default raPaginationComponent;
