export default class TeacherClassRosterController {
    // @ngInject
    constructor(
        $http,
        $log,
        $state,
        $stateParams,
        $uibModal,
        eventMediator,
        TeacherClass,
        urls,
        teacherService,
        $q,
        preferences,
        providerService
    ) {
        this.$http = $http;
        this.$log = $log;
        this.teacherClass = TeacherClass;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.eventMediator = eventMediator;
        this.urls = urls;
        this.teacherService = teacherService;
        this.$q = $q;
        this.preferences = preferences;
        this.providerService = providerService;
    }
    manage(student) {
        this.$log.debug('<TeacherClassRosterController> opening management on student');
        this.$log.debug('<TeacherClassRosterController> class ' + this.teacherClass.id);
        this.$log.debug('<TeacherClassRosterController> student ' + student.identity_id);
        this.$state.go('teacher.class.manage-student.info', {
            classId: this.teacherClass.id,
            studentId: student.identity_id,
        });
    }
    isDemo(student) {
        return _.has(student, 'membership_state.demo');
    }
    dropConfirm(student) {
        this.$log.debug('<TeacherClassRosterController> user must confirm removal');
        this.target = student;
        this.$uibModal
            .open({
                templateUrl: 'teacher/teacher-class/remove-student-modal.html',
            })
            .result.then(this.dropConfirmed.bind(this), this.dropCancelled.bind(this));
    }
    dropConfirmed() {
        this.$log.debug('<TeacherClassRosterController> removal confirmed');
        if (this.target) {
            this.$http({
                url: `${this.urls.base}/ra/class/${this.teacherClass.id}/members/${this.target
                    .identity_id}`,
                method: 'DELETE',
                headers: {
                    Authorization: 'Token ' + this.token,
                },
            })
                .then(this.dropResolved.bind(this))
                .catch(this.dropRejected.bind(this));
        } else {
            this.$log.debug("<TeacherClassRosterController> couldn't remove student (no target)");
            this.dropRejected();
        }
    }
    dropCancelled() {
        this.$log.debug('<TeacherClassRosterController> modal cancelled');
        delete this.target;
    }
    dropResolved() {
        this.$log.debug('<TeacherClassRosterController> student removed');
        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message:
                'You have successfully removed ' +
                this.target.first_name +
                ' ' +
                this.target.last_name +
                ' from ' +
                this.teacherClass.name +
                '.',
        });
        _.remove(this.teacherClass.students, ['identity_id', this.target.identity_id]);
        delete this.target;
    }
    dropRejected() {
        this.$log.debug('<TeacherClassRosterController> student removal failed');
        this.eventMediator.emit('messageNotification', {
            type: 'danger',
            message:
                'There was a problem removing ' +
                this.target.first_name +
                ' ' +
                this.target.last_name +
                ' from ' +
                this.teacherClass.name +
                '. Please try again.',
        });
        delete this.target;
    }

    createAssignment() {
        const classId = this.teacherClass.id;
        return this.providerService.launchCreateAssignment(classId);
    }

    classHasStudentWork() {
        const deferred = this.$q.defer();
        const promises = [];

        for (let i = 0; i < this.teacherClass.assignments.length; i++) {
            promises.push(this.teacherService.getStudentWork(this.teacherClass.assignments[i].id));
        }

        this.$q.all(promises).then(
            success => {
                let hasWork = false;

                for (let i = 0; i < success.length; i++) {
                    if (success[i].signal_check > 0 || success[i].submission > 0) {
                        hasWork = true;
                        break;
                    }
                }

                deferred.resolve(hasWork);
            },
            () => {
                deferred.reject();
            }
        );

        return deferred.promise;
    }

    getClassAndTimeDisplay() {
        const { time, name } = this.teacherClass;
        return time ? `${time} - ${name}` : name;
    }

    showDeleteModal() {
        return this.$uibModal.open({
            template: `
                <div class="modal-header">
                    <h1>Are you sure?</h1>
                </div>
                <div class="modal-body">
                    <p>
                        Students have done work for this class. If you click Continue, you and your students will lose all the work and assignments for <strong ng-bind="'${this.getClassAndTimeDisplay()}'"></strong>.
                    </p>
                </div>
                <div class="modal-footer">
                    <div class="buttons-centered-container">
                        <div class="buttons-centered">
                            <button class="btn btn-default" ng-click="$close()">Continue</button>
                            <button class="btn btn-primary" ng-click="$dismiss()">Cancel</button>
                        </div>
                    </div>
                </div>`,
        });
    }

    deleteClass() {
        this.teacherService
            .editClass({
                id: this.teacherClass.id,
                state: 'inactive',
            })
            .then(() => {
                const undoData = {
                    url: `/ra/class/${this.teacherClass.id}`,
                    method: 'PUT',
                    data: { state: 'active' },
                };

                this.eventMediator.emit('messageNotification', {
                    type: 'success',
                    message: `<strong ng-bind="'${this.getClassAndTimeDisplay()}'"></strong> has been deleted. <a api-action=${angular.toJson(
                        undoData
                    )}>Undo</a>`,
                    postStateChange: true,
                });
                this.$state.go('teacher.home');
            });
    }

    slay() {
        this.classHasStudentWork().then(success => {
            if (success) {
                this.showDeleteModal().result.then(this.deleteClass.bind(this));
            } else {
                this.deleteClass();
            }
        });
    }
}
