import angular from 'angular';
import eventMediator from 'gong-event-mediator';

import user from '../user/user';
import userAccount from '../user-account/user-account';
import teacherOnboardingCarousel from '../components/teacher-onboarding-carousel/teacher-onboarding-carousel';
import adminOnboardingWalkthrough from '../components/admin-onboarding-walkthrough/admin-onboarding-walkthrough';

import onboardingComponent from './onboarding-component';
import OnboardingService from './onboarding-service';

const onboarding = angular.module('onboarding', [
    eventMediator,
    user,
    userAccount,
    teacherOnboardingCarousel,
    adminOnboardingWalkthrough,
]);
onboarding.component('onboarding', onboardingComponent);
onboarding.service('onboardingService', OnboardingService);

export default onboarding.name;
