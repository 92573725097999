import clipboard from 'clipboard-polyfill';
import Tooltip from 'tooltip.js';

class GoogleSsoBarController {
    // @ngInject
    constructor($element, $timeout) {
        this.$element = $element;
        this.$timeout = $timeout;
    }

    districtCodeMailToLink() {
        const subject = 'Set up Revision Assistant';
        const body = `
Get ready. Get set (up). Go! Here’s how to create your Revision Assistant account.

    1. Visit https://www.revisionassistant.com
    2. Click “Sign in with Google.”
    3. If prompted, choose the teacher role.
    4. Enter your district code: Enter this code: ${this.districtCode}

Welcome aboard,
The RA Team
`;

        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }

    copyCodeToClipboard() {
        if (!this.districtCodeTooltip) {
            this.districtCodeTooltip = new Tooltip(
                document.querySelector('.district-code-button'),
                {
                    placement: 'top',
                    title: 'Copied to Clipboard',
                    trigger: 'none',
                }
            );
        }

        return clipboard
            .writeText(this.districtCode)
            .then(() =>
                this.$timeout(() => {
                    this.districtCodeTooltip.show();
                }, 50)
            )
            .then(() =>
                this.$timeout(() => {
                    this.districtCodeTooltip.hide();
                }, 1000)
            );
    }
}

export default {
    templateUrl: 'admin/google-sso-bar/google-sso-bar-component.html',
    bindings: {
        districtCode: '<',
        onRegenerateCode: '&',
        showRegenerateCode: '<',
    },
    controller: GoogleSsoBarController,
};
