const ASSIGNMENT_STATES = Object.freeze({
    spotCheckNotStarted: 'tst_not_started',
    spotCheckInProgress: 'tst_in_progress',
    spotCheckCollected: 'tst_collected',
    spotCheckCollecting: 'tst_collecting',
    archived: 'archived',
    closed: 'closed',
    open: 'open',
    deleted: 'deleted',
});
export default ASSIGNMENT_STATES;
