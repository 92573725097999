import fp from 'lodash/fp';
import { assignmentTypes } from '../shared/assignment-types-factory';
/**
 * Helpers for calculating values useful to the assignment tile component.
 * These are not used directly by the assignment tile itself, but are intended
 * as helpers to other files using an assignment tile.
 *
 * For example, the assignment tile accepts a generic `tileCssClasses` binding
 * which is just a basic space delimited string. This file provides a function
 * that is common to both the student and teacher tiles for calculating this.
 */
export default /* @ngInject */ (dateFilter, assignmentTypesFactory) => {
    const assignmentTypeClasses = {
        [assignmentTypes.signalCheck]: 'ra-assignment',
        [assignmentTypes.spotCheck]: 'test-mode-assignment',
        [assignmentTypes.expansionPack]: 'expansion-pack-assignment',
    };

    const assignmentTypeNames = {
        [assignmentTypes.signalCheck]: 'Signal Check',
        [assignmentTypes.spotCheck]: 'Spot Check',
        [assignmentTypes.expansionPack]: 'Expansion Pack',
    };

    const assignmentStateClasses = {
        archived: 'archived',
        closed: 'closed',
        tst_in_progress: 'in-progress',
        tst_collected: 'collected',
    };

    const assignmentStatesSpotCheck = {
        tst_not_started: 'Not Started',
        tst_in_progress: 'In Progress',
        tst_collecting: 'Collecting Work...',
        tst_collected: 'Work Collected',
        closed: 'Closed',
        archived: 'Archived',
    };

    function getAssignmentClasses(assignment) {
        const { assignment_type, state } = assignment;
        const type = assignmentTypesFactory.convertLegacyToCurrentAssignmentType(assignment_type);
        return _.compact([assignmentTypeClasses[type], assignmentStateClasses[state]]);
    }

    function getAssignmentTypeText(assignment) {
        return assignmentTypeNames[assignment.assignment_type] || 'Signal Check';
    }

    function getAssignmentState(assignment) {
        if (assignment.state === 'archived') {
            return { type: 'state', text: 'Archived' };
        }
        // TODO: 'TM' assignment type will eventually no longer exist
        if (assignment.assignment_type === 'TM' || assignment.assignment_type === 'spot check') {
            return { type: 'state', text: assignmentStatesSpotCheck[assignment.state] };
        }
        if (assignment.state === 'closed') {
            return { type: 'state', text: 'Closed' };
        }

        if (assignment.state === 'open' && assignment.due_date) {
            return {
                type: 'date',
                text: `Due ${dateFilter(assignment.due_date, 'MMMM dd, yyyy')}`,
            };
        }

        return { type: 'date', text: 'No due date' };
    }

    function getAssignmentStateText(assignment) {
        return getAssignmentState(assignment).text;
    }

    function getAssignmentStateType(assignment) {
        return getAssignmentState(assignment).type;
    }

    function getAssignmentTypeGear(assignmentType) {
        const type = assignmentTypesFactory.convertLegacyToCurrentAssignmentType(assignmentType);
        return `/images/gear-icon-${fp.kebabCase(type)}.svg`;
    }

    return {
        getAssignmentClasses,
        getAssignmentStateText,
        getAssignmentStateType,
        getAssignmentTypeText,
        getAssignmentTypeGear,
    };
};
