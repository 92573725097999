import angular from 'angular';
import eventMediator from 'gong-event-mediator';

import urls from '../../url-config/url-config';
import utilsForm from '../../utils-form/utils-form';
import userAccountService from '../../user-account/user-account';
import formUtils from '../../form-utils/form-utils';

import changePasswordComponent from './change-password-component';

const changePassword = angular.module('change-password', [
    eventMediator,
    urls,
    utilsForm,
    userAccountService,
    formUtils,
]);
changePassword.component('changePassword', changePasswordComponent);

export default changePassword.name;
