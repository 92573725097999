export default class AdminAccountsController {
    // @ngInject
    constructor(admins, $stateParams, $state, adminService, eventMediator, group) {
        this.admins = admins;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.adminService = adminService;
        this.eventMediator = eventMediator;

        this.groupType = group.type;
        this.groupId = group.id;
        this.createAccountRef = `admin.${this
            .groupType}.administrators.create-account(${this.getStateParams(
            this.groupType,
            this.groupId
        )})`;
        this.label = _.startCase(`${this.groupType} administrator`).toLowerCase();
    }

    getStateParams(groupType, groupId, identityId) {
        return JSON.stringify({
            [`${groupType}Id`]: groupId,
            identityId,
        });
    }

    handleRemove(admin) {
        this.adminService.removeAdminById(this.groupId, admin.identity_id).then(success => {
            this.fireRemoveNotification(admin, success.members);
            this.$state.go(this.$state.current, this.$stateParams, { reload: true });
        });
    }

    fireRemoveNotification(admin, members) {
        let membershipId = _.get(members, '[0].id', undefined);
        if (!membershipId) return;

        const undoData = {
            url: `/ra/admin/${this.groupType}/${this.groupId}/membership/${membershipId}/restart`,
            method: 'POST',
            data: { id: admin.id },
        };

        const message = `You have successfully removed <strong>${_.escape(
            admin.first_name + ' ' + admin.last_name
        ).trim()}</strong>'s ${this.label} account.<a api-action=${angular.toJson(
            undoData
        )}>Undo</a>`;

        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message,
            postStateChange: true,
        });
    }

    handlePageChange(page, search) {
        this.adminService
            .getAdminAccountsBySchool(this.groupId, { page, count: 20, search })
            .then(admins => {
                this.admins = admins;
                const page = _.get(admins, 'page.current');
                this.$state.go('.', { page }, { reload: false, notify: false });
            });
    }

    getManageAccountSref(accountId) {
        return `admin.${this.groupType}.administrators.administrator.detail(${this.getStateParams(
            this.groupType,
            this.groupId,
            accountId
        )})`;
    }
}
