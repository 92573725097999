/**
 * Manages an <input type="file" /> and calls `onFilesSelected` each time the user selects a file.
 * This is similar to './file-input-directive.js', but has a few differences:
 * 1. Exposes a nicer API via `onFilesSelected` instead of relying on 2-way binding
 * 2. Triggers `onFileSelected` _every time_ the user selects a file.
 *    Even if the user selects the same file multiple times.
 * Generally, we are trying to deprecate the directive version in favor of this component.
 */
class FileSelectComponent {
    // @ngInject
    constructor($element) {
        this.$element = $element;

        // save the "bound" reference so we can unsubscribe when the component is destroyed
        this.boundHandleFileInputChange = this.handleFileInputChange.bind(this);
    }

    $onInit() {
        this.$element.on('change', this.boundHandleFileInputChange);
    }

    $onDestroy() {
        this.$element.off('change', this.boundHandleFileInputChange);
    }

    handleFileInputChange() {
        const input = this.$element.find('input')[0];
        this.onFilesSelected({ files: input.files });

        // ensures that selecting the same file twice still triggers the change event
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        input.value = null;
    }
}

export default {
    controller: FileSelectComponent,
    bindings: {
        acceptedFileExtensions: '<',
        onFilesSelected: '&',
    },
    template: `<input type="file" accept="{{$ctrl.acceptedFileExtensions}}" />`,
};
