import angular from 'angular';
import ngAnimate from 'angular-animate';

import roleDrawerComponent from './role-drawer-component';
import interactionShield from '../interaction-shield/interaction-shield';

const roleDrawer = angular.module('roleDrawer', [ngAnimate, interactionShield]);
roleDrawer.component('roleDrawer', roleDrawerComponent);

export default roleDrawer.name;
