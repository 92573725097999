import _ from 'lodash';

const roles = {
    learner: 'Learner',
    instructor: 'Instructor',
};

class EulaSsoController {
    /* @ngInject */
    constructor() {
        this.roles = roles;
    }

    $onChanges(changes) {
        const model = _.get(changes, 'model.currentValue');
        this.uiModel = model ? this.createUiModel(_.cloneDeep(model)) : this.uiModel;
    }

    createUiModel(setupInfo) {
        const schools = _(setupInfo.availableSchools)
            .filter(school => setupInfo.membershipGroupIds.includes(school.id))
            .map('name')
            .value();

        return {
            role: setupInfo.role,
            agreedToEula: setupInfo.agreedToEula,
            displayName: setupInfo.displayName,
            districtName: setupInfo.districtName,
            schools,
        };
    }

    getSchoolString(schools) {
        if (schools.length <= 2) {
            return schools.join(' and ');
        }

        const [init, [last]] = _.chunk(schools, schools.length - 1);
        return `${init.join(', ')}, and ${last}`;
    }

    back() {
        // when going back, don't include the eula as part of the model update
        // this ensures you can only "complete" setup by clicking next
        const model = _.pick(this.uiModel, ['displayName']);
        this.onBack({ model });
    }

    submit() {
        if (!this.form.$valid) {
            return;
        }

        const model = _.pick(this.uiModel, ['agreedToEula', 'displayName']);
        this.onSubmit({ model });
    }
}

export default {
    templateUrl: 'login/shared/eula-sso/eula-sso-component.html',
    controller: EulaSsoController,
    bindings: {
        model: '<',
        validationErrors: '<',
        isBackVisible: '<',
        onBack: '&',
        onSubmit: '&',
    },
};
