import angular from 'angular';

import textUtils from '../../../../text-utils/text-utils';

import EditAdminAccountController from './edit-admin-account-controller';

const editAdminAccount = angular.module('editAdminAccount', [textUtils]);
editAdminAccount.controller('EditAdminAccountController', EditAdminAccountController);

export default editAdminAccount.name;
