import _ from 'lodash';

export default class PaymentFormController {
    // @ngInject
    constructor(
        salesContactEmail,
        stripeCheckoutInformation,
        $log,
        $scope,
        $state,
        $stateParams,
        paymentService,
        user
    ) {
        this.$log = $log;
        this.$scope = $scope;
        this.$state = $state;
        this.paymentService = paymentService;
        this.user = user.getUser();
        this.salesContact = `mailto:${salesContactEmail}`;
        this.trialExpired = _.get($stateParams, 'trialExpired', false);
        this.purchaseComplete = false;

        this.userHasAgreedToTOS = false;

        this.errorDuringPurchase = false;
        this.stripePublishableKey = stripeCheckoutInformation.stripe_publishable_key;
        this.paymentAmountInCents = stripeCheckoutInformation.amount;
    }

    // Open the Stripe Checkout widget
    openStripeCheckout() {
        if (!this.userHasAgreedToTOS || this.purchaseComplete) return;

        // TODO (kfr2): Move StripeCheckout to a factory/service?
        // https://github.com/turnitin/frontend-ra-consumer/pull/212#pullrequestreview-112575432
        const handler = StripeCheckout.configure({
            key: this.stripePublishableKey,
            name: 'Revision Assistant',
            description: '1-Year Teacher Subscription',
            image: '/images/payment/signals.svg',
            locale: 'auto',
            amount: this.paymentAmountInCents,
            zipCode: true,
            billingAddress: false,
            allowRememberMe: false,
            panelLabel: 'Buy Now for {{amount}}',
            token: (stripe_token, args) =>
                this.$scope.$apply(this.handleCheckout(stripe_token, args)),
        });

        handler.open({
            email: this.user.email,
        });
    }

    // Send data from the Stripe Checkout widget to our API
    handleCheckout(token) {
        const data = {
            email: token.email,
            stripe_token: token.id,
        };
        this.paymentService.submitPayment(data).then(
            order_number => {
                this.purchaseComplete = true;
                this.$state.go('payment-pending', { orderNumber: order_number });
            },
            err_res => {
                this.$log.debug('An error happened. We should notify somebody.');
                this.$log.debug(err_res);
                this.purchaseComplete = true;
                this.errorDuringPurchase = true;
            }
        );
    }

    showErrorMessage() {
        return this.purchaseComplete && this.errorDuringPurchase;
    }

    showSuccessMessage() {
        return this.purchaseComplete && !this.errorDuringPurchase;
    }
}
