import angular from 'angular';
import uiRouter from 'angular-ui-router';

import user from '../user/user';
import ravenService from './raven';
import ravenExceptionHandler from './exception_handler';

const sentry = angular.module('sentry', [user, uiRouter]);
sentry.service('ravenService', ravenService);
sentry.factory('ravenExceptionHandler', ravenExceptionHandler);

sentry.config(function($provide) {
    'ngInject';
    $provide.decorator('$exceptionHandler', function($delegate, ravenExceptionHandler) {
        'ngInject';
        return function(exception, cause) {
            ravenExceptionHandler(exception, cause);
            $delegate(exception, cause);
        };
    });
});

export default sentry.name;
