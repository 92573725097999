import angular from 'angular';

import adminBulkUploadV2Component from './admin-bulk-upload-component';
import abuProgressBar from '../abu-progress-bar/abu-progress-bar';
import bulkUploadWizard from '../bulk-upload-wizard/bulk-upload-wizard';
import abuComponentService from './abu-component-service';

const adminBulkUploadV2 = angular.module('adminBulkUploadV2', [abuProgressBar, bulkUploadWizard]);

adminBulkUploadV2.component('adminBulkUploadV2', adminBulkUploadV2Component);
adminBulkUploadV2.factory('abuComponentService', abuComponentService);

export default adminBulkUploadV2.name;
