import angular from 'angular';

import textUtils from '../../../../text-utils/text-utils';

import CreateAdminAccountController from './create-admin-account-controller';

const createAdminAccount = angular.module('createAdminAccount', [textUtils]);
createAdminAccount.controller('CreateAdminAccountController', CreateAdminAccountController);

export default createAdminAccount.name;
