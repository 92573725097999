import angular from 'angular';
import ngMessages from 'angular-messages';

import spreadsheetModule from '../../../spreadsheet/spreadsheet';
import shared from '../shared/shared';

import BulkUploadWizard from './bulk-upload-wizard-component';
import CheckYourSpreadsheetComponent from './check-your-spreadsheet/check-your-spreadsheet-component';
import AccountCreationSettingsComponent from './account-creation-settings/account-creation-settings-component';
import AccountCreationSettingsStudentComponent from './account-creation-settings-student/account-creation-settings-student-component';
import PasswordCreationSettingsComponent from './password-creation-settings/password-creation-settings-component';
import UploadYourFileComponent from './upload-your-file/upload-your-file-component';

const teacherBulkUpload = angular.module('teacherBulkUploadWizardModule', [
    ngMessages,
    spreadsheetModule,
    shared,
]);

teacherBulkUpload.component('bulkUploadWizard', BulkUploadWizard);
teacherBulkUpload.component('checkYourSpreadsheet', CheckYourSpreadsheetComponent);
teacherBulkUpload.component('accountCreationSettings', AccountCreationSettingsComponent);
teacherBulkUpload.component(
    'accountCreationSettingsStudent',
    AccountCreationSettingsStudentComponent
);
teacherBulkUpload.component('passwordCreationSettings', PasswordCreationSettingsComponent);
teacherBulkUpload.component('uploadYourFile', UploadYourFileComponent);

export default teacherBulkUpload.name;
