import angular from 'angular';
import EventMediator from 'gong-event-mediator';
import uiBootstrap from 'angular-ui-bootstrap';

import urlConfig from '../../url-config/url-config';
import user from '../../user/user';

import adminService from './admin-service';
import accountDeletionModal from './account-deletion-modal';
import accountDisplayFactory from './account-display-factory';
import bulkUploadFactory from './bulk-upload-factory';
import bulkDeletionModalFactory from './bulk-deletion-modal-factory';
import districtModalFactory from './district-modal-factory';
import postDownloadFactory from './post-download-factory';
import searchValidation from './search-validation';

const adminShared = angular.module('adminShared', [urlConfig, user, EventMediator, uiBootstrap]);

adminShared.factory('adminService', adminService);
adminShared.factory('accountDeletionModal', accountDeletionModal);
adminShared.factory('accountDisplay', accountDisplayFactory);
adminShared.factory('bulkUploadFactory', bulkUploadFactory);
adminShared.factory('bulkDeletionModalFactory', bulkDeletionModalFactory);
adminShared.factory('postDownloadFactory', postDownloadFactory);
adminShared.factory('districtModalFactory', districtModalFactory);
adminShared.factory('searchValidation', searchValidation);

export default adminShared.name;
