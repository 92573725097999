import angular from 'angular';
import ngSanitize from 'angular-sanitize';

import promotionsFactory from './promotions-factory';
import promotionBannersComponent from './promotion-banners-component';

import user from '../../user/user';
import assignment from '../../assignment/assignment';
import userAccount from '../../user-account/user-account';

const promotionsModule = angular.module('promotions', [ngSanitize, user, assignment, userAccount]);
promotionsModule.factory('promotionsFactory', promotionsFactory);
promotionsModule.component('promotionBanners', promotionBannersComponent);

export default promotionsModule.name;
