import { resolveTeacherHomeData } from './teacher-routes-resolve';

// @ngInject
export default function teacherRoutes($stateProvider) {
    $stateProvider
        .state('teacher', {
            abstract: true,
            url: '/teacher',
            template: '<ui-view class="teacher"></ui-view>',
            data: {
                isAuthorized(user) {
                    return user.hasInstructorRole();
                },
            },
        })
        .state('teacher.home', {
            url: '/home',
            templateUrl: 'teacher/home/home.html',
            controller: 'HomeController as homeController',
            resolve: {
                school: /* @ngInject */ user => user._getMembershipData()[user.getCurrentRole()][0],
                homeData(teacherService) {
                    '@ngInject';

                    return teacherService.getHomeData().then(resolveTeacherHomeData);
                },
            },
        })
        .state('teacher.archived-classes', {
            url: '/archived-classes',
            templateUrl: 'teacher/archived-classes/archived-classes.html',
            controller: 'ArchivedClassesController as $ctrl',
            resolve: {
                archivedClasses(teacherService) {
                    '@ngInject';

                    return teacherService.getArchivedClasses();
                },
            },
        })
        //teacher-class
        .state('teacher.create-class', {
            url: '/create-class',
            templateUrl: 'teacher/teacher-class/teacher-class-add.html',
            controller: 'TeacherClassAddController',
            controllerAs: 'ctrl',
        })
        .state('teacher.class', {
            abstract: true,
            url: '/class/:classId',
            template: '<ui-view />',
            resolve: {
                // TODO: Resolve parameters should start with lowercase instead, e.g. 'teacherClass' and 'student'.
                TeacherClass($stateParams, teacherService) {
                    return teacherService.getClass($stateParams.classId);
                },
            },
        })
        .state('teacher.class.created', {
            url: '/created',
            templateUrl: 'teacher/teacher-class/teacher-class-created.html',
            controller: 'TeacherClassCreatedController',
            controllerAs: 'ctrl',
        })
        .state('teacher.class.edit', {
            url: '/edit',
            templateUrl: 'teacher/teacher-class/teacher-class-edit.html',
            controller: 'TeacherClassEditController',
            controllerAs: 'ctrl',
        })
        .state('teacher.class.roster', {
            url: '/roster',
            templateUrl: 'teacher/teacher-class/teacher-class-roster.html',
            controller: 'TeacherClassRosterController',
            controllerAs: 'ctrl',
        })
        .state('teacher.class.manage-student', {
            abstract: true,
            url: '/student/:studentId',
            template: '<ui-view class="teacher"></ui-view>',
            resolve: {
                // TODO: Resolve parameters should start with lowercase instead, e.g. 'teacherClass' and 'student'.
                Student: /* @ngInject */ ($stateParams, teacherService) => {
                    const studentId = parseInt($stateParams.studentId, 10);
                    return teacherService.getStudentAccount(studentId);
                },
            },
        })
        .state('teacher.class.manage-student.info', {
            url: '/info',
            templateUrl:
                'teacher/teacher-class/student-accounts/view/teacher-class-manage-student.html',
            controller: 'TeacherClassManageStudentController',
            controllerAs: '$ctrl',
            resolve: {
                // This is renaming for incremental refactoring.
                // See 'teacher.class' and 'teacher.class.manage-student'.
                targetUser: /* @ngInject */ Student => {
                    return Student;
                },
                teacherClass: /* @ngInject */ TeacherClass => {
                    return TeacherClass;
                },
            },
        })
        .state('teacher.class.manage-student.edit', {
            url: '/edit',
            templateUrl:
                'teacher/teacher-class/student-accounts/edit/teacher-class-edit-student.html',
            controller: 'TeacherClassEditStudentController',
            controllerAs: '$ctrl',
            resolve: {
                // This is renaming for incremental refactoring.
                // See 'teacher.class' and 'teacher.class.manage-student'.
                targetUser: /* @ngInject */ Student => {
                    return Student;
                },
                teacherClass: /* @ngInject */ TeacherClass => {
                    return TeacherClass;
                },
            },
        })
        .state('teacher.class.manage-student.password', {
            url: '/password',
            templateUrl: 'teacher/teacher-class/teacher-class-change-password.html',
            controller: 'TeacherClassChangePasswordController',
            controllerAs: '$ctrl',
        });
}
