import angular from 'angular';

import textUtils from '../../../text-utils/text-utils';
import bulkUploadShared from '../shared/shared';

import abuAccountActionsComponent from './abu-account-actions-component';
import abuAccountActionsHelper from './abu-account-actions-helper';

const abuAccountActions = angular.module('abuAcountActions', [textUtils, bulkUploadShared]);
abuAccountActions.component('abuAccountActions', abuAccountActionsComponent);
abuAccountActions.factory('abuAccountActionsHelper', abuAccountActionsHelper);

export default abuAccountActions.name;
