import angular from 'angular';

import adminChangePassword from './admin-change-password/admin-change-password';
import createAccount from './create-account/create-account';
import createAccountSuccess from './create-account-success/create-account-success';
import adminEditAccount from './admin-edit-account/admin-edit-account';
import adminViewAccount from './admin-view-account/admin-view-account';

const schoolShared = angular.module('schoolShared', [
    createAccount,
    createAccountSuccess,
    adminChangePassword,
    adminEditAccount,
    adminViewAccount,
]);

export default schoolShared.name;
