import _ from 'lodash';

/**
 * Provides ability to create a model for the usage report form component
 * as well validate the object that is returned from "submitting" the form.
 */
export default () => {
    'ngInject';
    function isSchoolListVisible(user, schools, isDistrictOnly) {
        if (isDistrictOnly) {
            return false;
        }

        if (user.isImplementationSpecialist() || user.isDistrictAdministrator()) {
            return true;
        }

        return _.size(schools) > 1;
    }

    function getDefaultStartDate() {
        return new Date('08/18/2016');
    }

    function getDefaultEndDate() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return new Date(today.setDate(today.getDate() - 1));
    }

    function createModel(user, { licenseTermDates, schools, districtOnly }) {
        return {
            isSchoolListVisible: isSchoolListVisible(user, schools, districtOnly),
            minDate: licenseTermDates.start,
            maxDate: licenseTermDates.end,
            startDate: getDefaultStartDate(),
            endDate: getDefaultEndDate(),
            schoolListModel: {
                name: 'Schools',
                includeAll: true,
                areOptionsVisible: false,
                items: _(schools)
                    .map(x => _.pick(x, ['id', 'name']))
                    .map(x => _.assign({}, x, { isSelected: true }))
                    .value(),
                validationMessage: '',
            },
            formErrors: {},
        };
    }

    // helper that validates Required, MinDate, and MaxDate
    function validateRequiredDate({ key, name }, date, { minDate, maxDate }) {
        if (!date) {
            return { [key]: `Please enter a valid ${_.lowerCase(name)} date.` };
        }

        if (date < minDate) {
            return { [key]: `${name} date must be on or after ${minDate.toLocaleDateString()}.` };
        }

        if (date > maxDate) {
            return { [key]: `${name} date cannot be after ${maxDate.toLocaleDateString()}.` };
        }
    }

    function validateStartDate({ startDate, endDate }, usageReportModel) {
        const basicValidation = validateRequiredDate(
            { key: 'startDate', name: 'Start' },
            startDate,
            usageReportModel
        );

        if (basicValidation) {
            return basicValidation;
        }

        if (endDate && endDate < startDate) {
            return { startDate: 'Start date cannot be after end date.' };
        }
    }

    function validateEndDate({ endDate }, usageReportModel) {
        return validateRequiredDate({ key: 'endDate', name: 'End' }, endDate, usageReportModel);
    }

    function validateSchools({ schools }, { schoolListModel }) {
        if (_.isEmpty(schools) && !_.isEmpty(schoolListModel.items)) {
            return { schools: 'Please select at least one school.' };
        }
    }

    /**
     * Validates the form that will be sent to the server - of the shape:
     *          { startDate, endDate, schools }
     * Validates against the usageReportModel which includes `minDate` and `maxDate`
     * Returns an object of:
     * - `isValid` indicating if the provided model was valid
     * - `model` which has the validation applied to the model
     */
    function validate(usageReportForm, usageReportModel) {
        const validation = _.assign(
            {},
            validateStartDate(usageReportForm, usageReportModel),
            validateEndDate(usageReportForm, usageReportModel),
            validateSchools(usageReportForm, usageReportModel)
        );

        const isValid = _.isEmpty(validation);

        const schoolListModel = _.assign({}, usageReportModel.schoolListModel, {
            validationMessage: validation.schools,
        });

        const model = _.assign({}, usageReportModel, {
            formErrors: _.pick(validation, ['startDate', 'endDate']),
            schoolListModel,
        });

        return { isValid, model };
    }

    return {
        createModel,
        validate,
    };
};
