import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import navigationNetComponent from './navigation-net-component';

const navigationNet = angular.module('navigationNet', [eventMediator, uiBootstrap, uiRouter]);
navigationNet.component('navigationNet', navigationNetComponent);

export default navigationNet.name;
