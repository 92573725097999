import _ from 'lodash';

export default class ArchivedClassesController {
    // @ngInject
    constructor(providerService, archivedClasses, preferences) {
        this.providerService = providerService;
        this.archivedClasses = archivedClasses;
        this.preferences = preferences;

        this.areArchivedClassesVisible = archivedClasses.length > 0;
        this.initSchoolYears();
        this.filterArchivedClassesBySchoolYear();
    }

    initSchoolYears() {
        // TODO remove after archving 2018 launch
        if (!_.includes(this.preferences.getActiveDevFlags(), 'archiving-2018')) return;

        this.schoolYears = _(this.archivedClasses)
            .map('schoolYear')
            .uniq()
            .orderBy([], ['desc'])
            .value();
        this.selectedSchoolYear = _.head(this.schoolYears);
    }

    filterArchivedClassesBySchoolYear() {
        // TODO remove after archving 2018 launch
        if (!_.includes(this.preferences.getActiveDevFlags(), 'archiving-2018')) {
            this.filteredArchivedClasses = this.archivedClasses;
            return;
        }

        this.filteredArchivedClasses = _.filter(this.archivedClasses, {
            schoolYear: this.selectedSchoolYear,
        });
    }

    hasAssignments(archClass) {
        return !_.isEmpty(archClass.assignments);
    }

    stripArchivedPrefix(className) {
        return _.replace(className, 'Archived: ', '');
    }

    launchAssignment({ id }) {
        return this.providerService.launchAssignmentById(id, 'Instructor');
    }
}
