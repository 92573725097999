import _ from 'lodash';

/**
 * Provides the ability to get the params for the admin usage report.
 * This is a 2 part process:
 * 1. When the route is triggered we load the list of districts associated
 *    to the current user. This will return 1 or more districts.
 * 2. A second call comes from the page (controller) itself to load additional
 *    data such as the list of schools (by selected district) and the applicable
 *    license dates (start of license and end of license).
 *
 * Eventually, we would like to replace this client side logic with a server
 * endpoint that can return this information for a given user.
 * At that point we may want to rethink the 2 step process and condense it to 1
 * to minimize round trips to the network.
 */
export default ($q, adminService, preferences) => {
    'ngInject';

    /**
     * Load the list of districts to which the user's current role has access.
     * For Implementation Specialists and District Admins this is simply the
     * the districts to which they have a membership.
     * For school admins, we need to load one of their schools in order to get
     * the district information.
     * Note: it is an assumption/convention of the system that a school admin
     * will have memberships to schools within ONLY 1 district.
     */
    function fetchAvailableDistricts(user) {
        const districts = user.getDistrictsWithAdministrativeAccess();
        if (!_.isEmpty(districts)) {
            return $q.resolve(districts);
        }

        return getDistrictForSchoolAdmin(user).then(x => [x]);
    }

    /**
     * Loads the params that drive the actual usage report form.
     * This includes the list of available schools and the license term dates
     * that can be used to limit min and max date selection.
     */
    function fetchUsageReportParams(districtId, user) {
        if (user.isSchoolAdministrator()) {
            return createReportParamsForSchoolAdmin(districtId, user);
        }

        return adminService.getDistrictById(districtId).then(createReportParamsForDistrict);
    }

    function getDistrictForSchoolAdmin(user) {
        const schools = user.getSchoolsWithAdministrativeAccess();
        const firstSchool = _.head(schools);
        return adminService
            .getSchoolById(firstSchool.id)
            .then(x => _.pick(x.parent, ['id', 'name']));
    }

    function createReportParamsForSchoolAdmin(districtId, user) {
        const schools = user.getSchoolsWithAdministrativeAccess();
        const licenseTermDates = getLicenseTermDates();
        return $q.resolve({ districtId, schools, licenseTermDates });
    }

    function createReportParamsForDistrict(district) {
        const licenseTermDates = getLicenseTermDates();
        const schools = _.map(district.institutions, x => _.pick(x, ['id', 'name']));
        return {
            districtId: district.id,
            schools,
            licenseTermDates,
            districtOnly: preferences.isAdminUsageDistrictOnly(district.id),
        };
    }

    /**
     * Eventually we want these dates to be contextual based on the report params.
     * Currently, our license data is inaccurate for some districts and rather
     * than use incorrect data we are making the decision to just use hard coded
     * defaults that will work well enough for everyone.
     * see: https://jira2.iparadigms.com/browse/RA-1436
     */
    function getLicenseTermDates() {
        const startAsUTC = new Date('2016-08-18');
        const timezoneOffset = startAsUTC.getTimezoneOffset();
        const startAsLocal = new Date(
            startAsUTC.setMinutes(startAsUTC.getMinutes() + timezoneOffset)
        );

        const today = new Date();
        const yesterday = new Date(today.setDate(today.getDate() - 1));

        return {
            start: startAsLocal,
            end: yesterday,
        };
    }

    return {
        fetchAvailableDistricts,
        fetchUsageReportParams,
    };
};
