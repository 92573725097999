import _ from 'lodash';

import {
    ADMIN_VIEW_TEACHERS,
    ADMIN_VIEW_ADMINISTRATORS,
    ADMIN_VIEW_USAGE_REPORT,
} from './admin-permissions';

import { isCurrentTeacherTrialUser } from '../payment/payment-authorization';

const defaultPaginationOptions = {
    page: 1,
    count: 20,
};

const getPaginationOptions = function(page) {
    return _.merge({ page }, defaultPaginationOptions);
};

function createBulkUploadOrderDefinition() {
    return {
        abstract: true,
        url: '/bulk-upload/order/:orderId',
        template: '<ui-view />',
        resolve: {
            /* @ngInject */
            upload($stateParams, bulkUploadService, bulkUploadOrderTransformer) {
                const orderId = parseInt($stateParams.orderId, 10);

                return bulkUploadService
                    .getBulkUploadOrder(orderId)
                    .then(bulkUploadOrderTransformer.transformOrder);
            },
        },
    };
}

function createBulkUploadDetailsDefinition() {
    return {
        url: '/detail',
        controller: /* @ngInject */ function(upload, ownerName, school) {
            this.upload = upload;
            this.ownerName = ownerName;
            this.school = school;
        },
        controllerAs: '$ctrl',
        template: `
            <abu-order-detail upload="$ctrl.upload" owner-name="$ctrl.ownerName" school="$ctrl.school"></abu-order-detail>
        `,
        resolve: {
            /* @ngInject */
            ownerName(upload, adminService) {
                return adminService.getAccountById(upload.ownerId).then(res => {
                    const fullName = `${res.first_name} ${res.last_name}`;
                    return _.trim(fullName);
                });
            },
        },
    };
}

function createBulkUploadOutcomeDefinition() {
    return {
        url: '/outcome',
        template: `
            <abu-order-outcome upload="$ctrl.upload" school="$ctrl.school" role="teacher"></abu-order-outcome>
        `,
        controller: /* @ngInject */ function(upload, school) {
            this.upload = upload;
            this.school = school;
        },
        controllerAs: '$ctrl',
    };
}

function isAuthorizedForBulkUpload(user, preferences, params) {
    // TODO: This logic is repeated in 2 places - it needs a home!
    // This function isn't dependency injected which could make things tricky
    const { schoolId } = params;
    const hasBulkUploadRole =
        user.isDistrictAdministrator() ||
        user.isImplementationSpecialist() ||
        user.isSchoolAdministrator();

    return hasBulkUploadRole && preferences.isBulkUploadV2Enabled(schoolId);
}

// @ngInject
export default function adminRoutes($stateProvider) {
    $stateProvider
        .state('admin', {
            abstract: true,
            url: '/admin',
            template: `
                <div class="admin">
                    <admin-sidenav class="sidenav"></admin-sidenav>
                    <div>
                        <ui-view></ui-view>
                    </div>
                </div>
            `,
            resolve: {
                updateAdminSidenavStore: /* @ngInject */ (user, adminSidenavStore) => {
                    if (user.isDistrictAdministrator()) {
                        const [district] = user.getDistrictsWithAdministrativeAccess();
                        return adminSidenavStore.updateDistrict(district);
                    } else if (user.isSingleSchoolAdministrator()) {
                        const [school] = user.getSchoolsWithAdministrativeAccess();
                        return adminSidenavStore.updateSchool(school);
                    }
                },
            },
            data: {
                isAuthorized(user) {
                    return user.hasAdministratorRole() || user.hasImplementationSpecialistRole();
                },
            },
        })
        .state('admin.accounts-redirect', {
            url: '/accounts/redirect',
            controller: /* @ngInject */ adminSidenavStore => {
                adminSidenavStore.goToAccountsPage();
            },
        })
        .state('admin.district', {
            abstract: true,
            template: '<ui-view />',
            url: '/district/:districtId',
            resolve: {
                district: /* @ngInject */ (
                    adminService,
                    $stateParams,
                    adminSidenavStore,
                    accountDisplay
                ) => {
                    const districtId = parseInt($stateParams.districtId, 10);
                    return adminService.getDistrictById(districtId).then(district => {
                        adminSidenavStore.updateDistrict(district);
                        return accountDisplay.decorateDistrict(district);
                    });
                },
                showGoogleInfo: (user, district) => district.is_google_sso_enabled,
            },
        })
        .state('admin.district.index', {
            url: '',
            templateUrl: 'admin/home/district.html',
            controller: 'DistrictAdminHomeController',
            controllerAs: 'ctrl',
        })
        .state('admin.district.administrators', {
            abstract: true,
            url: '/administrators',
            template: '<ui-view />',
            resolve: {
                group: /* @ngInject */ district => district,
            },
        })
        .state('admin.district.administrators.index', {
            url: '?page',
            templateUrl: 'admin/school/admin/admin-accounts/admin-accounts.html',
            controller: 'AdminAccountsController',
            controllerAs: '$ctrl',
            resolve: {
                admins: /* @ngInject */ (adminService, $stateParams, district) => {
                    return adminService.getDistrictAdminAccounts(
                        district.id,
                        getPaginationOptions($stateParams.page)
                    );
                },
            },
        })
        .state('admin.district.administrators.create-account', {
            url: '/new',
            templateUrl: 'admin/school/admin/create-admin-account/create-admin-account.html',
            controller: 'CreateAdminAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.district.administrators.success', {
            url: '/success',
            templateUrl:
                'admin/school/admin/create-admin-account-success/create-admin-account-success.html',
            controller: 'CreateAdminAccountSuccessController',
            controllerAs: '$ctrl',
            params: {
                email: undefined,
                password: undefined,
                first_name: undefined,
                last_name: undefined,
            },
        })
        .state('admin.district.administrators.administrator', {
            url: '/:identityId',
            abstract: true,
            template: '<ui-view />',
            params: {
                // $state.go with option {reload: true} isn't working to repopulate the target user with updated data
                // the targetUserOverride parameter is being used as a hack (hopefully only short-term)
                targetUserOverride: undefined,
            },
            resolve: {
                admin: /* @ngInject */ ($stateParams, adminService) => {
                    const adminId = parseInt($stateParams.identityId, 10);
                    return adminService.getAccountById(adminId);
                },
                targetUser: /* @ngInject */ admin => admin,
            },
        })
        .state('admin.district.administrators.administrator.detail', {
            url: '',
            templateUrl: 'admin/school/admin/view-admin-account/view-admin-account.html',
            controller: 'ViewAdminAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.district.administrators.administrator.edit', {
            url: '/edit',
            templateUrl: 'admin/school/admin/edit-admin-account/edit-admin-account.html',
            controller: 'EditAdminAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.district.administrators.administrator.change-password', {
            url: '/change-password',
            templateUrl: 'admin/school/admin/change-admin-password/change-admin-password.html',
            controller: 'ChangeAdminPasswordController',
            controllerAs: '$ctrl',
        })
        .state('admin.school', {
            url: '/school/:schoolId',
            abstract: true,
            template: '<ui-view />',
            resolve: {
                school: /* @ngInject */ (
                    adminService,
                    $stateParams,
                    adminSidenavStore,
                    accountDisplay
                ) => {
                    return adminService.getSchoolById($stateParams.schoolId).then(school => {
                        adminSidenavStore.updateSchool(school);
                        return accountDisplay.decorateSchool(school);
                    });
                },
                district: /* @ngInject */ (school, adminSidenavStore) => {
                    let district =
                        school.parent && school.parent.group_type.toLowerCase() === 'district'
                            ? school.parent
                            : null;
                    adminSidenavStore.updateDistrict(district);
                    return district;
                },
                isBulkUploadV2Available: /* ngInject */ (user, preferences, school) => {
                    const hasBulkUploadRole =
                        user.isDistrictAdministrator() ||
                        user.isImplementationSpecialist() ||
                        user.isSchoolAdministrator();

                    // TODO: This logic is repeated in 2 places - it needs a home!
                    return hasBulkUploadRole && preferences.isBulkUploadV2Enabled(school.id);
                },
            },
        })
        .state('admin.school.index', {
            url: '',
            templateUrl: 'admin/school/dashboard/school-dashboard.html',
            controller: 'SchoolDashboardController',
            controllerAs: '$ctrl',
            resolve: {
                ordersV2(
                    bulkUploadService,
                    school,
                    isBulkUploadV2Available,
                    abuAccountActionsHelper
                ) {
                    /* @ngInject */
                    if (!isBulkUploadV2Available) {
                        return [];
                    }

                    return bulkUploadService
                        .getAllBulkUploadOrdersForGroup(school.id)
                        .then(abuAccountActionsHelper.createAccountActionUploads);
                },
                showGoogleInfo: (user, school) => school.is_google_sso_enabled,
                fteLimitReached: (user, school) =>
                    isCurrentTeacherTrialUser(user) && school.license.in_use >= school.license.max,
            },
        })
        .state('admin.school.students', {
            abstract: true,
            url: '/students',
            template: '<ui-view />',
        })
        .state('admin.school.students.index', {
            url: '?page',
            templateUrl: 'admin/school/students/student-accounts/student-accounts.html',
            controller: 'StudentAccountsController',
            controllerAs: '$ctrl',
            resolve: {
                students: /* @ngInject */ (adminService, $stateParams, school) => {
                    return adminService.getStudentAccountsBySchool(
                        school.id,
                        getPaginationOptions($stateParams.page)
                    );
                },
            },
        })
        .state('admin.school.students.bulk-upload', {
            url: '/bulk-upload',
            templateUrl:
                'admin/bulk-upload/admin-bulk-upload-student/admin-bulk-upload-student.html',
            controller: 'AdminBulkUploadStudentController',
            controllerAs: '$ctrl',
            data: {
                isAuthorized: isAuthorizedForBulkUpload,
            },
            resolve: {
                previousConfig(bulkUploadService, school) {
                    return bulkUploadService.getStudentConfig(school.id);
                },
            },
        })
        .state('admin.school.bulk-upload', {
            abstract: true,
            url: '/bulk-upload',
            template: '<ui-view />',
        })
        .state('admin.school.bulk-upload.order', {
            abstract: true,
            url: '/order/:orderId',
            template: '<ui-view />',
            resolve: {
                upload($stateParams, bulkUploadService, bulkUploadOrderTransformer) {
                    /* @ngInject */
                    const orderId = parseInt($stateParams.orderId, 10);

                    return bulkUploadService
                        .getBulkUploadOrder(orderId)
                        .then(bulkUploadOrderTransformer.transformOrder);
                },
            },
        })
        .state('admin.school.students.bulk-upload-order', createBulkUploadOrderDefinition())
        .state(
            'admin.school.students.bulk-upload-order.outcome',
            createBulkUploadOutcomeDefinition()
        )
        .state(
            'admin.school.students.bulk-upload-order.details',
            createBulkUploadDetailsDefinition()
        )
        .state('admin.school.students.create-account', {
            url: '/new',
            templateUrl: 'admin/school/students/create-student-account/create-student-account.html',
            controller: 'CreateStudentAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.students.success', {
            url: '/success',
            templateUrl:
                'admin/school/students/create-student-account-success/create-student-account-success.html',
            controller: 'CreateStudentAccountSuccessController',
            controllerAs: '$ctrl',
            params: {
                username: undefined,
                email: undefined,
                password: undefined,
                first_name: undefined,
                last_name: undefined,
            },
        })
        .state('admin.school.students.student', {
            url: '/:identityId',
            abstract: true,
            template: '<ui-view />',
            params: {
                // $state.go with option {reload: true} isn't working to repopulate the target user with updated data
                // the targetUserOverride parameter is being used as a hack (hopefully only short-term)
                targetUserOverride: undefined,
            },
            resolve: {
                student: /* @ngInject */ ($stateParams, adminService) => {
                    const studentId = parseInt($stateParams.identityId, 10);
                    return adminService.getAccountById(studentId);
                },
                targetUser: /* @ngInject */ student => student,
            },
        })
        .state('admin.school.students.student.detail', {
            url: '',
            templateUrl: 'admin/school/students/view-student-account/view-student-account.html',
            controller: 'ViewStudentAccountController',
            controllerAs: '$ctrl',
            params: {
                targetUserOverride: undefined,
            },
            resolve: {
                schoolId: /* @ngInject */ school => school.id,
            },
        })
        .state('admin.school.students.student.edit', {
            url: '/edit',
            templateUrl: 'admin/school/students/edit-student-account/edit-student-account.html',
            controller: 'EditStudentAccountController',
            controllerAs: '$ctrl',
            resolve: {
                schoolId: /* @ngInject */ school => school.id,
            },
        })
        .state('admin.school.students.student.change-password', {
            url: '/change-password',
            templateUrl:
                'admin/school/students/change-student-password/change-student-password.html',
            controller: 'ChangeStudentPasswordController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.teachers', {
            abstract: true,
            url: '/teachers',
            template: '<ui-view />',
        })
        .state('admin.school.teachers.index', {
            url: '?page',
            templateUrl: 'admin/school/teachers/teacher-accounts/teacher-accounts.html',
            controller: 'TeacherAccountsController',
            controllerAs: '$ctrl',
            data: {
                isAuthorized(user, preferences, { schoolId }) {
                    return user.hasGroupPermission(ADMIN_VIEW_TEACHERS, schoolId);
                },
            },
            resolve: {
                teachers: /* @ngInject */ (adminService, $stateParams, school) => {
                    return adminService.getTeacherAccountsBySchool(
                        school.id,
                        getPaginationOptions($stateParams.page)
                    );
                },
            },
        })
        .state('admin.school.teachers.bulk-upload', {
            url: '/bulk-upload',
            template:
                '<admin-bulk-upload-v2 account-type="teacher" school="$ctrl.school" previous-config="$ctrl.previousConfig"></admin-bulk-upload-v2>',
            controller: /* @ngInject */ function(school, previousConfig) {
                this.school = school;
                this.previousConfig = previousConfig;
            },
            controllerAs: '$ctrl',
            data: {
                isAuthorized: isAuthorizedForBulkUpload,
            },
            resolve: {
                previousConfig(bulkUploadService, school /*preferences*/) {
                    // return preferences.getBulkUploadInstructorConfig(school.id);
                    return bulkUploadService.getTeacherConfig(school.id);
                },
            },
        })
        .state('admin.school.teachers.bulk-upload-order', createBulkUploadOrderDefinition())
        .state(
            'admin.school.teachers.bulk-upload-order.outcome',
            createBulkUploadOutcomeDefinition()
        )
        .state(
            'admin.school.teachers.bulk-upload-order.details',
            createBulkUploadDetailsDefinition()
        )
        .state('admin.school.teachers.create-account', {
            url: '/new',
            templateUrl: 'admin/school/teachers/create-teacher-account/create-teacher-account.html',
            controller: 'CreateTeacherAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.teachers.success', {
            url: '/success',
            templateUrl:
                'admin/school/teachers/create-teacher-account-success/create-teacher-account-success.html',
            controller: 'CreateTeacherAccountSuccessController',
            controllerAs: '$ctrl',
            params: {
                email: undefined,
                password: undefined,
                first_name: undefined,
                last_name: undefined,
            },
        })
        .state('admin.school.teachers.teacher', {
            url: '/:identityId',
            abstract: true,
            template: '<ui-view />',
            params: {
                // $state.go with option {reload: true} isn't working to repopulate the target user with updated data
                // the targetUserOverride parameter is being used as a hack (hopefully only short-term)
                targetUserOverride: undefined,
            },
            resolve: {
                teacher: /* @ngInject */ ($stateParams, adminService) => {
                    const teacherId = parseInt($stateParams.identityId, 10);
                    return adminService.getAccountById(teacherId);
                },
                targetUser: /* @ngInject */ teacher => teacher,
            },
        })
        .state('admin.school.teachers.teacher.detail', {
            url: '',
            templateUrl: 'admin/school/teachers/view-teacher-account/view-teacher-account.html',
            controller: 'ViewTeacherAccountController',
            controllerAs: '$ctrl',
            resolve: {
                schoolId: /* @ngInject */ school => school.id,
            },
        })
        .state('admin.school.teachers.teacher.edit', {
            url: '/edit',
            templateUrl: 'admin/school/teachers/edit-teacher-account/edit-teacher-account.html',
            controller: 'EditTeacherAccountController',
            controllerAs: '$ctrl',
            resolve: {
                schoolId: /* @ngInject */ school => school.id,
                schoolName: /* @ngInject */ school => school.name,
            },
        })
        .state('admin.school.teachers.teacher.change-password', {
            url: '/change-password',
            templateUrl:
                'admin/school/teachers/change-teacher-password/change-teacher-password.html',
            controller: 'ChangeTeacherPasswordController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.administrators', {
            abstract: true,
            url: '/administrators',
            template: '<ui-view />',
            resolve: {
                group: /* @ngInject */ school => school,
            },
        })
        .state('admin.school.administrators.index', {
            url: '?page',
            templateUrl: 'admin/school/admin/admin-accounts/admin-accounts.html',
            controller: 'AdminAccountsController',
            controllerAs: '$ctrl',
            data: {
                isAuthorized(user, preferences, { schoolId }) {
                    return user.hasGroupPermission(ADMIN_VIEW_ADMINISTRATORS, schoolId);
                },
            },
            resolve: {
                admins: /* @ngInject */ (adminService, $stateParams, school) => {
                    return adminService.getAdminAccountsBySchool(
                        school.id,
                        getPaginationOptions($stateParams.page)
                    );
                },
            },
        })
        .state('admin.school.administrators.create-account', {
            url: '/new',
            templateUrl: 'admin/school/admin/create-admin-account/create-admin-account.html',
            controller: 'CreateAdminAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.administrators.success', {
            url: '/success',
            templateUrl:
                'admin/school/admin/create-admin-account-success/create-admin-account-success.html',
            controller: 'CreateAdminAccountSuccessController',
            controllerAs: '$ctrl',
            params: {
                email: undefined,
                password: undefined,
                first_name: undefined,
                last_name: undefined,
            },
        })
        .state('admin.school.administrators.administrator', {
            url: '/:identityId',
            abstract: true,
            template: '<ui-view />',
            params: {
                // $state.go with option {reload: true} isn't working to repopulate the target user with updated data
                // the targetUserOverride parameter is being used as a hack (hopefully only short-term)
                targetUserOverride: undefined,
            },
            resolve: {
                admin: /* @ngInject */ ($stateParams, adminService) => {
                    const adminId = parseInt($stateParams.identityId, 10);
                    return adminService.getAccountById(adminId);
                },
                targetUser: /* @ngInject */ admin => admin,
            },
        })
        .state('admin.school.administrators.administrator.detail', {
            url: '',
            templateUrl: 'admin/school/admin/view-admin-account/view-admin-account.html',
            controller: 'ViewAdminAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.administrators.administrator.edit', {
            url: '/edit',
            templateUrl: 'admin/school/admin/edit-admin-account/edit-admin-account.html',
            controller: 'EditAdminAccountController',
            controllerAs: '$ctrl',
        })
        .state('admin.school.administrators.administrator.change-password', {
            url: '/change-password',
            templateUrl: 'admin/school/admin/change-admin-password/change-admin-password.html',
            controller: 'ChangeAdminPasswordController',
            controllerAs: '$ctrl',
        })
        .state('admin.multi_school', {
            url: '/schools',
            templateUrl: 'admin/home/multi-school.html',
            controller: 'MultiSchoolAdminHomeController',
            controllerAs: '$ctrl',
            resolve: {
                schools: /* @ngInject */ (user, adminService, $q, authService, accountDisplay) => {
                    const adminMemberships = _.get(user.getUser(), 'memberships.Administrator', []);
                    const schools = adminMemberships
                        .filter(membership => membership.group_type.toLowerCase() === 'institution')
                        .map(membership => adminService.getSchoolById(membership.id));

                    return $q
                        .all(schools)
                        .then(schools =>
                            schools.map(school => accountDisplay.decorateSchool(school))
                        );
                },
            },
        })
        .state('admin.usage-report', {
            url: '/usage-report',
            templateUrl: 'admin/usage-report/usage-report.html',
            controller: 'UsageReportController',
            controllerAs: '$ctrl',
            resolve: {
                availableDistricts: (user, usageReportFactory) => {
                    /* @ngInject */
                    return usageReportFactory.fetchAvailableDistricts(user);
                },
            },
            data: {
                isAuthorized(user) {
                    return user.hasGeneralPermission(ADMIN_VIEW_USAGE_REPORT);
                },
            },
        });
}
