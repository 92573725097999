import angular from 'angular';
import uiRouter from 'angular-ui-router';

import user from '../user/user';

import authorizationFactory from './authorization-factory';

const authorizationModule = angular.module('authorization', [uiRouter, user]);

authorizationModule.factory('authorizationFactory', authorizationFactory);

export default authorizationModule.name;
