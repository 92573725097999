import angular from 'angular';
import uiRouter from 'angular-ui-router';
import eventMediator from 'gong-event-mediator';

import authorization from '../../authorization/authorization';
import user from '../../user/user';

import specialistSidenavComponent from './specialist-sidenav-component';
import specialistSidenavStore from './specialist-sidenav-store';

const specialistSidenav = angular.module('specialistSidenav', [
    uiRouter,
    eventMediator,
    authorization,
    user,
]);

specialistSidenav.component('specialistSidenav', specialistSidenavComponent);
specialistSidenav.factory('specialistSidenavStore', specialistSidenavStore);

export default specialistSidenav.name;
