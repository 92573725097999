import angular from 'angular';
import uiRouter from 'angular-ui-router';
import eventMediator from 'gong-event-mediator';

import accounts from '../../components/accounts/accounts';
import adminShared from '../../../shared/shared';

import AdminAccountsController from './admin-accounts-controller';

const adminAccounts = angular.module('adminAccounts', [
    accounts,
    uiRouter,
    adminShared,
    eventMediator,
]);
adminAccounts.controller('AdminAccountsController', AdminAccountsController);

export default adminAccounts.name;
