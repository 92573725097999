import angular from 'angular';
import ngMessages from 'angular-messages';
import uiRouter from 'angular-ui-router';
import eventMediator from 'gong-event-mediator';
import utilsForm from '../../../utils-form/utils-form';
import user from '../../../user/user';
import loginShared from '../../shared/shared';
import ChangePasswordController from './change-password-controller';
const changePassword = angular.module('changePassword', [
    ngMessages,
    eventMediator,
    uiRouter,
    utilsForm,
    user,
    loginShared,
]);

changePassword.controller('ChangePasswordController', ChangePasswordController);
export default changePassword.name;
