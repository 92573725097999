import _ from 'lodash';

function pickAsCamelCase(obj, props) {
    return _(obj)
        .mapKeys((val, key) => _.camelCase(key))
        .pick(props)
        .value();
}

export function transformHomeData({ data }) {
    return {
        classes: data.classes,
        hasArchivedClasses: !!data.has_archived_classes,
    };
}

export function transformOneArchivedAssignment(ass) {
    return pickAsCamelCase(ass, [
        'id',
        'name',
        'promptTitle',
        'promptGenre',
        'promptSkillLevel',
        'assignmentType',
    ]);
}

export function transformOneArchivedClass(archClass) {
    const classProps = pickAsCamelCase(archClass, ['id', 'name', 'time', 'schoolYear']);
    const assignments = _.map(archClass.assignments, transformOneArchivedAssignment);

    return _.assign({}, classProps, { assignments });
}

export function transformArchivedClassData({ data }) {
    return _.map(data.classes, transformOneArchivedClass);
}
