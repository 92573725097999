// @ngInject
export default function sendEmailService($http, $q, urls) {
    return {
        resetPassword(email) {
            return $http({
                method: 'GET',
                url: `${urls.base}/ra/identity/reset_token`,
                params: { email },
            })
                .then(res => res.data)
                .catch(res => $q.reject(res.data));
        },
    };
}
