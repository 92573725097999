import angular from 'angular';

import urlConfig from '../../url-config/url-config';
import interactionShield from '../../interaction-shield/interaction-shield';
import adminShared from '../shared/shared';
import usageReportComponents from './components/components';

import UsageReportController from './usage-report-controller';
import usageReportFactory from './usage-report-factory';
import usageReportDownloadFactory from './usage-report-download-factory';

const usageReportModule = angular.module('usageReport', [
    urlConfig,
    interactionShield,
    adminShared,
    usageReportComponents,
]);

usageReportModule.controller('UsageReportController', UsageReportController);
usageReportModule.factory('usageReportFactory', usageReportFactory);
usageReportModule.factory('usageReportDownloadFactory', usageReportDownloadFactory);

export default usageReportModule.name;
