import angular from 'angular';

import utilsForm from '../../../../utils-form/utils-form';

import loginIdentityComponent from './login-identity-component';

const loginIdentity = angular.module('loginIdentity', [utilsForm]);
loginIdentity.component('loginIdentity', loginIdentityComponent);

export default loginIdentity.name;
