import angular from 'angular';

import userAccount from '../../user-account/user-account';

import demoService from './demo-service';

const demoShared = angular.module('demoShared', [userAccount]);
demoShared.factory('demoService', demoService);

export default demoShared.name;
