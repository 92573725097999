import angular from 'angular';
import uiRouter from 'angular-ui-router';

import abuOrderOutcomeComponent from './abu-order-outcome-component';
import shared from '../shared/shared';
import abuUploadSummaryTable from '../abu-upload-summary-table/abu-upload-summary-table';

const abuOrderOutcome = angular.module('abuOrderOutcome', [
    uiRouter,
    shared,
    abuUploadSummaryTable,
]);
abuOrderOutcome.component('abuOrderOutcome', abuOrderOutcomeComponent);

export default abuOrderOutcome.name;
