/**
 * DEPRECATED!
 * See `./file-select-component` for a replacement component.
 *
 * Applied as an attribute to a `<input type="file" />` element.
 * Uses 2-way data binding to assign files to a property on your scope/controller.
 */
function fileInput($parse) {
    'ngInject';

    function link(scope, elm, attrs) {
        function handleOnChange() {
            scope.$apply(function() {
                $parse(attrs.fileInput).assign(scope, elm[0].files);
            });
        }

        elm.on('change', handleOnChange);
        scope.$on('$destroy', () => elm.off('change', handleOnChange));
    }

    return { restrict: 'A', link };
}

export default fileInput;
