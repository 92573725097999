export const ASSIGNMENT_TYPES = {
    SIGNAL_CHECK: 'signal check',
    SPOT_CHECK: 'spot check',
    EXPANSION_PACK: 'expansion pack',
};

export const ASSIGNMENT_TYPE_NAMES = {
    [ASSIGNMENT_TYPES.SIGNAL_CHECK]: 'Signal Check',
    [ASSIGNMENT_TYPES.SPOT_CHECK]: 'Spot Check',
    [ASSIGNMENT_TYPES.EXPANSION_PACK]: 'Expansion Pack',
};
