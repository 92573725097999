import _ from 'lodash';

import { knownPreferences } from '../../../../user/preferences-factory';
import { isCurrentTeacherTrialUser } from '../../../../payment/payment-authorization';

export default class StudentAccountsController {
    // @ngInject
    constructor(
        students,
        adminService,
        $stateParams,
        $state,
        eventMediator,
        preferences,
        school,
        user,
        userAccountService
    ) {
        this.adminService = adminService;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.eventMediator = eventMediator;
        this.preferences = preferences;
        this.school = school;
        this.students = students;
        this.userAccountService = userAccountService;

        const groupId = this.school.id;
        this.createAccountRef = `admin.school.students.create-account({ schoolId: ${groupId} })`;
        this.createBulkAcccountsRef = `admin.school.students.bulk-upload({ schoolId: ${groupId} })`;

        this.showTTAccountCreationDisabledMessage = this.canAccessTTAccountCreation(user);
    }

    canAccessTTAccountCreation(user) {
        return (
            isCurrentTeacherTrialUser(user) &&
            this.school.license.in_use === this.school.license.max
        );
    }

    handleRemove(student) {
        this.adminService.removeStudentById(this.school.id, student.identity_id).then(success => {
            this.removeDisplayFTELimitTooltipDismissedPreference();
            this.fireRemoveNotification(student, success.members);
            this.$state.go(this.$state.current, this.$stateParams, { reload: true });
        });
    }

    removeDisplayFTELimitTooltipDismissedPreference() {
        this.preferences.setDisplayFTELimitTooltipDismissed(false);
        const prefs = {
            [knownPreferences.teacher_trial_fte_limit_tooltip_dismissed]: false,
        };
        this.userAccountService.storePreferences(prefs);
    }

    fireRemoveNotification(student, members) {
        let membershipId = _.get(members, '[0].id', undefined);
        if (!membershipId) return;

        const undoData = {
            url: `/ra/admin/school/${this.school.id}/membership/${membershipId}/restart`,
            method: 'POST',
            data: { id: student.id },
        };

        const message = `You have successfully removed <strong>${_.escape(
            student.first_name + ' ' + student.last_name
        ).trim()}</strong>'s student account.<a api-action=${angular.toJson(undoData)}>Undo</a>`;

        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message,
            postStateChange: true,
        });
    }

    handlePageChange(page, search) {
        let params = { page: page, search: search, count: 20 };
        this.adminService.getStudentAccountsBySchool(this.school.id, params).then(students => {
            let currentPage = students.page ? students.page.current : undefined;
            this.students = students;
            this.$state.go(
                '.',
                { page: currentPage, search: search },
                { reload: false, notify: false }
            ); // This is to
        });
    }

    getManageAccountSref(accountId) {
        return `admin.school.students.student.detail({ schoolId: ${this.school
            .id}, identityId: ${accountId} })`;
    }
}
