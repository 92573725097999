export default function expirationPendingModalFactory($uibModal) {
    'ngInject';

    return {
        prompt(modalNameToShow) {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'payment/expiration-pending-modal/expiration-pending-modal.html',
                controller: 'ExpirationPendingModalController',
                controllerAs: '$ctrl',
                resolve: {
                    modalNameToShow: () => modalNameToShow,
                },
            }).result;
        },
    };
}
