import _ from 'lodash';

const KNOWN_REDIRECTS = {
    accounts: 'admin.accounts-redirect',
    'admin-reports': 'admin.usage-report',
    archivedClasses: 'teacher.archived-classes',
};

export const DEFAULT = 'noop';
export const REDIRECT_TO = 'redirect-to';
export const FIRE_NOTIFICATION = 'fire-notification';
const KNOWN_REDIRECT_ACTIONS = [DEFAULT, REDIRECT_TO, FIRE_NOTIFICATION];

export const UNDO_DELETE_ASSIGNMENT_NOTIFICATION = 'undo-delete-assignment-notification';

const DEFAULT_ACTION_BODY = { action: DEFAULT, payload: {} };

function parseRedirectData(encryptedData) {
    if (!encryptedData) return {};

    const decodedData = decodeURIComponent(encryptedData);
    const stringifiedData = decodedData.toString();
    const parsedData = JSON.parse(stringifiedData);

    if (!KNOWN_REDIRECT_ACTIONS.includes(parsedData.action)) return DEFAULT_ACTION_BODY;
    return parsedData;
}

const NOTIFICATION_PARSERS = {
    [UNDO_DELETE_ASSIGNMENT_NOTIFICATION]: payload => {
        const { assignmentId, assignmentName, assignmentState, classTime, className } = payload;

        const undoData = {
            url: `/ra/assignments/${assignmentId}`,
            method: 'PUT',
            data: { state: assignmentState },
        };

        const message = `<strong>${_.escape(
            assignmentName
        )}</strong> has been deleted from <strong>${_.escape(
            classTime ? `${classTime} - ${className}` : className
        )}</strong>.<a api-action=${JSON.stringify(undoData)}>Undo</a>`;

        return { type: 'success', message };
    },
};

function parseFireNotification(eventMediator) {
    return ({ notificationType, payload }) => {
        const { type, message } = NOTIFICATION_PARSERS[notificationType](payload);
        eventMediator.emit('messageNotification', {
            postStateChange: true,
            type,
            message,
        });
    };
}

export default /* @ngInject */ (eventMediator, authService) => {
    const ACTION_MAP = {
        [DEFAULT]: _.noop,
        [REDIRECT_TO]: payload =>
            authService.redirect({
                toState: KNOWN_REDIRECTS[payload] || null,
            }),
        [FIRE_NOTIFICATION]: parseFireNotification(eventMediator),
    };

    function processRedirectData(encryptedData) {
        const { action, payload } = parseRedirectData(encryptedData);
        const actions = _.uniq([action || DEFAULT, REDIRECT_TO]);
        actions.forEach(currAction => ACTION_MAP[currAction](payload));
    }

    return {
        processRedirectData,
    };
};
