import angular from 'angular';

import shared from '../../components/components';

import ChangeStudentPasswordController from './change-student-password-controller';

const changeStudentPassword = angular.module('changeStudentPassword', [shared]);
changeStudentPassword.controller(
    'ChangeStudentPasswordController',
    ChangeStudentPasswordController
);

export default changeStudentPassword.name;
