export default function UtilsService() {
    'ngInject';

    //sorted teacher-class dropdown lists
    this.classGradeOptions = ['6', '7', '8', '9', '10', '11', '12', 'Other'];
    this.classSubjectOptions = [
        'ELA',
        'English',
        'History',
        'Reading',
        'Social Sciences',
        'Writing',
        'Other',
    ];
}
