class OnboardingController {
    // @ngInject
    constructor($scope, eventMediator, onboardingService) {
        this.$scope = $scope;
        this.eventMediator = eventMediator;
        this.onboardingService = onboardingService;
    }

    $onInit() {
        this.hideAllOnboarding();

        ['login-complete', 'auth.role.changed.transitioned-home'].forEach(evtName => {
            this.eventMediator.subscribe(this.$scope, evtName, () => {
                if (this.onboardingService.userHasCompletedOnboarding()) return;
                this.showOnboarding();
            });
        });

        this.eventMediator.subscribe(this.$scope, 'onboarding.show', () => this.showOnboarding());
    }

    hideAllOnboarding() {
        this.showAdminOnboarding = false;
        this.showTeacherOnboarding = false;
    }

    showOnboarding() {
        this.showAdminOnboarding = this.onboardingService.userHasAdministratorOnboarding();
        this.showTeacherOnboarding = this.onboardingService.userHasTeacherOnboarding();

        this.eventMediator.subscribe(this.$scope, '$stateChangeSuccess', () =>
            this.handleDismissOnboarding(() => {}, '')
        );
    }

    handleDismissOnboarding(
        markCompleteCallback = () => {},
        eventToEmit = 'onboarding.header.relaunchability-notification.show'
    ) {
        this.eventMediator.unsubscribe(this.$scope, '$stateChangeSuccess');
        this.hideAllOnboarding();
        if (this.onboardingService.userHasCompletedOnboarding()) return;

        this.eventMediator.emit(eventToEmit);
        markCompleteCallback();
    }

    handleDismissTeacherOnboarding() {
        this.handleDismissOnboarding(() => this.onboardingService.markTeacherOnboardingComplete());
    }

    handleDismissAdminOnboarding() {
        this.handleDismissOnboarding(() =>
            this.onboardingService.markAdministratorOnboardingComplete()
        );
    }
}

export default {
    controller: OnboardingController,
    template: `
        <teacher-onboarding-carousel ng-if="$ctrl.showTeacherOnboarding" on-view-later="$ctrl.handleDismissTeacherOnboarding()"
        on-done="$ctrl.handleDismissTeacherOnboarding()"></teacher-onboarding-carousel>
        <admin-onboarding-walkthrough ng-if="$ctrl.showAdminOnboarding" on-view-later="$ctrl.handleDismissAdminOnboarding()" on-done="$ctrl.handleDismissAdminOnboarding()"></admin-onboarding-walkthrough>
    `,
};
