import angular from 'angular';

import CreateStudentAccountSuccessController from './create-student-account-success-controller';

const createStudentAccountSuccess = angular.module('createStudentAccountSuccess', []);
createStudentAccountSuccess.controller(
    'CreateStudentAccountSuccessController',
    CreateStudentAccountSuccessController
);

export default createStudentAccountSuccess.name;
