// @ngInject
export default function pearlyGatesInterceptor($q, $injector, $rootScope) {
    let wantedState;
    let wantedParams;

    $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
        wantedState = toState;
        wantedParams = toParams;
    });

    return {
        responseError(rejection) {
            const authService = $injector.get('authService');
            if (rejection.status === 403) {
                authService.handleForbiddenStatus(wantedState, wantedParams);
            }
            return $q.reject(rejection);
        },
    };
}
