export default class TeacherClassCreatedController {
    // @ngInject
    constructor($log, $state, $stateParams, TeacherClass) {
        this.$log = $log;
        this.teacherClass = TeacherClass;
        this.$state = $state;
    }

    goToClass() {
        this.$state.go('teacher.class.roster', { classId: this.teacherClass.id });
    }
    createAnother() {
        this.$state.go('teacher.create-class');
    }
}
