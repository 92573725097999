// @ngInject
export default function userAccountService(urls, $http, $q) {
    return {
        updateUserAccount(account) {
            const url = `${urls.base}/ra/identity/${account.identity_id}/profile`;
            return $q((resolve, reject) => {
                $http({ url, method: 'PUT', data: account })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(rej => {
                        reject(rej.data);
                    });
            });
        },
        updatePasswordForAccount(account, newPassword) {
            return $q((resolve, reject) => {
                const url = `${urls.base}/ra/identity/${account.identity_id}/password`;
                $http({ url: url, method: 'PUT', data: newPassword })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(res => {
                        reject(res.data);
                    });
            });
        },
        getPreferences() {
            return $http({
                url: `${urls.base}/ra/identity/preferences`,
                method: 'GET',
            })
                .then(res => res.data)
                .catch(rej => rej.data);
        },
        storePreferences(data) {
            return $http({
                url: `${urls.base}/ra/identity/preferences`,
                method: 'POST',
                data,
            })
                .then(res => res.data)
                .catch(rej => rej.data.errors);
        },
    };
}
