import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import uiRouter from 'angular-ui-router';

import authorization from '../../authorization/authorization';
import textUtils from '../../text-utils/text-utils';
import user from '../../user/user';

import adminSidenavComponent from './admin-sidenav-component';
import adminSidenavStore from './admin-sidenav-store';

const adminSidenav = angular.module('adminSidenav', [
    eventMediator,
    uiRouter,
    authorization,
    user,
    textUtils,
]);
adminSidenav.component('adminSidenav', adminSidenavComponent);
adminSidenav.factory('adminSidenavStore', adminSidenavStore);

export default adminSidenav.name;
