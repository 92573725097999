import fp from 'lodash/fp';

const PROMOTIONS_PREFIX = 'promotions.';
const PROMOTIONS_POSTFIX = '.complete';

export function getFormattedPromotion(promotion) {
    return `${PROMOTIONS_PREFIX}${promotion}${PROMOTIONS_POSTFIX}`;
}

// @ngInject
export default function promotionsFactory(preferences, userAccountService) {
    function getPromotions(groupIds) {
        const promotions = preferences.getEnabledFeatures(PROMOTIONS_PREFIX, groupIds);

        return fp.reduce((acc, promo) => {
            const preferenceCompletedFlag = getFormattedPromotion(promo);
            const completionStatuses = _.map(groupIds, gId =>
                preferences.getPreference(gId, preferenceCompletedFlag)
            );
            // if any groups have flagged complete, don't show it.
            return _.some(completionStatuses) ? acc : [...acc, promo];
        }, [])(promotions);
    }

    function markPromotionDismissed(promotion) {
        preferences.setPromotionComplete(promotion);
        userAccountService.storePreferences({
            [getFormattedPromotion(promotion)]: true,
        });
    }

    return {
        getPromotions,
        markPromotionDismissed,
    };
}
