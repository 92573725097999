import React from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'frontend-ra-shared';
import { IntlProvider, FormattedMessage } from 'react-intl';

class RelaunchOnboardingTooltipController {
    // @ngInject
    constructor($document, $element, eventMediator) {
        this.$element = $element;
        this.$document = $document;
        this.eventMediator = eventMediator;
    }

    $onInit() {
        this.renderReact();
    }

    close() {
        this.eventMediator.emit('onboarding.header.relaunchability-notification.hide');
    }

    getReactAttachPoint() {
        const reactAttachSelector = '.attach-relaunch-onboarding-tooltip';
        return this.$element[0].querySelector(reactAttachSelector);
    }

    getHelpIcon() {
        return this.$document[0].querySelector(this.helpIconSelector);
    }

    // eslint-disable-next-line lodash/prefer-constant
    getPlacement() {
        return 'bottom';
    }

    renderReact() {
        ReactDOM.render(
            <IntlProvider locale="en">
                <div>
                    <Tooltip
                        id="relaunchable-onboarding-tooltip"
                        getTarget={this.getHelpIcon.bind(this)}
                        getPlacement={this.getPlacement.bind(this)}
                        offset={20}
                    >
                        <p>
                            <FormattedMessage
                                id="consumer.onboarding.teacher.revisit-tour.body"
                                defaultMessage={`
                                    Click here to revisit this tour
                                    or to visit our Help Guides
                                    for step-by-step help and videos.`}
                            />
                        </p>
                        <button
                            className="btn btn-compact btn-primary"
                            onClick={this.close.bind(this)}
                        >
                            <FormattedMessage
                                id="consumer.onboarding.teacher.revisit-tour.ok"
                                defaultMessage="Got It"
                            />
                        </button>
                    </Tooltip>
                </div>
            </IntlProvider>,
            this.getReactAttachPoint()
        );
    }
}

export default {
    controller: RelaunchOnboardingTooltipController,
    templateUrl: 'header/relaunch-onboarding-tooltip.html',
    bindings: {
        helpIconSelector: '@',
    },
};
