import _ from 'lodash';

const STUDENT_ROUTES = [
    'students.index',
    'students.create-account',
    'students.success',
    'students.student.detail',
    'students.student.edit',
    'students.student.change-password',
    'students.bulk-upload',
    'students.bulk-upload-order.outcome',
    'students.bulk-upload-order.details',
];

const TEACHER_ROUTES = [
    'teachers.index',
    'teachers.create-account',
    'teachers.success',
    'teachers.teacher.detail',
    'teachers.teacher.edit',
    'teachers.teacher.change-password',
    'teachers.bulk-upload',
    'teachers.bulk-upload-order.outcome',
    'teachers.bulk-upload-order.details',
];

const SCHOOL_ADMIN_ROUTES = [
    'administrators.index',
    'administrators.create-account',
    'administrators.success',
    'administrators.administrator.detail',
    'administrators.administrator.edit',
    'administrators.administrator.change-password',
];

const DISTRICT_ADMIN_ROUTES = [
    'administrators.index',
    'administrators.create-account',
    'administrators.success',
    'administrators.administrator.detail',
    'administrators.administrator.edit',
    'administrators.administrator.change-password',
];

export default function adminSidenavStore(
    $rootScope,
    $state,
    user,
    authorizationFactory,
    eventMediator
) {
    'ngInject';
    let adminSidenavData = {};

    // null checks here are for tests
    if (eventMediator && eventMediator.subscribe) {
        eventMediator.subscribe($rootScope, 'logout', () => {
            adminSidenavData = {};
        });
    }

    function isAtSchoolSubpage() {
        return $state.includes('admin.school');
    }

    function isAtDistrictSubpage() {
        return $state.includes('admin.district');
    }

    function showDistrictsLink() {
        if (user.isLtiAdmin()) {
            return false;
        }
        return user.isImplementationSpecialist();
    }

    function showCurrentDistrictLink() {
        if (!adminSidenavData.district || user.isLtiAdmin()) {
            return false;
        }

        const canAccessDistrict =
            user.isImplementationSpecialist() || user.isDistrictAdministrator();
        const isAtDistrictHomepage = $state.is('admin.district');
        const inAtDistrictSubpage =
            (isAtSchoolSubpage() || isAtDistrictSubpage()) && !!adminSidenavData.district;
        const isWithinDistrict = isAtDistrictHomepage || inAtDistrictSubpage;
        const isReportsPage = $state.is('admin.usage-report');

        return canAccessDistrict && (isWithinDistrict || isReportsPage);
    }

    function showSchoolsLink() {
        if (user.isLtiAdmin()) {
            return false;
        }
        return user.isMultiSchoolAdministrator();
    }

    function showSchoolDetailLinks() {
        if (!adminSidenavData.school || user.isLtiAdmin()) {
            return false;
        }

        return (
            isAtSchoolSubpage() ||
            $state.is('admin.usage-report') ||
            $state.is('admin.accounts-redirect')
        );
    }

    function showDistrictAdminsLink() {
        return user.isDistrictAdministrator();
    }

    function showReportsLink() {
        return (
            user.isLtiAdmin() ||
            authorizationFactory.isAuthorizedForStateName('admin.usage-report', user)
        );
    }

    function stateWithSchoolId() {
        return {
            schoolId: adminSidenavData.school && adminSidenavData.school.id,
            ...$state.params,
        };
    }

    function showTeachersIndex() {
        if (!showSchoolDetailLinks()) {
            return false;
        }

        return authorizationFactory.isAuthorizedForStateName(
            'admin.school.teachers.index',
            user,
            stateWithSchoolId()
        );
    }

    function showAdministratorsIndex() {
        if (!showSchoolDetailLinks()) {
            return false;
        }

        return authorizationFactory.isAuthorizedForStateName(
            'admin.school.administrators.index',
            user,
            stateWithSchoolId()
        );
    }

    function isStudentRouteSelected() {
        return isSchoolSubrouteSelected(STUDENT_ROUTES);
    }

    function isTeacherRouteSelected() {
        return isSchoolSubrouteSelected(TEACHER_ROUTES);
    }

    function isSchoolAdminRouteSelected() {
        return isSchoolSubrouteSelected(SCHOOL_ADMIN_ROUTES);
    }

    function isDistrictAdminRouteSelected() {
        return isDistrictSubrouteSelected(DISTRICT_ADMIN_ROUTES);
    }

    function isSchoolSubrouteSelected(subroutes) {
        return subroutes.some(subroute => $state.is(`admin.school.${subroute}`, $state.params));
    }

    function isDistrictSubrouteSelected(subroutes) {
        return subroutes.some(subroute => $state.is(`admin.district.${subroute}`, $state.params));
    }

    function getLinks() {
        return {
            districts: showDistrictsLink(),
            currentDistrict: showCurrentDistrictLink(),
            schools: showSchoolsLink(),
            schoolDetail: showSchoolDetailLinks(),
            districtAdmins: showDistrictAdminsLink(),
            reports: showReportsLink(),
            teachersIndex: showTeachersIndex(),
            administratorsIndex: showAdministratorsIndex(),
        };
    }

    function getAdminSidenavData() {
        return _.cloneDeep(adminSidenavData);
    }

    function goToAccountsPage() {
        const linksDisplay = getLinks();

        if (linksDisplay.districtAdmins) {
            $state.go('admin.district.administrators.index', {
                districtId: adminSidenavData.district.id,
            });
        }

        if (linksDisplay.administratorsIndex) {
            $state.go('admin.school.administrators.index', {
                schoolId: adminSidenavData.school.id,
            });
        }
    }

    return {
        updateDistrict(district) {
            adminSidenavData = _.assign({}, adminSidenavData, { district });
        },
        updateSchool(school) {
            adminSidenavData = _.assign({}, adminSidenavData, { school });
        },
        getAdminSidenavData,
        getLinks,
        goToAccountsPage,
        isStudentRouteSelected,
        isTeacherRouteSelected,
        isSchoolAdminRouteSelected,
        isDistrictAdminRouteSelected,
        isSchoolSubrouteSelected,
        isDistrictSubrouteSelected,
    };
}
