import fp from 'lodash/fp';

const SUCCESS_COPY = {
    heading: fp.constant('Success!'),
    summaryMessage: count =>
        `Your file contained ${count < 0 ? 0 : count} accounts. We did the following:`,
    moreDetailsMessage: fp.constant('For more details about this transaction'),
};

const NEEDS_REVIEW_COPY = {
    heading: fp.constant('Please review your accounts.'),
    summaryMessage: count =>
        `Your file contained ${count < 0
            ? 0
            : count} accounts. Some are ready to go; some need to be reviewed.`,
    moreDetailsMessage: fp.constant('For more details'),
};

function getCopy(needsReview, totalAccounts) {
    const copy = needsReview ? NEEDS_REVIEW_COPY : SUCCESS_COPY;

    return {
        heading: copy.heading(totalAccounts),
        summaryMessage: copy.summaryMessage(totalAccounts),
        moreDetailsMessage: copy.moreDetailsMessage(totalAccounts),
    };
}

function getButtonStates({ recordsCreatedCount, recordsInvalidCount, recordsUnchangedCount }) {
    const availableDownloads = fp.compact([
        recordsCreatedCount > 0 ? 'created' : null,
        recordsInvalidCount > 0 ? 'review' : null,
    ]);

    const buttonStates = {
        isGoToSchoolAvailable: recordsInvalidCount < 1,
        isUploadCorrectedAvailable: recordsInvalidCount > 0,
        isCreateMoreAvailable:
            recordsUnchangedCount > 0 && recordsCreatedCount < 1 && recordsInvalidCount < 1,
        isDownloadSingleAvailable: availableDownloads.length === 1,
        isDownloadAllAvailable: availableDownloads.length > 1,
    };

    return {
        availableDownloads,
        buttonStates,
    };
}

export default function getUiState(upload) {
    const needsReview = upload.recordsInvalidCount > 0;
    const copy = getCopy(needsReview, upload.recordsTotalCount);
    return fp.assignAll([getButtonStates(upload), { copy }]);
}
