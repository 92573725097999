export default function loginModalFactory($uibModal) {
    'ngInject';

    return {
        eulaPrompt() {
            return $uibModal.open({
                animation: false,
                keyboard: false,
                backdrop: 'static',
                windowClass: 'eula-modal',
                templateUrl: 'login/shared/eula-modal.html',
                controller: 'EulaModalController',
                controllerAs: 'ctrl',
            }).result;
        },
        changePasswordPrompt() {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'login/shared/change-password-modal.html',
                controller: 'ChangePasswordModalController',
                controllerAs: 'ctrl',
            }).result;
        },
    };
}
