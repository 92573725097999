export default {
    templateUrl: 'admin/bulk-upload/abu-account-actions/abu-account-actions-component.html',
    bindings: {
        orders: '<',
        isDeletable: '&',
        onDelete: '&',
        onViewDetail: '&',
        onContactSupport: '&',
    },
};
