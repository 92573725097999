import _ from 'lodash';

export default /* @ngInject */ (pluralize, bulkUploadOrderTransformer) => {
    const { pluralizeOrNull } = pluralize;
    const { orderStatus } = bulkUploadOrderTransformer;

    const getNewAccountStatusTextOrNull = pluralizeOrNull(
        count => `${count} new account`,
        count => `${count} new accounts`
    );

    const getNewMembershipStatusTextOrNull = pluralizeOrNull(
        count => `${count} existing account added to this school`,
        count => `${count} existing accounts added to this school`
    );

    const getDeletedMembershipStatusTextOrNull = pluralizeOrNull(
        count => `${count} existing account removed from this school`,
        count => `${count} existing accounts removed from this school`
    );

    const getDuplicateAccountStatusTextOrNull = pluralizeOrNull(
        count => `${count} duplicate account`,
        count => `${count} duplicate accounts`
    );

    const getNeedsReviewAccountStatusTextOrNull = pluralizeOrNull(
        count => `${count} account needs review`,
        count => `${count} accounts need review`
    );

    const getRecordsDeletedStatusTextOrNull = pluralizeOrNull(
        count => `${count} account deleted`,
        count => `${count} accounts deleted`
    );

    const getRecordsUnableToBeDeletedTextOrNull = pluralizeOrNull(
        count => `${count} account unable to be deleted`,
        count => `${count} accounts unable to be deleted`
    );

    function getSuccessStatusText(upload) {
        return _.compact([
            getNewAccountStatusTextOrNull(upload.recordsCreatedIdentityCount),
            getNewMembershipStatusTextOrNull(upload.recordsCreatedMembershipOnlyCount),
            getDuplicateAccountStatusTextOrNull(upload.recordsUnchangedCount),
            getNeedsReviewAccountStatusTextOrNull(upload.recordsInvalidCount),
        ]).join(', ');
    }

    function getDeletedStatusText(upload) {
        return _.compact([
            getRecordsDeletedStatusTextOrNull(upload.recordsDeletedIdentityCount),
            getDeletedMembershipStatusTextOrNull(upload.recordsDeletedMembershipOnlyCount),
            getRecordsUnableToBeDeletedTextOrNull(upload.recordsUnableToBeDeletedCount),
        ]).join(', ');
    }

    const statusTextMapping = {
        [orderStatus.successful]: getSuccessStatusText,
        [orderStatus.processing]: _.constant('File processing...'),
        [orderStatus.unsuccessful]: _.constant('There was a problem. Try again.'),
        [orderStatus.deleting]: _.constant('Removing accounts...'),
        [orderStatus.deleted]: getDeletedStatusText,
    };

    function getStatusText(upload) {
        const unknownStatusFn = _.constant('');
        const statusTextFnOrDefault = _.get(statusTextMapping, upload.status, unknownStatusFn);
        return statusTextFnOrDefault(upload);
    }

    const actionStatusMapping = {
        viewDetails: [orderStatus.successful, orderStatus.deleted],
        contactSupport: [orderStatus.unsuccessful],
    };

    function getActionForStatus(status) {
        return _.findKey(actionStatusMapping, statuses => _.includes(statuses, status)) || null;
    }

    function createAccountActionUpload(upload) {
        return _.assign({}, upload, {
            statusDescription: getStatusText(upload),
            action: getActionForStatus(upload.status),
        });
    }

    function createAccountActionUploads(uploads) {
        return _.map(uploads, createAccountActionUpload);
    }

    return {
        createAccountActionUpload,
        createAccountActionUploads,
    };
};
