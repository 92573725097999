//this factory overrides $exceptionHandler to capture exceptions with Raven
export default function(ravenService) {
    'ngInject';

    return function(exception) {
        ravenService.captureException(exception);
        if (window.newrelic) {
            newrelic.noticeError(exception);
        }
        // TODO implement an error route and direct traffic there
    };
}
