class SpecialistSidenavController {
    /* @ngInject */
    constructor($state, eventMediator, $scope, user, authorizationFactory, specialistSidenavStore) {
        this.$state = $state;
        this.specialistSidenavStore = specialistSidenavStore;
        this.user = user;
        this.authorizationFactory = authorizationFactory;

        this.specialistSidenavData = specialistSidenavStore.getSpecialistSidenavData();

        eventMediator.subscribe($scope, '$stateChangeSuccess', () => {
            this.specialistSidenavData = this.specialistSidenavStore.getSpecialistSidenavData();
        });
    }

    isActiveNav(...routeNames) {
        return routeNames.some(routeName => this.$state.includes(routeName));
    }

    isAtSchoolSubpage() {
        return this.$state.includes('specialist.create-accounts');
    }

    showSchoolDetailLinks() {
        return this.isAtSchoolSubpage();
    }

    showDistrictLink() {
        return this.isAtSchoolSubpage() && !!this.specialistSidenavData.district;
    }

    showReportsLink() {
        return this.authorizationFactory.isAuthorizedForStateName('admin.usage-report', this.user);
    }
}

export default {
    templateUrl: 'specialist/specialist-sidenav/specialist-sidenav-component.html',
    controller: SpecialistSidenavController,
};
