import _ from 'lodash';

// @ngInject
export default function adminService(urls, $http, $q) {
    function getAccountsByRole(role, groupId, params, group = 'school') {
        // query is {page, count}
        return $q((resolve, reject) => {
            $http({
                url: `${urls.base}/ra/admin/${group}/${groupId}/${role}`,
                method: 'GET',
                params,
            })
                .then(res => resolve(res.data))
                .catch(rej => reject(rej.data));
        });
    }

    function getGroupById(group, groupId) {
        return $q((resolve, reject) => {
            const url = `${urls.base}/ra/admin/${group}/${groupId}`;
            return $http({ url, method: 'GET' })
                .then(res => resolve(_.assign(res.data, { type: group })))
                .catch(rej => reject(rej.data));
        });
    }

    const GROUPS = {
        SCHOOL: 'school',
        DISTRICT: 'district',
    };

    return {
        getSchoolById(schoolId) {
            return getGroupById(GROUPS.SCHOOL, schoolId);
        },
        getDistrictById(districtId) {
            return getGroupById(GROUPS.DISTRICT, districtId);
        },
        getStudentAccountsBySchool(schoolId, params) {
            return getAccountsByRole('students', schoolId, params);
        },
        getTeacherAccountsBySchool(schoolId, params) {
            return getAccountsByRole('teachers', schoolId, params);
        },
        getAdminAccountsBySchool(schoolId, params) {
            return getAccountsByRole('admins', schoolId, params);
        },
        getDistrictAdminAccounts(districtId, params) {
            return getAccountsByRole('admins', districtId, params, GROUPS.DISTRICT);
        },
        removeStudentById(schoolId, studentId) {
            return $q((resolve, reject) => {
                return $http({
                    url: `${urls.base}/ra/admin/school/${schoolId}/student/${studentId}`,
                    method: 'DELETE',
                })
                    .then(res => resolve(res.data))
                    .catch(err => reject(err.data));
            });
        },
        removeTeacherById(schoolId, teacherId) {
            return $q((resolve, reject) => {
                return $http({
                    url: `${urls.base}/ra/admin/school/${schoolId}/teacher/${teacherId}`,
                    method: 'DELETE',
                })
                    .then(res => resolve(res.data))
                    .catch(err => reject(err.data));
            });
        },
        removeAdminById(groupId, adminId, group = 'school') {
            return $q((resolve, reject) => {
                return $http({
                    url: `${urls.base}/ra/admin/${group}/${groupId}/admin/${adminId}`,
                    method: 'DELETE',
                })
                    .then(res => resolve(res.data))
                    .catch(err => reject(err.data));
            });
        },
        getAccountById(identityId) {
            return $q((resolve, reject) => {
                const url = `${urls.base}/ra/identity/${identityId}`;
                $http({ url: url, method: 'GET' })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(res => {
                        reject(res.data);
                    });
            });
        },
        _createAccount(schoolId, data, role, group = 'school') {
            return $q((resolve, reject) => {
                $http({
                    url: `${urls.base}/ra/admin/${group}/${schoolId}/${role}`,
                    method: 'POST',
                    data,
                })
                    .then(res => resolve(res.data))
                    .catch(res => reject(res.data.errors));
            });
        },
        createStudentAccount(schoolId, data) {
            return this._createAccount(schoolId, data, 'students');
        },
        createTeacherAccount(schoolId, data) {
            return this._createAccount(schoolId, data, 'teachers');
        },
        createAdminAccount(groupId, data, group) {
            return this._createAccount(groupId, data, 'admins', group);
        },
        regenerateDistrictCode(districtId) {
            return $http({
                url: `${urls.base}/ra/admin/district/${districtId}/regenerate-district-code`,
                method: 'POST',
            }).then(res => res.data);
        },
    };
}
