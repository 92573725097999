import _ from 'lodash';

export function getDefaultModel() {
    return {
        fileData: null,
        workbook: null,
        filename: null,
        isDataFreeOfPersonalInfo: null,
    };
}

class UploadYourFileController {
    // @ngInject
    constructor(spreadsheetFactory) {
        this.spreadsheetFactory = spreadsheetFactory;
    }

    $onInit() {
        const initialModel = _.pick(this.initialModel, Object.keys(getDefaultModel()));
        this.model = _.assign(getDefaultModel(), initialModel);
        this.fileUploadValidationMessage = null;
    }

    handleFilesSelected(files) {
        if (files && files.length === 1) {
            return this.processFile(files[0]);
        }
    }

    loadWorkbookSuccess(fileData, workbook) {
        this.fileUploadValidationMessage = null;
        this.model.fileData = fileData;
        this.model.workbook = workbook;

        if (!this.model.filename) {
            this.model.filename = fileData.name;
        }
    }

    loadWorkbookError() {
        this.fileUploadValidationMessage = 'Please select an .xls or .xlsx file to upload.';
    }

    processFile(fileData) {
        return this.spreadsheetFactory
            .loadFileAsWorkbook(fileData)
            .then(this.loadWorkbookSuccess.bind(this, fileData))
            .catch(this.loadWorkbookError.bind(this));
    }

    back() {
        this.onBack({ model: this.model });
    }

    saveAndContinue() {
        if (this.form.$valid) {
            this.onSaveAndContinue({ model: this.model });
        }
    }
}

export default {
    bindings: {
        requireDataWaiver: '<',
        initialModel: '<',
        onBack: '&',
        onSaveAndContinue: '&',
    },
    controller: UploadYourFileController,
    templateUrl: 'admin/bulk-upload/bulk-upload-wizard/upload-your-file/upload-your-file.html',
};
