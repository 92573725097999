import angular from 'angular';
import uiRouter from 'angular-ui-router';
import eventMediator from 'gong-event-mediator';

import accounts from '../../components/accounts/accounts';
import adminShared from '../../../shared/shared';

import TeacherAccountsController from './teacher-accounts-controller';

const teacherAccounts = angular.module('teacherAccounts', [
    accounts,
    uiRouter,
    adminShared,
    eventMediator,
]);
teacherAccounts.controller('TeacherAccountsController', TeacherAccountsController);

export default teacherAccounts.name;
