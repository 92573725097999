import _ from 'lodash';

export const BASIC_ROLES = {
    LEARNER: 'learner', // AKA Student
    ADMINISTRATOR: 'administrator', // Could be District, Multi-School, Single-School, and/or LTI
    INSTRUCTOR: 'instructor', // AKA Teacher
    IMPLEMENTATION_SPECIALIST: 'implementation specialist', // AKA Customer Service Consultant (CSC)
};

export const COMPLEX_ROLES = {
    LEARNER: 'learner',
    INSTRUCTOR: 'instructor',
    IMPLEMENTATION_SPECIALIST: 'implementation specialist',
    LTI_ADMIN: 'lti_administrator',
    DISTRICT_ADMIN: 'district_administrator',
    SINGLE_SCHOOL_ADMIN: 'school_administrator',
    MULTI_SCHOOL_ADMIN: 'multi_school_administrator',
};

const HOME_STATES = Object.freeze({
    [COMPLEX_ROLES.INSTRUCTOR]: 'teacher.home',
    [COMPLEX_ROLES.LEARNER]: 'student.home',
    [COMPLEX_ROLES.SINGLE_SCHOOL_ADMIN]: 'admin.school.index',
    [COMPLEX_ROLES.DISTRICT_ADMIN]: 'admin.district.index',
    [COMPLEX_ROLES.MULTI_SCHOOL_ADMIN]: 'admin.multi_school',
    [COMPLEX_ROLES.IMPLEMENTATION_SPECIALIST]: 'specialist.districts.index',
    [COMPLEX_ROLES.LTI_ADMIN]: 'admin.usage-report',
});

const ROLE_STORAGE_KEY = 'role';

const GROUP_TYPES = {
    DISTRICT: 'district',
    INSTITUTION: 'institution',
};

const PRODUCT_TYPES = {
    STANDALONE: 'Revision Assistant',
    LTI: 'Revision Assistant - Integration',
};

export default function user(preferences, $sessionStorage, eventMediator, userPermissions) {
    'ngInject';
    let user = undefined;
    let currentRoleData = null;
    let membershipRoles = [];
    let permissions = {};

    return {
        hydrate(userObject) {
            if (typeof window.__insp === 'object') {
                __insp.push(['identify', userObject.email]);
            }

            user = userObject;
            this._applyRoles();
            eventMediator.emit('auth.role.initialized');
            preferences.hydrate(userObject.preferences);
            permissions = userPermissions.buildPermissions(this._getRoleKey(), user.preferences);

            const storedRole = $sessionStorage[ROLE_STORAGE_KEY];
            if (storedRole) this.setCurrentRole(storedRole);
        },

        refreshPermissions() {
            permissions = userPermissions.buildPermissions(this._getRoleKey(), user.preferences);
        },

        _getMembershipData() {
            let memberships = user ? user.memberships : {};
            return _.reduce(
                memberships,
                (granted, data, role) => {
                    if (!_.isEmpty(data)) granted[role.toLowerCase()] = data;
                    return granted;
                },
                {}
            );
        },

        _getDefaultMembershipData() {
            let hierarchy = [
                BASIC_ROLES.LEARNER,
                BASIC_ROLES.ADMINISTRATOR,
                BASIC_ROLES.INSTRUCTOR,
            ];

            let membershipData = this._getMembershipData();
            return _.reduce(
                membershipData,
                (prev, roleMemberships, r) => {
                    return hierarchy.indexOf(r) >= hierarchy.indexOf(prev.role)
                        ? { role: r, memberships: roleMemberships }
                        : prev;
                },
                {}
            );
        },

        _applyRoles(roleData) {
            currentRoleData = roleData || this._getDefaultMembershipData() || null;
            membershipRoles = Object.keys(this._getMembershipData());
        },

        isAuthenticated() {
            return !!(user && user.login_token);
        },

        getUser() {
            return user && _.cloneDeep(_.omit(user, 'login_token'));
        },

        getToken() {
            return user && user.login_token;
        },

        getIdentifier() {
            if (!user) return null;
            return user.email || user.username || null;
        },

        getCurrentRole() {
            return _.get(currentRoleData, 'role');
        },

        setCurrentRole(role) {
            if (!role) throw new Error(`Cannot set an empty role. [${role}]`);

            role = role.toLowerCase();
            if (role === currentRoleData.role) {
                return false;
            }

            let membershipData = this._getMembershipData();
            if (!membershipData[role]) {
                throw new Error(`Attempted to apply invalid role [${role}] for user.`);
            }

            const prevRole = currentRoleData.role;
            $sessionStorage[ROLE_STORAGE_KEY] = role;
            this._applyRoles({ role, memberships: membershipData[role] });
            permissions = userPermissions.buildPermissions(this._getRoleKey(), user.preferences);

            if (prevRole) {
                eventMediator.emit('auth.role.changed', { role, prevRole });
            } else {
                eventMediator.emit('auth.role.initialized', { role });
            }

            return true;
        },

        logout() {
            user = undefined;
            $sessionStorage[ROLE_STORAGE_KEY] = undefined;
            this._applyRoles();
            preferences.dehydrate();
        },

        hasInstructorRole() {
            return _.includes(membershipRoles, BASIC_ROLES.INSTRUCTOR);
        },

        hasLearnerRole() {
            return _.includes(membershipRoles, BASIC_ROLES.LEARNER);
        },

        hasAdministratorRole() {
            return _.includes(membershipRoles, BASIC_ROLES.ADMINISTRATOR);
        },

        hasDistrictAdministratorRole() {
            let membershipData = this._getMembershipData() || {};
            if (!membershipData || !membershipData.administrator) return false;

            return membershipData.administrator.some(
                m => m.group_type.toLowerCase() === GROUP_TYPES.DISTRICT
            );
        },

        hasImplementationSpecialistRole() {
            return _.includes(membershipRoles, BASIC_ROLES.IMPLEMENTATION_SPECIALIST);
        },

        hasGroupPermission(permission, groupId) {
            return _.includes(permissions.groupPermissions[groupId], permission);
        },

        hasGeneralPermission(permission) {
            return _.includes(permissions.generalPermissions, permission);
        },

        isInstructor() {
            return this.getCurrentRole() === BASIC_ROLES.INSTRUCTOR;
        },

        isImplementationSpecialist() {
            return this.getCurrentRole() === BASIC_ROLES.IMPLEMENTATION_SPECIALIST;
        },

        isLearner() {
            return this.getCurrentRole() === BASIC_ROLES.LEARNER;
        },

        isSchoolAdministrator() {
            if (!currentRoleData || !currentRoleData.memberships) return false;

            let containsSchoolAdmin =
                this.isAdministrator() &&
                currentRoleData.memberships.some(
                    m => m.group_type.toLowerCase() === GROUP_TYPES.INSTITUTION
                );

            // We only consider a user to be effectively be a school admin
            // if they are NOT also a district admin, since DA role takes precendence.
            let useSchoolAdmin = containsSchoolAdmin && !this.isDistrictAdministrator();

            return useSchoolAdmin;
        },

        isSingleSchoolAdministrator() {
            return this.isSchoolAdministrator() && !this.isMultiSchoolAdministrator();
        },

        isMultiSchoolAdministrator() {
            return (
                this.isSchoolAdministrator() &&
                getAdminData(this.getUser(), GROUP_TYPES.INSTITUTION).length > 1
            );
        },

        isDistrictAdministrator() {
            if (!currentRoleData || !currentRoleData.memberships) return false;

            return (
                this.isAdministrator() &&
                currentRoleData.memberships.some(
                    m => m.group_type.toLowerCase() === GROUP_TYPES.DISTRICT
                )
            );
        },

        isAdministrator() {
            return this.getCurrentRole() === BASIC_ROLES.ADMINISTRATOR;
        },

        daysLeftOnLicense() {
            const memberships = this._getCurrentMemberships();
            const groupIds = _(memberships)
                .map('id')
                .uniq()
                .value();
            const licenses = _.filter(_.get(user, 'licenses', []), license =>
                _.includes(groupIds, license.id)
            );
            const daysLeft = _(licenses)
                .flatMap('products')
                .map('days_left')
                .uniq()
                .value();
            return _.min(daysLeft) || 0;
        },

        hasExpiredLicense() {
            const memberships = this._getCurrentMemberships();
            const groupIds = _(memberships)
                .map('id')
                .uniq()
                .value();
            const licenses = _.filter(_.get(user, 'licenses', []), license =>
                _.includes(groupIds, license.id)
            );
            const totalLicenseActivity = _(licenses)
                .flatMap('products')
                .map('active')
                .uniq()
                .value();
            // TODO (kfr2) Can the user have both an active and expired license
            // at the same time? If so, what should we do?
            return _.includes(totalLicenseActivity, false);
        },

        _getCurrentMemberships() {
            // Some examples of supported situations where the user
            // could have multiple memberships with the same role:
            // - CSCs (Implementation Specialist), across multiple districts
            // - Multi-School Admin, at multiple schools within the same district
            // - Teacher, at multiple schools within the same district
            // - Student, at multiple schools within the same district
            if (this.isDistrictAdministrator()) {
                return this.getDistrictsWithAdministrativeAccess();
            } else if (this.isSchoolAdministrator()) {
                return this.getSchoolsWithAdministrativeAccess();
            }
            return _.get(currentRoleData, 'memberships') || [];
        },

        _getProductTypes(memberships) {
            const groupIds = _(memberships)
                .map('id')
                .uniq()
                .value();
            const licenses = _.filter(_.get(user, 'licenses', []), license =>
                _.includes(groupIds, license.id)
            );
            return _(licenses)
                .flatMap('products')
                .map('product_type')
                .uniq()
                .value();
        },

        isLtiAdmin() {
            if (!this.isAdministrator()) return false;
            const memberships = this._getCurrentMemberships();
            const productTypes = this._getProductTypes(memberships);
            return productTypes.length > 0 && _.every(productTypes, pt => pt === PRODUCT_TYPES.LTI);
        },

        isMultiRole() {
            return membershipRoles.length > 1;
        },

        _getRoleKey() {
            if (this.isLtiAdmin()) {
                return COMPLEX_ROLES.LTI_ADMIN;
            } else if (this.isDistrictAdministrator()) {
                return COMPLEX_ROLES.DISTRICT_ADMIN;
            } else if (this.isMultiSchoolAdministrator()) {
                return COMPLEX_ROLES.MULTI_SCHOOL_ADMIN;
            } else if (this.isSingleSchoolAdministrator()) {
                return COMPLEX_ROLES.SINGLE_SCHOOL_ADMIN;
            } else {
                return this.getCurrentRole();
            }
        },

        getHomeState() {
            if (!this.isAuthenticated()) {
                return { toState: 'login' };
            } else if (_.get(currentRoleData, 'memberships.length', 0) < 1) {
                return { toState: 'unauthorized' }; // User's memberships not set up yet
            }

            let toParams = null;
            if (this.isDistrictAdministrator()) {
                const district = _.find(currentRoleData.memberships, membership => {
                    return membership.group_type.toLowerCase() === GROUP_TYPES.DISTRICT;
                });
                toParams = { districtId: district.id };
            } else if (this.isSingleSchoolAdministrator()) {
                const institution = _.find(currentRoleData.memberships, membership => {
                    return membership.group_type.toLowerCase() === GROUP_TYPES.INSTITUTION;
                });
                toParams = { schoolId: institution.id };
            }

            return { toState: HOME_STATES[this._getRoleKey()], toParams };
        },

        getMultipleMemberships(role) {
            if (!user.memberships || user.memberships[role].length <= 1) {
                return null;
            }
            return _.sortBy(_.cloneDeep(user.memberships[role]), 'name');
        },

        /**
         * Returns a list of district IDs based on the current role.
         * Implementation specialists and District admins can return values.
         * School admins will always return an empty list.
         */
        getDistrictsWithAdministrativeAccess() {
            const districtAdminAccess =
                this.isDistrictAdministrator() || this.isImplementationSpecialist();
            if (!districtAdminAccess) return [];

            return _(currentRoleData.memberships)
                .filter(isMembershipGroupType(GROUP_TYPES.DISTRICT))
                .map(x => _.pick(x, ['id', 'name']))
                .value();
        },

        getSchoolsWithAdministrativeAccess() {
            const schoolAdminAccess = this.isAdministrator() || this.isImplementationSpecialist();
            if (!schoolAdminAccess) return [];

            return _(currentRoleData.memberships)
                .filter(isMembershipGroupType(GROUP_TYPES.INSTITUTION))
                .map(x => _.pick(x, ['id', 'name']))
                .value();
        },

        getGroupIds() {
            return _.map(this._getMembershipData()[this.getCurrentRole()], ({ id }) => id);
        },
    };
}

function isMembershipGroupType(groupType) {
    return membership => membership.group_type.toLowerCase() === groupType.toLowerCase();
}

function getAdminData(user, groupType) {
    return _.filter(user.memberships.Administrator, administrator => {
        return administrator.group_type.toLowerCase() === groupType.toLowerCase();
    });
}
