const RAISE_EVENT = 'raise-shield';
const LOWER_EVENT = 'lower-shield';
const START_LOAD_GFX_EVENT = 'start-loading-gfx';
const FINISH_LOAD_GFX_EVENT = 'finish-loading-gfx';

// @ngInject
export default function interactionShieldService($q, eventMediator, $rootScope) {
    let deferred;

    let service = this;

    eventMediator.subscribe($rootScope, LOWER_EVENT, () => {
        if (deferred) {
            deferred.resolve();
        }
    });

    service.shieldAction = (event, data) => {
        deferred = $q.defer();
        eventMediator.emit(event, data);
        return deferred.promise;
    };

    service.raiseShield = data => service.shieldAction(RAISE_EVENT, data);
    service.lowerShield = data => service.shieldAction(LOWER_EVENT, data);

    service.startLoading = data => service.shieldAction(START_LOAD_GFX_EVENT, data);
    service.finishLoading = () => service.shieldAction(FINISH_LOAD_GFX_EVENT);

    service.raiseTransitionShield = () => service.raiseShield({ transition: true });
    service.lowerTransitionShield = () => service.lowerShield({ transition: false });

    return service;
}
