import _ from 'lodash';

const roles = {
    teacher: 'Instructor',
    student: 'Learner',
};

class RoleSelectionComponent {
    /* @ngInject */
    constructor($scope, $element) {
        this.roles = roles;
        this.$element = $element;

        // clear the district code server side validation anytime the data changes
        $scope.$watch(
            '$ctrl.uiModel.districtCode',
            this.setDistrictCodeMatchValidation.bind(this, null)
        );
    }

    $onChanges(changes) {
        const isDistrictCodeValid = !_.get(
            changes,
            'validationErrors.currentValue.field.districtCode'
        );
        this.setDistrictCodeMatchValidation(isDistrictCodeValid);

        const model = _.get(changes, 'model.currentValue');
        this.uiModel = model ? _.cloneDeep(model) : this.uiModel;
    }

    setDistrictCodeMatchValidation(isValid) {
        if (this.form && this.form.districtCode) {
            this.form.districtCode.$setValidity('no-match', isValid);
        }
    }

    setSelectedRole(role) {
        // clear the EULA on role change - the user should need to click this fresh each time
        this.uiModel.agreedToEula = false;
        this.uiModel.role = role;
        this.form.$setPristine();
    }

    confirmRole() {
        if (!this.uiModel.role || !this.form.$valid) {
            return;
        }

        this.onSubmit({ model: _.cloneDeep(this.uiModel) });
    }
}

export default {
    controller: RoleSelectionComponent,
    templateUrl: 'login/shared/role-selection/role-selection-component.html',
    bindings: {
        model: '<',
        validationErrors: '<',
        onSubmit: '&',
    },
};
