import _ from 'lodash';

import {
    isCurrentTeacherTrialUser,
    isAuthorizedForTeacherTrialPayment,
} from '../../payment/payment-authorization';

export default class HomeController {
    // @ngInject
    constructor(
        $scope,
        $log,
        $q,
        paymentService,
        providerService,
        assignmentFactory,
        expirationPendingModalFactory,
        preferences,
        user,
        demoService,
        $state,
        interactionShieldService,
        eventMediator,
        school,
        homeData,
        userAccountService,
        assignmentSettingsModalFactory
    ) {
        this.preferences = preferences;
        this.userAccountService = userAccountService;
        this.$state = $state;
        this.demoService = demoService;
        this.paymentService = paymentService;
        this.expirationPendingModalFactory = expirationPendingModalFactory;
        this.assignmentFactory = assignmentFactory;
        this.schoolId = school.id;
        this.userEmail = user.getIdentifier();
        this.isSalesDemoEnabled = preferences.isSalesDemoEnabled(this.schoolId);
        this.isTeacherTrial = isCurrentTeacherTrialUser(user);
        this.teacherGroupIds = user.getGroupIds();
        this.creatableAssignmentTypes = preferences.getCreatableAssignmentTypes(
            this.teacherGroupIds
        );
        this.interactionShieldService = interactionShieldService;
        this.isArchiveNotificationVisible = homeData.isArchiveNotificationVisible;
        this.assignmentSettingsModalFactory = assignmentSettingsModalFactory;

        this.data = this.initHomeData(homeData);

        this.$log = $log;
        this.$q = $q;
        this.providerService = providerService;
        this.eventMediator = eventMediator;
        this.$scope = $scope;
        this.user = user.getUser();

        // Update password change status when updated, this will allow the
        // promo banner to display or hide correctly.
        this.eventMediator.subscribe($scope, 'user.change_password.changed', () => {
            this.user = user.getUser();
        });

        if (isAuthorizedForTeacherTrialPayment(user)) {
            this.$log.debug('Authorized for TT');
            this.paymentService.getExpirationPendingModalName().then(modalName => {
                if (!_.isNull(modalName)) {
                    this.$log.debug('Launching modal...', modalName);
                    expirationPendingModalFactory.prompt(modalName);
                } else {
                    this.$log.debug('No modal to launch.');
                }
            });
        }
    }

    initHomeData({ classes }) {
        const sortedClasses = _.sortBy(classes, 'created') || [];

        return {
            classes: sortedClasses.map(({ assignments, ...classData }) => {
                return {
                    ...classData,
                    assignments: this.assignmentFactory.sortAssignments(assignments),
                    state: this.assignmentFactory.DEFAULT_STATE,
                };
            }),
            hasClasses: classes.length > 0,
        };
    }

    isAssignmentVisible(assignment) {
        const stateOfClass = _.find(this.data.classes, ['id', assignment.group_id]).state;

        return this.assignmentFactory.isAssignmentVisible(stateOfClass, assignment);
    }

    _handleErrors(errors) {
        this.eventMediator.emit('messageNotification', {
            type: 'danger',
            message: 'Sorry we were unable to build a demo class, an error has occurred.',
        });
        return errors;
    }

    _handleSuccess() {
        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message: 'Demo class created!',
        });
    }

    launchAssignment(assignmentId) {
        this.$log.log('launching assignment...');
        this.providerService.launchAssignmentById(assignmentId, 'Instructor');
    }

    updateAssignment(assignment, updates) {
        const classIndex = _.findIndex(this.data.classes, ['id', assignment.group_id]);
        const assignmentIndex = _.findIndex(this.data.classes[classIndex].assignments, [
            'id',
            assignment.id,
        ]);
        this.data.classes[classIndex].assignments[assignmentIndex] = {
            ...assignment,
            ...updates,
        };
        this.data.classes[classIndex].assignments = this.assignmentFactory.sortAssignments(
            this.data.classes[classIndex].assignments
        );
    }

    handleEditAssignment(assignment) {
        const classOfAssignment = _.find(this.data.classes, ['id', assignment.group_id]);
        this.assignmentSettingsModalFactory
            .open(assignment, classOfAssignment)
            .then(assignmentChanges => {
                this.updateAssignment(assignment, assignmentChanges);
            });
    }

    createSalesDemo() {
        this.interactionShieldService.startLoading();

        const data = {
            email: this.userEmail, //TODO - update multiplicity to use auth token
            group_id: this.schoolId,
        };

        return this.demoService
            .createSalesDemo(data)
            .then(() => this.$state.reload())
            .catch(errors => this.$q.reject(this._handleErrors(errors)))
            .then(() => this._handleSuccess())
            .finally(() => this.interactionShieldService.finishLoading());
    }

    createAssignment(classId) {
        return this.providerService.launchCreateAssignment(classId);
    }
}
