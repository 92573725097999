// @ngInject
export default function studentService(urls, $http, $q) {
    return {
        addClass(classCode) {
            return $http({
                method: 'POST',
                url: `${urls.base}/ra/class/by_code/${classCode}`,
            })
                .then(res => res.data)
                .catch(res => $q.reject(res.data));
        },
        getClasses() {
            return $http({
                method: 'POST',
                url: `${urls.base}/ra/classes`,
                data: { membership_type: 'Learner' },
            }).then(res => res.data.classes);
        },
    };
}
