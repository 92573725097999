export default class EulaModalController {
    // @ngInject
    constructor(authService, ravenService, $http, $log, $q, $uibModalInstance, urls, user) {
        this.authService = authService;
        this.ravenService = ravenService;
        this.$http = $http;
        this.$log = $log;
        this.$q = $q;
        this.$uibModalInstance = $uibModalInstance;
        this.urls = urls;
        this.user = user;
        this.userData = user.getUser();
    }

    needsANickname() {
        return this.user.hasInstructorRole() || this.user.hasAdministratorRole();
    }

    closeEulaModal() {
        this.$uibModalInstance.close();
    }

    displayFormFailures() {
        try {
            this.form.showErrors = true;
        } catch (e) {
            // We are unsure of how an 'undefined' form is happening,
            // but Sentry is reporting such errors,
            // so we are logging the issue,
            // in hopes of gathering more actionable information.
            this.ravenService.captureFormException(e, this);
        }
    }

    updateProfile() {
        return this.$http({
            url: `${this.urls.base}/ra/identity/profile`,
            method: 'PUT',
            data: {
                agreed_to_eula: this.agreed_to_eula,
                nickname: this.nickname,
            },
        });
    }

    changeUserPassword() {
        if (!this.user.getUser().change_password) {
            return this.$q.resolve(true);
        }

        return this.authService
            .changeUserPassword(this.newPassword)
            .then(() => this.login())
            .catch(() => this.displayFormFailures());
    }

    login() {
        return this.authService.login(this.user.getIdentifier(), this.newPassword).catch(() => {
            this.authService.logOut();
            return this.displayFormFailures();
        });
    }

    validate() {
        return this.form.$valid;
    }

    save() {
        if (!this.validate()) {
            return this.displayFormFailures();
        }

        return this.updateProfile()
            .then(() => this.changeUserPassword())
            .then(() => this.closeEulaModal())
            .catch(() => this.displayFormFailures());
    }
}
