//remotes
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import urlConfig from '../url-config/url-config';
import EventMediator from 'gong-event-mediator';
//locals
import home from './home/home';
import archivedClasses from './archived-classes/archived-classes';
import teacherShared from './shared/shared';
import teacherClass from './teacher-class/teacher-class';
import teacherRoutes from './teacher-routes';
import changePassword from '../components/change-password/change-password';
import demo from '../demo/demo';

//module
const teacher = angular.module('teacher', [
    uiRouter,
    home,
    archivedClasses,
    urlConfig,
    teacherClass,
    teacherShared,
    EventMediator,
    changePassword,
    demo,
]);
//injections
teacher.config(teacherRoutes);
//export
export default teacher.name;
