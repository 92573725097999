class ChangePasswordController {
    // @ngInject
    constructor(eventMediator, userAccountService, notifyDataLossService, $scope) {
        this.eventMediator = eventMediator;
        this.userAccountService = userAccountService;
        this.notifyDataLossService = notifyDataLossService;
        this.$scope = $scope;
    }

    $onInit() {
        this.userLogin = {
            newPassword: '',
            newPasswordConfirm: '',
        };

        this.dataComparator = this.notifyDataLossService.anticipateDataLoss(
            this.$scope,
            this,
            'userLogin'
        );
    }

    _handleSuccess() {
        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message: `${this.targetUser.full_name}'s password was successfully changed.`,
            postStateChange: true,
        });
        this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);
        this.onSuccess();
    }

    _handleError() {
        this.eventMediator.emit('messageNotification', {
            type: 'danger',
            message: 'There was an error updating this student. Please try again.',
        });
        this.form.showErrors = true;
    }

    cancel() {
        this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);
        this.onCancel();
    }

    save() {
        if (this.form.$valid) {
            return this.userAccountService
                .updatePasswordForAccount(this.targetUser, this.userLogin.newPassword)
                .then(this._handleSuccess.bind(this))
                .catch(this._handleError.bind(this));
        } else {
            this.eventMediator.emit('messageNotification', {
                type: 'danger',
                message: 'Please complete all required fields.',
            });
            this.form.showErrors = true;
        }
    }
}

const changePasswordComponent = {
    templateUrl: 'components/change-password/change-password.html',
    controller: ChangePasswordController,
    bindings: {
        targetUser: '<',
        onSuccess: '&',
        onCancel: '&',
        changePasswordCallback: '&',
    },
};

export default changePasswordComponent;
