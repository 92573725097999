import angular from 'angular';
import uiRouter from 'angular-ui-router';
import adminShared from '../../../shared/shared';

import adminViewAccountComponent from './admin-view-account-component';

const adminViewAccount = angular.module('adminViewAccount', [uiRouter, adminShared]);
adminViewAccount.component('adminViewAccount', adminViewAccountComponent);

export default adminViewAccount.name;
