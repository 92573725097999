import _ from 'lodash';

export default function(eventMediator, $uibModal, $state, $q) {
    'ngInject';

    const DATA_LOSS_EVENT = '$stateChangeStart';
    const DEFAULT_MESSAGE =
        'You have unsaved changes. If you leave this page, you will lose these changes.';

    function presentNotificationModal(message) {
        return $uibModal.open({
            controllerAs: '$ctrl',
            controller(message) {
                this.message = message;
            },
            resolve: {
                message: _.constant(message || DEFAULT_MESSAGE),
            },
            template: `
                    <div>
                        <button type="button" class="close" ng-click="$dismiss()"></button>
                        <div class="modal-header">
                            <h1>Before You Go...</h1>
                        </div>
                        <div class="modal-body">
                            <p>
                                {{ $ctrl.message }}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <div class="buttons-centered-container">
                                <div class="buttons-centered">
                                    <button class="btn btn-default" ng-click="$close()">Discard Changes</button>
                                    <button class="btn btn-primary" ng-click="$dismiss()">Go Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                `,
        }).result;
    }

    class DataComparator {
        constructor(scope, dataProvider, dataPath, message) {
            this.scope = scope;
            this.dataProvider = dataProvider;
            this.dataPath = dataPath;
            this.message = message;

            this.setPristine();
        }

        getData() {
            return _.cloneDeep(_.get(this.dataProvider, this.dataPath));
        }

        setPristine() {
            this.data = this.getData();
        }

        isDirty() {
            return !_.isEqual(this.data, this.getData());
        }
    }

    const dataComparators = {};

    return {
        anticipateDataLoss(scope, dataProvider, dataPath, message) {
            const dataComparator = new DataComparator(scope, dataProvider, dataPath, message);
            dataComparators[dataPath] = dataComparator;

            eventMediator.subscribe(scope, DATA_LOSS_EVENT, (event, toState, toParams) => {
                if (!dataComparator.isDirty()) return;

                event.preventDefault();

                presentNotificationModal(message).then(() => {
                    this.cancelDataLossAnticipation(dataComparator);
                    $state.go(toState, toParams);
                });
            });

            return dataComparator;
        },
        cancelDataLossAnticipation(dataComparator) {
            dataComparators[dataComparator.dataPath] = undefined;
            eventMediator.unsubscribe(dataComparator.scope, DATA_LOSS_EVENT);
        },
        navigate() {
            const dirty = _(dataComparators)
                .values()
                .compact()
                .find(dataComparator => dataComparator.isDirty());

            if (dirty)
                return presentNotificationModal(dirty.message).then(() => {
                    this.cancelDataLossAnticipation(dirty);
                    return dirty;
                });

            return $q.resolve(true);
        },
    };
}
