import angular from 'angular';

import shared from '../shared/shared';

import AdminBulkUploadStudentController from './admin-bulk-upload-student-controller';

const adminBulkUploadStudent = angular.module('adminBulkUploadStudent', [shared]);

adminBulkUploadStudent.controller(
    'AdminBulkUploadStudentController',
    AdminBulkUploadStudentController
);

export default adminBulkUploadStudent.name;
