// This RegExp identifies each regular expression control
// character in a string.
const regexControlChars = new RegExp('[\\.\\[\\]\\^\\$\\|\\?\\*\\+\\(\\)]', 'g');

class HighlightTermController {
    // @ngInject
    constructor() {
        this.reg = null; // data store for calculated regex
        this.result = ''; // data store for result ng-bind-html value
    }
    $onInit() {
        // highlightIf should be a rule to determine
        // when highlighting occurs, defaults to always
        // be false
        if (!(typeof this.highlightIf === 'function')) this.highlightIf = _.constant(false);
        this.doHighlight();
    }
    $onChanges() {
        this.doHighlight();
    }
    doHighlight() {
        // calculates result to be injected into template
        if (!this.highlightIf(this.searchTerm)) return (this.result = this.term);
        else {
            this.reg = new RegExp(this.regexEscape(this.searchTerm), 'gi');
            if (!this.reg) return;
            else this.result = this.term.replace(this.reg, this.highlightTemplate);
        }
    }
    highlightTemplate(match) {
        // wrap the given string in an html string literal
        return '<span class="term">' + match + '</span>';
    }
    stringEscape(match) {
        // prepend a string with \
        return '\\' + match;
    }
    regexEscape(str) {
        // escape all RegExp control characters in the input string
        return str.replace(regexControlChars, this.stringEscape);
    }
}
export default {
    bindings: {
        highlightIf: '<',
        searchTerm: '<',
        term: '<',
    },
    controller: HighlightTermController,
    template: '<span ng-bind-html="$ctrl.result"></span>',
};
