let urls = {
    base: 'https://dev.getlightbox.com',
    help: __HELP_URL__,
    teacherTrialHelp:
        'https://help.turnitin.com/revision-assistant/teacher/account-basics/using-trial-accounts.htm',
    updates: __UPDATES_URL__,
    teacherTrial: 'https://www.revisionassistant.com/try',
    support: 'https://help.revisionassistant.com',
    promptSequences:
        'https://help.turnitin.com/revision-assistant/prompts-resources/unit-planning-resources/prompt-sequences.htm',
};

// Globals being defined on webpack build.
if (__PRODUCTION__) {
    urls.base = 'https://api.tiiscoringengine.com';
}

if (__STAGING__) {
    urls.base = 'https://staging.tiiscoringengine.com';
}

if (__COLOR__) {
    urls.base = `https://${__COLOR__}.getlightbox.com`;
}

if (__API_URL__) {
    urls.base = __API_URL__;
}

// Make sure this cannot be overwritten or changed at all
// by using Object.freeze
export default Object.freeze(urls);
