import _ from 'lodash';
import XLSX from 'xlsx';

import FileSaver from 'file-saver';

function getWorkbookAsBuffer(stream) {
    const buffer = new ArrayBuffer(stream.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i != stream.length; ++i) view[i] = stream.charCodeAt(i) & 0xff;

    return buffer;
}

const WORKBOOK_WRITE_OPTIONS = { bookType: 'xlsx', bookSST: false, type: 'binary' };
function saveWorkbookAs(workbook, filename) {
    const workbookOut = XLSX.write(workbook, WORKBOOK_WRITE_OPTIONS);
    const workbookBlob = new Blob([getWorkbookAsBuffer(workbookOut)], {
        type: 'application/octet-stream',
    });
    FileSaver.saveAs(workbookBlob, filename);
}

// @ngInject
export default function spreadsheet($q) {
    return {
        _parseRange(sheetRef) {
            let range = typeof sheetRef == 'string' ? XLSX.utils.decode_range(sheetRef) : sheetRef;

            return {
                startCol: range.s.c,
                endCol: range.e.c,
                startRow: range.s.r,
                endRow: range.e.r,
            };
        },

        _getRowContents(sheet, rowIndex, startCol, endCol) {
            const rowName = XLSX.utils.encode_row(rowIndex);
            const numColumns = endCol - startCol + 1;
            let rowContents = new Array(numColumns);
            for (let i = startCol; i <= endCol; ++i) {
                const columnName = XLSX.utils.encode_col(i);
                const cellName = `${columnName}${rowName}`;
                rowContents[i] = _.get(sheet, [cellName, 'w']);
            }
            return rowContents;
        },

        _getColumnHeaders(sheet) {
            if (!_.get(sheet, '!ref')) {
                return [];
            }

            const range = this._parseRange(sheet['!ref']);
            const headers =
                this._getRowContents(sheet, range.startRow, range.startCol, range.endCol) || [];

            return _.reject(headers, _.isNil);
        },

        getColumnHeaders(workbook, sheetNumbers) {
            return sheetNumbers.map(sheetIndex => {
                const sheet = this._getSheet(workbook, sheetIndex);
                return this._getColumnHeaders(sheet);
            });
        },

        _getRowData(sheet) {
            return XLSX.utils.sheet_to_json(sheet) || [];
        },

        _getSheet(workbook, zeroBasedIndex) {
            const sheetName = workbook.SheetNames[zeroBasedIndex];
            return workbook.Sheets[sheetName];
        },

        workbookToJson(workbook, sheetNumbers) {
            return _.map(sheetNumbers, i => {
                const sheet = this._getSheet(workbook, i);
                let columns = this._getColumnHeaders(sheet);
                let rowData = this._getRowData(sheet);
                return { columns, rowData };
            });
        },

        getWorkbook(fileData) {
            let massagedData = this._fixdata(fileData);
            return XLSX.read(massagedData, { type: 'binary' });
        },

        _getXHR() {
            return new XMLHttpRequest();
        },

        _createObjectURL(blob) {
            return URL.createObjectURL(blob);
        },

        _fixdata(binaryData) {
            return _(new Uint8Array(binaryData))
                .chunk(10240)
                .map(ch => new Uint8Array(ch))
                .map(ia => {
                    return _.map(ia, i => String.fromCharCode(i));
                })
                .map(ca => ca.join(''))
                .join('');
        },

        loadFile(blob) {
            return $q((resolve, reject) => {
                let blobURL = this._createObjectURL(blob);
                let xhr = this._getXHR();
                xhr.open('GET', blobURL);
                xhr.responseType = 'arraybuffer';
                xhr.onload = () => resolve(xhr.response);
                xhr.onerror = evt => reject(evt);
                xhr.onabort = evt => reject(evt);
                xhr.send();
            });
        },

        loadFileAsWorkbook(fileInfo) {
            return this.loadFile(fileInfo).then(binaryData => this.getWorkbook(binaryData));
        },

        saveWorkbookAs,
    };
}
