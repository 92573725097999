import angular from 'angular';

import userAccountService from './user-account-service';

import urlConfig from '../url-config/url-config';

const userAccount = angular.module('userAccount', [urlConfig]);
userAccount.factory('userAccountService', userAccountService);

export default userAccount.name;
