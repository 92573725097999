import _ from 'lodash';
import fp from 'lodash/fp';
import * as L from 'partial.lenses';

import { getFormattedPromotion } from '../components/promotions/promotions-factory';

import { ASSIGNMENT_TYPES } from '../assignment/assignment-constants';

const knownFeatures = {
    onboarding_teacher: 'onboarding.teacher',
    onboarding_administrator: 'onboarding.administrator',
};

const normalizedFeatures = _(knownFeatures)
    .mapKeys(key => `feature_${key}`)
    .mapValues(featureName => `feature.${featureName}`)
    .value();

export const knownPreferences = _.assign(
    {
        onboarding_teacher_complete: 'onboarding.teacher.complete',
        onboarding_administrator_complete: 'onboarding.administrator.complete',
        teacher_trial_promotion: 'feature.license-sends-trial-email',
        teacher_trial_fte_limit_tooltip_dismissed:
            'feature.teacher-trial.fte-limit-reached-tt-dismissed',
    },
    normalizedFeatures
);

/* @ngInject */
export default function preferencesFactory() {
    let preferences = {};

    const getAllGroupIds = () => {
        // Note: Group IDs are stored and returned as strings here!
        return Object.keys(preferences);
    };

    function getPreference(groupId, ...keys) {
        if (!groupId) {
            throw 'Please provide a group ID.';
        }
        if (!keys || !keys.length || !keys[0]) {
            throw 'Please provide a preference name.';
        }

        return _.get(preferences, _.concat([`${groupId}`], keys));
    }

    function isFeatureEnabled(featureName, defaultValue = false) {
        return groupId => {
            let pref = getPreference(groupId, `feature.${featureName}`);
            return _.defaultTo(pref, defaultValue);
        };
    }

    function doesPreferenceMatchForAnyGroup(preferenceName, matchFn) {
        const isTrue = v => v === true;
        return _(getAllGroupIds())
            .map(groupId => getPreference(groupId, preferenceName))
            .some(matchFn || isTrue);
    }

    /**
     * Looks across all group preferences to determine if the feature is on for any group.
     * This is useful for a feature like reporting that is either on or off
     * as a whole and is not conceptually tied to a group.
     */
    function isFeatureEnabledForAnyGroup(featureName) {
        return doesPreferenceMatchForAnyGroup(`feature.${featureName}`);
    }

    function getActiveDevFlags() {
        const keys = L.collect(
            [
                L.values,
                L.entries,
                L.when(([k, v]) => k.startsWith('dev.') && !!v),
                L.first,
                k => k.replace(/^dev\./, ''),
            ],
            preferences
        );

        return _.uniq(keys);
    }

    const isSignalCheckEnabled = isFeatureEnabled('signal-check', true);
    const isSpotlightEnabled = isFeatureEnabled('spotlight');
    const isExpansionPackEnabled = isFeatureEnabled('bootcamp');
    const isBulkUploadEnabled = isFeatureEnabled('bulk-upload', true); // expected to be temporary
    const isSalesDemoEnabled = isFeatureEnabled('sales-demo');
    const isBulkUploadV2Enabled = isFeatureEnabled('2bulk2upload');
    const is2018SATPilotEnabled = isFeatureEnabled('201801-sat-pilot');
    const isAdminUsageDistrictOnly = isFeatureEnabled('admin-usage-district-only');
    const isAdminManagementAvailable = isFeatureEnabled('admin-display-administrator', true);
    const isInstructorManagementAvailable = isFeatureEnabled('admin-display-instructor', true);

    function getAssignmentTypeFlags(groupId) {
        return {
            isSignalCheckEnabled: isSignalCheckEnabled(groupId),
            isSpotlightEnabled: isSpotlightEnabled(groupId),
            isExpansionPackEnabled: isExpansionPackEnabled(groupId),
        };
    }

    const preferenceAssignmentTypes = Object.freeze({
        [ASSIGNMENT_TYPES.SIGNAL_CHECK]: groupId => isSignalCheckEnabled(groupId),
        [ASSIGNMENT_TYPES.SPOT_CHECK]: groupId => isSpotlightEnabled(groupId),
        [ASSIGNMENT_TYPES.EXPANSION_PACK]: groupId => isExpansionPackEnabled(groupId),
    });

    function getCreatableAssignmentTypesByGroup(groupId) {
        return Object.keys(preferenceAssignmentTypes).filter(assigType =>
            preferenceAssignmentTypes[assigType](groupId)
        );
    }

    const getCreatableAssignmentTypes = groupIds => {
        return fp.flow(fp.flatMap(groupId => getCreatableAssignmentTypesByGroup(groupId)), fp.uniq)(
            groupIds
        );
    };

    const getEnabledFeaturesForGroup = (prefix, groupId, defaultValue) => {
        return fp.flow(
            fp.compact,
            fp.filter(fp.startsWith(`feature.${prefix}`)),
            fp.filter(prefName =>
                isFeatureEnabled(prefName.replace('feature.', ''), defaultValue)(groupId)
            ),
            fp.map(prefName => prefName.replace(`feature.${prefix}`, '')),
            fp.uniq
        )(Object.keys(preferences[groupId] || {}));
    };

    const getEnabledFeatures = (prefix, groupIds) => {
        return fp.flow(
            fp.flatMap(groupId => getEnabledFeaturesForGroup(prefix, groupId, false)),
            fp.uniq
        )(groupIds);
    };

    function getBulkUploadConfigFromPreferencesForAccountType(
        preferences = {},
        groupId,
        accountType
    ) {
        return _.flow([
            () => preferences[groupId] || {},
            groupPreferences =>
                _.pick(groupPreferences, [
                    'instructor-multiple-schools',
                    'instructor-password-change',
                    'instructor-password-default',
                    'instructor-password-generate',
                    'instructor-password-length-minimum',

                    'student-identifier-type',
                    'student-password-change',
                    'student-password-default',
                    'student-password-generate',
                    'student-sis-identifier-column',
                    'student-username-suffix',
                    'student-password-length-minimum',
                ]),
            config =>
                _.reduce(
                    config,
                    (filteredConfig, value, key) => {
                        return _.startsWith(key, accountType)
                            ? _.assign({}, filteredConfig, { [_.camelCase(key)]: value })
                            : filteredConfig;
                    },
                    {}
                ),
        ])();
    }

    const setPreference = (groupId, preferenceName, val) => {
        preferences[groupId][preferenceName] = val;
    };

    const setPreferenceForAllGroups = (preferenceName, val) => {
        const groupIds = getAllGroupIds();
        groupIds.forEach(groupId => setPreference(groupId, preferenceName, val));
    };

    return {
        hydrate(preferencesObj) {
            preferences = preferencesObj;
        },
        dehydrate() {
            preferences = {};
        },
        getPreference,
        getEnabledFeatures,
        getCreatableAssignmentTypes,
        isSignalCheckEnabled,
        isSpotlightEnabled,
        isExpansionPackEnabled,
        isBulkUploadEnabled,
        isAdminReportsEnabled: () => isFeatureEnabledForAnyGroup('consumer-admin-reports'),
        isTeacherOnboardingEnabled: () =>
            isFeatureEnabledForAnyGroup(knownFeatures.onboarding_teacher),
        isAdministratorOnboardingEnabled: () =>
            isFeatureEnabledForAnyGroup(knownFeatures.onboarding_administrator),
        isSalesDemoEnabled,
        getAssignmentTypeFlags,
        isBulkUploadV2Enabled,
        isAdminUsageDistrictOnly,
        getBulkUploadStudentConfig: groupId =>
            getBulkUploadConfigFromPreferencesForAccountType(preferences, groupId, 'student'),
        getBulkUploadInstructorConfig: groupId =>
            getBulkUploadConfigFromPreferencesForAccountType(preferences, groupId, 'instructor'),
        is2018SATPilotEnabled,
        isAdminManagementAvailable,
        isInstructorManagementAvailable,
        isTeacherOnboardingComplete: () =>
            doesPreferenceMatchForAnyGroup(knownPreferences.onboarding_teacher_complete),
        isAdministratorOnboardingComplete: () =>
            doesPreferenceMatchForAnyGroup(knownPreferences.onboarding_administrator_complete),
        isTeacherTrialPromotionUpgradeComplete: () =>
            doesPreferenceMatchForAnyGroup(
                knownPreferences.teacher_trial_promotion,
                v => v === false
            ),
        shouldDisplayFTELimitTooltip: () =>
            !doesPreferenceMatchForAnyGroup(
                knownPreferences.teacher_trial_fte_limit_tooltip_dismissed
            ),
        setTeacherOnboardingComplete: () =>
            setPreferenceForAllGroups(knownPreferences.onboarding_teacher_complete, true),
        setAdministratorOnboardingComplete: () =>
            setPreferenceForAllGroups(knownPreferences.onboarding_administrator_complete, true),
        setTeacherTrialPromotionUpgradeComplete: () =>
            setPreferenceForAllGroups(knownPreferences.teacher_trial_promotion, false),
        setDisplayFTELimitTooltipDismissed: shouldDismiss =>
            setPreferenceForAllGroups(
                knownPreferences.teacher_trial_fte_limit_tooltip_dismissed,
                shouldDismiss
            ),
        getActiveDevFlags,
        setPromotionComplete: promotion =>
            setPreferenceForAllGroups(getFormattedPromotion(promotion), true),
    };
}
