import angular from 'angular';

import bulkUploadDisplayErrors from './bulk-upload-display-errors-component';
import textUtils from '../../../text-utils/text-utils';

const adminBulkUploadDisplayErrors = angular.module('adminBulkUploadDisplayErrors', [textUtils]);

adminBulkUploadDisplayErrors.component('bulkUploadDisplayErrors', bulkUploadDisplayErrors);

export default adminBulkUploadDisplayErrors.name;
