import _ from 'lodash';

import { getDefaultModel as getEmptyStep4Model } from '../bulk-upload-wizard/upload-your-file/upload-your-file-component';

const accountTypeColumnDisplay = (accountType, column) => _.startCase(`${accountType} ${column}`);
const defaultColumnDisplay = (accountType, column) => _.startCase(column);

const headerDisplayMap = {
    first_name: accountTypeColumnDisplay,
    last_name: accountTypeColumnDisplay,
    email: _.constant('Email Address'),
    student_id: _.constant('Student ID'),
};

function getDisplayForColumn(accountType) {
    return column => _.get(headerDisplayMap, column, defaultColumnDisplay)(accountType, column);
}

class AdminBulkUploadController {
    // @ngInject
    constructor(spreadsheetFactory, eventMediator, $state, abuComponentService) {
        this.spreadsheetFactory = spreadsheetFactory;
        this.eventMediator = eventMediator;
        this.$state = $state;
        this.abuComponentService = abuComponentService;
    }

    $onInit() {
        this.downloadData = null;
        this.hasMissingHeaders = false;
        this.missingHeaders = [];
        this.unexpectedHeaders = [];
        this.duplicatedHeaders = [];
        this.processing = false;
        this.progressBarModel = { type: 'indeterminate' };
        this.initialModel = this.abuComponentService.getWizardModelFromConfig(
            this.previousConfig,
            this.accountType
        );
    }

    $onDestroy() {
        if (this.cancelOrderPolling) this.cancelOrderPolling();
    }

    clearAllMessageNotifications() {
        this.eventMediator.emit('messageNotification:removeAll');
    }

    renderHeaderErrors({ missingHeaders, unexpectedHeaders, duplicatedHeaders }) {
        this.hasMissingHeaders = true;
        this.missingHeaders = _.map(missingHeaders, getDisplayForColumn(this.accountType));
        this.unexpectedHeaders = _.map(unexpectedHeaders, 'columnName');
        this.duplicatedHeaders = _.map(duplicatedHeaders, x =>
            _.assign({}, x, { columnKey: _.startCase(x.columnKey) })
        );
    }

    renderGenericUploadError(msg) {
        const message = msg || 'An error occurred. Please contact a customer support specialist.';
        this.eventMediator.emit('messageNotification', { type: 'danger', message });
    }

    handlePollingProgress({ processed, total }) {
        if (total > 0) {
            const percentComplete = Math.ceil(processed / total * 100);
            this.progressBarModel = { type: 'percent', percentComplete };
        }
    }

    handleUploadSuccess(res) {
        const { promise, cancel } = this.abuComponentService.pollOrder(
            res,
            this.handlePollingProgress.bind(this)
        );
        this.cancelOrderPolling = cancel;

        // returning the promise ensures any polling errors find their way to our handler
        return promise.then(res => {
            const params = _.assign({}, this.$state.params, { orderId: res.id });
            const routeName =
                this.accountType === 'teacher'
                    ? 'admin.school.teachers.bulk-upload-order.outcome'
                    : 'admin.school.students.bulk-upload-order.outcome';
            this.$state.go(routeName, params, { reload: true });
        });
    }

    handleUploadError(err) {
        this.processing = false;

        if (err.type === 'cancel') {
            return;
        }

        if (err.type === 'header-validation') {
            return this.renderHeaderErrors(err.data);
        }

        return this.renderGenericUploadError(err.message);
    }

    handleWizardOnComplete(model) {
        this.clearAllMessageNotifications();

        // update the initial model so that if we go back to the wizard the user choices persist
        // we intentionally clear out step 4 so the user is forced to re-select a file
        const step4ModelProperties = _.keys(getEmptyStep4Model());
        this.initialModel = _.omit(model, step4ModelProperties);

        // needed data if there is an error and the user clicks "download"
        this.downloadData = _.pick(model, ['filename', 'workbook']);

        this.processing = true;
        this.abuComponentService
            .createAccountsFromWorkbook(this.accountType, this.school.id, model)
            .then(this.handleUploadSuccess.bind(this))
            .catch(this.handleUploadError.bind(this));
    }

    handleDownloadFile() {
        const { filename, workbook } = this.downloadData;
        this.spreadsheetFactory.saveWorkbookAs(workbook, filename);
    }

    handleUploadCorrectedFile() {
        this.processing = false;
        this.hasMissingHeaders = false;
        this.missingHeaders = [];
        this.unexpectedHeaders = [];
        this.duplicatedHeaders = [];
    }
}

export default {
    bindings: {
        school: '<',
        accountType: '@',
        previousConfig: '<',
    },
    controller: AdminBulkUploadController,
    template: `
            <bulk-upload-wizard ng-if="!$ctrl.processing && !$ctrl.hasMissingHeaders" on-complete="$ctrl.handleWizardOnComplete(model)" school="$ctrl.school" account-type="$ctrl.accountType" initial-model="$ctrl.initialModel"></bulk-upload-wizard>
            <abu-progress-bar ng-if="$ctrl.processing"
                model="$ctrl.progressBarModel"></abu-progress-bar>
            <bulk-upload-display-errors ng-if="$ctrl.hasMissingHeaders"
                missing-headers="$ctrl.missingHeaders"
                unexpected-headers="$ctrl.unexpectedHeaders"
                duplicated-headers="$ctrl.duplicatedHeaders"
                on-upload-corrected-file="$ctrl.handleUploadCorrectedFile()"
                on-download-file="$ctrl.handleDownloadFile()"></bulk-upload-display-errors>
        `,
};
