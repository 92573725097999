class MessageNotificationsController {
    // @ngInject
    constructor(eventMediator, $scope, $timeout, $sce) {
        this.$timeout = $timeout;
        this._id = 0;
        this.notifications = [];
        this.postNotifications = [];
        this.$sce = $sce;

        eventMediator.subscribe($scope, 'messageNotification', (event, notification) => {
            this.addNotification(notification);
        });
        eventMediator.subscribe($scope, 'messageNotification:removeAll', () => {
            this.removeAllNotifications();
        });
        eventMediator.subscribe($scope, '$stateChangeStart', () => {
            this.removeAllNotifications();
        });
        eventMediator.subscribe($scope, '$stateChangeSuccess', () => {
            this.addPostNotifications();
        });
    }

    /*
     * notification object:
     * type: <string> 'success' or 'danger',
     * message: <string> message to be displayed
     * timeout: [optional] <number> miliseconds to display message before autoclosing
     * postStateChange: [optional] boolean show after the stateChange
     */
    addNotification(baseNotification) {
        const message = baseNotification.message;
        if (typeof message === 'string') {
            baseNotification.baseMessage = message;
            baseNotification.message = this.$sce.trustAsHtml(message);
        }

        if (baseNotification.postStateChange === true) {
            this.postNotifications.push(_.omit(baseNotification, 'postStateChange'));
            return;
        }
        this._id++;
        const notification = _.assign({ id: this._id }, baseNotification);

        // Only the most recent notification with a particular message
        // should be displayed. Previous notifications are removed so
        // the one that is being added appears at the bottom of the
        // notifications list.
        this.removeAllNotificationsWithMessage(message);

        this.notifications.push(notification);
        if (notification.timeout) {
            this.$timeout(() => {
                this.removeNotification(notification.id);
            }, notification.timeout);
        }
    }

    addPostNotifications() {
        this.postNotifications.forEach(this.addNotification, this);
        this.postNotifications = [];
    }

    removeNotification(id) {
        this.notifications = this.notifications.filter(notification => {
            return id !== notification.id;
        });
    }

    removeAllNotifications() {
        this.notifications = [];
    }

    removeAllNotificationsWithMessage(message) {
        this.notifications = this.notifications.filter(notification => {
            return message !== notification.baseMessage;
        });
    }
}

function MessageNotificationsDirective() {
    return {
        scope: {},
        bindToController: {},
        templateUrl: 'message-notifications/message-notifications-directive.html',
        controller: MessageNotificationsController,
        controllerAs: 'messageNotificationsController',
    };
}

export default MessageNotificationsDirective;
