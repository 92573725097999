export default function authTokenInterceptor(user) {
    'ngInject';

    return {
        request(config) {
            if (user.getToken()) {
                config.headers['Authorization'] = 'Token ' + user.getToken();
            }

            return config;
        },
    };
}
