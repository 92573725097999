function bindCompiledHtmlDirective($compile) {
    'ngInject';
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            scope.$watch(
                function() {
                    return scope.$eval(attrs.bindCompiledHtml);
                },
                function(value) {
                    element.html(value);
                    $compile(element.contents())(scope);
                }
            );
        },
    };
}

export default bindCompiledHtmlDirective;
