import _ from 'lodash';

// @ngInject
export default function loginRoutes($stateProvider) {
    /**
     * Reset password and set password are two different routes but handled by the same
     * controller and template (just some of the text is different).
     * This helper lets us build out the route config for each in a shared way.
     */
    function createPasswordResetRoute(url, model) {
        return {
            url,
            templateUrl: 'login/password-reset/change-password/change-password.html',
            controller: 'ChangePasswordController as $ctrl',
            data: {
                isPublic: true,
            },
            resolve: {
                resetToken: /* @ngInject */ ($q, $location) => {
                    const resetToken = $location.search().reset_token;
                    return resetToken ? resetToken : $q.reject();
                },
                model: /* @ngInject */ resetToken => {
                    return _.assign({ resetToken }, model);
                },
            },
        };
    }

    $stateProvider
        .state('loginSac', {
            url: '/login-sac/:sac?role&data',
            controller: 'LoginSacController as $ctrl',
            resolve: {
                sacData: /* @ngInject */ $stateParams => {
                    return {
                        token: $stateParams.sac,
                        role: $stateParams.role,
                        redirectData: $stateParams.data,
                    };
                },
            },
            data: {
                isPublic: true,
            },
        })
        .state('login', {
            url: '/login',
            templateUrl: 'login/login.html',
            controller: 'LoginController as $ctrl',
            data: {
                isPublic: true,
                appRootStyleOverrides: 'login',
            },
        })
        .state('google-sign-in', {
            url: '/login-google',
            templateUrl: 'login/login.html',
            controller: 'LoginController as $ctrl',
            data: {
                isPublic: true,
                appRootStyleOverrides: 'login',
            },
        })
        .state('unauthorized', {
            url: '/unauthorized',
            templateUrl: 'login/unauthorized.html',
            controller: 'UnauthorizedController as unauthorizedController',
            data: {
                isPublic: true,
            },
        })
        .state('passwordReset', {
            url: '/login/password-reset',
            template: '<ui-view></ui-view>',
            data: {
                isPublic: true,
                appRootStyleOverrides: 'dark',
            },
        })
        .state('passwordReset.sendEmail', {
            url: '/send-email',
            templateUrl: 'login/password-reset/send-email/send-email.html',
            controller: 'SendEmailController as $ctrl',
            data: {
                isPublic: true,
            },
        })
        .state(
            'passwordReset.changePassword',
            createPasswordResetRoute('/change-password', {
                title: 'Reset Your Password',
                instructions: 'Complete the form below to change your password.',
                passwordLabel: 'New Password',
                buttonText: 'Reset Password',
                showMessageOnSuccess: true,
            })
        )
        .state(
            'passwordReset.setPassword',
            createPasswordResetRoute('/set-password', {
                title: 'Set Up Your Account',
                instructions: 'Almost there! Create a password and log in.',
                passwordLabel: 'Password',
                buttonText: 'Save and Continue',
                showMessageOnSuccess: false,
            })
        );
}
