export default function accountDeletionModal($uibModal) {
    'ngInject';

    return {
        presentModal(account, accountType) {
            return $uibModal.open({
                template: `
                    <div class="modal-header">
                        <h1>Are you sure?</h1>
                    </div>
                    <div class="modal-body">
                        <p>
                            If you click continue, {{($ctrl.firstName + ' ' + $ctrl.lastName).trim()}} will no longer be able to access Revision Assistant as a ${accountType}.
                        </p>
                    </div>
                    <div class="modal-footer">
                        <div class="buttons-centered-container">
                            <div class="buttons-centered">
                                <button class="btn btn-default" ng-click="$dismiss()">Cancel</button>
                                <button class="btn btn-primary" ng-click="$close()">Continue</button>
                            </div>
                        </div>
                    </div>`,
                bindToController: true,
                controllerAs: '$ctrl',
                resolve: {
                    firstName: _.constant(_.get(account, 'first_name', '')),
                    lastName: _.constant(_.get(account, 'last_name', '')),
                },
                controller: /* @ngInject */ function($scope, firstName, lastName) {
                    this.firstName = firstName;
                    this.lastName = lastName;
                },
            }).result;
        },
    };
}
