import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import uiRouter from 'angular-ui-router';

import userAccount from '../../../../user-account/user-account';
import utilsForm from '../../../../utils-form/utils-form';
import navigationNet from '../navigation-net/navigation-net';
import loginIdentity from '../login-identity/login-identity';

import adminEditAccountComponent from './admin-edit-account-component';

const adminEditAccount = angular.module('adminEditAccount', [
    userAccount,
    eventMediator,
    uiRouter,
    utilsForm,
    navigationNet,
    loginIdentity,
]);
adminEditAccount.component('adminEditAccount', adminEditAccountComponent);

export default adminEditAccount.name;
