import _ from 'lodash';
import { signInStatus } from '../login-constants';

export const steps = {
    roleSelection: 'role',
    schoolSelection: 'school',
    eula: 'eula',
};

export default class UserSetupController {
    /* @ngInject */
    constructor($uibModalInstance, eventMediator, userSetupFactory, userData) {
        this.$uibModalInstance = $uibModalInstance;
        this.eventMediator = eventMediator;
        this.userSetupFactory = userSetupFactory;
        this.userData = userData;
        this.handleSubmit = _.debounce(this.handleSubmitRaw.bind(this), 300, {
            leading: true,
            trailing: false,
        });

        this.steps = steps;

        this.onInit();
    }

    onInit() {
        // record the original list of steps on initialization
        // the server will continue to remove steps from "remainingSetup" as they are completed
        this.totalSteps = this.userData.remainingSetup;
        this.validationErrors = {};
        this.renderStep(this.userData, this.userData.remainingSetup[0]);
    }

    renderStep(userData, currentStep) {
        this.userData = userData;
        this.currentStep = currentStep;
        this.isBackVisible = this.getPreviousStep(this.totalSteps, this.currentStep);
    }

    getPreviousStep(totalSteps, step) {
        const prevStep = totalSteps[_.indexOf(totalSteps, step) - 1];
        return prevStep ? prevStep : false;
    }

    getNextStep(totalSteps, step) {
        const nextStep = totalSteps[_.indexOf(totalSteps, step) + 1];
        return nextStep ? nextStep : false;
    }

    renderGeneralError(message) {
        this.eventMediator.emit('messageNotification', { type: 'danger', message });
    }

    verifySetupInfoError(err) {
        if (err.type === 'validation-error') {
            this.validationErrors = err.errors;
            return;
        }

        return this.renderGeneralError(err.message);
    }

    verifySetupInfoSuccess(response) {
        if (response.type === signInStatus.authenticated) {
            return this.$uibModalInstance.close({ user: response.user });
        }

        return this.renderStep(
            response.userData,
            this.getNextStep(this.totalSteps, this.currentStep)
        );
    }

    handleBack(model) {
        const previousStep = this.getPreviousStep(this.totalSteps, this.currentStep);
        if (!previousStep) {
            return;
        }

        this.userData.setupInfo = _.assign({}, this.userData.setupInfo, model);
        this.renderStep(this.userData, previousStep);
    }

    handleSubmitRaw(model) {
        const userInfo = _.assign({}, this.userData.setupInfo, model);

        this.userSetupFactory
            .verifySetupInfo(userInfo)
            .then(this.verifySetupInfoSuccess.bind(this))
            .catch(this.verifySetupInfoError.bind(this));
    }

    handleHomeClick() {
        this.$uibModalInstance.dismiss();
    }
}
