import angular from 'angular';

import user from '../../user/user';

import SpecialistHomeController from './specialist-district-controller';

const specialistHome = angular.module('specialistHome', [user]);

specialistHome.controller('SpecialistHomeController', SpecialistHomeController);

export default specialistHome.name;
