/**
 * Customizes an assignment tile for the student view.
 * Very similar to a teacher view, but without the ability to edit.
 */
class StudentAssignmentTileController {
    // @ngInject
    constructor(assignmentTileHelper) {
        this.assignmentTileHelper = assignmentTileHelper;
    }

    $onChanges(changes) {
        if (!changes.assignment) return;

        this.tileCssClasses = this.getAssignmentBodyClasses(
            this.activeClassMembership,
            this.assignment
        );
        this.assignmentState = this.assignmentTileHelper.getAssignmentStateText(this.assignment);
        this.assignmentStateType = this.assignmentTileHelper.getAssignmentStateType(
            this.assignment
        );
    }

    getAssignmentBodyClasses(membership, assignment) {
        if (!membership) {
            return ['closed'];
        }

        return this.assignmentTileHelper.getAssignmentClasses(assignment);
    }

    handleLaunchAssignment() {
        this.onLaunchAssignment({ assignmentId: this.assignment.id });
    }
}

export default {
    templateUrl: 'student/student-class/student-assignment-tile-component.html',
    controller: StudentAssignmentTileController,
    bindings: {
        assignment: '<',
        onLaunchAssignment: '&',
        activeClassMembership: '=',
    },
};
