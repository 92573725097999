import _ from 'lodash';

import assignmentStates from '../../assignment-states';

export const AssignmentSettingsModalWrapperController = function(
    assignmentId,
    assignmentName,
    assignmentDueDate,
    assignmentState,
    className,
    classTime,
    $scope,
    teacherService,
    eventMediator,
    $filter
) {
    'ngInject';
    this.assignmentId = assignmentId;
    this.assignmentName = assignmentName;
    this.assignmentDueDate = assignmentDueDate;
    this.assignmentState = assignmentState;

    function notifySuccessAndCloseModal(message, assignment) {
        eventMediator.emit('messageNotification', {
            type: 'success',
            message,
        });
        $scope.$close(assignment);
    }

    this.handleSaveAssignment = function({ name, dueDate }) {
        const assignment = {
            id: assignmentId,
            name,
            due_date: $filter('date')(dueDate, 'yyyy-MM-dd'),
        };

        teacherService.editAssignment(assignment).then(() => {
            notifySuccessAndCloseModal(
                'You have successfully updated your assignment.',
                assignment
            );
        });
    };

    function setAssignmentState(state, message) {
        const assignment = {
            id: assignmentId,
            state,
        };

        teacherService
            .editAssignment(assignment)
            .then(() => notifySuccessAndCloseModal(message, assignment));
    }

    this.handleCloseAssignment = function() {
        setAssignmentState(
            assignmentStates.closed,
            'You have successfully closed your assignment.'
        );
    };

    this.handleReopenAssignment = function() {
        setAssignmentState(
            assignmentStates.open,
            'You have successfully reopened your assignment.'
        );
    };

    this.handleDeleteAssignment = function() {
        const undoData = {
            url: `/ra/assignments/${assignmentId}`,
            method: 'PUT',
            data: { state: assignmentState },
        };

        const successMessage = `<strong>${_.escape(
            assignmentName
        )}</strong> has been deleted from <strong>${_.escape(
            classTime ? `${classTime} - ${className}` : className
        )}</strong>.<a api-action=${angular.toJson(undoData)}>Undo</a>`;

        setAssignmentState(assignmentStates.deleted, successMessage);
    };

    this.handleDismiss = function() {
        $scope.$dismiss();
    };
};

export default /* @ngInject */ $uibModal => {
    const template = `
        <div class="assignment-settings-modal-wrapper">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"></button>
            <assignment-settings-modal
                assignment-id="$ctrl.assignmentId"
                assignment-name="$ctrl.assignmentName"
                assignment-due-date="$ctrl.assignmentDueDate"
                assignment-state="$ctrl.assignmentState"
                on-save-assignment="$ctrl.handleSaveAssignment(assignment)"
                on-close-assignment="$ctrl.handleCloseAssignment()"
                on-reopen-assignment="$ctrl.handleReopenAssignment()"
                on-delete-assignment="$ctrl.handleDeleteAssignment()"
            >
            </assignment-settings-modal>
        </div>
    `;

    function open(
        {
            name: assignmentName,
            id: assignmentId,
            due_date: assignmentDueDate,
            state: assignmentState,
        },
        { time: classTime, name: className }
    ) {
        return $uibModal.open({
            template,
            controller: 'AssignmentSettingsModalWrapperController',
            resolve: {
                assignmentId: _.constant(assignmentId),
                assignmentName: _.constant(assignmentName),
                assignmentDueDate: _.constant(assignmentDueDate),
                assignmentState: _.constant(assignmentState),
                classTime: _.constant(classTime),
                className: _.constant(className),
            },
            bindToController: true,
            controllerAs: '$ctrl',
            windowClass: 'assignment-settings-modal-window',
        }).result;
    }

    return {
        open,
    };
};
