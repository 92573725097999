import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import EventMediator from 'gong-event-mediator';
import ngSanitize from 'angular-sanitize';

import messageNotificationsDirective from './message-notifications-directive';
import bindCompiledHtmlDirective from './bind-compiled-html-directive';

const messageNotifications = angular.module('messageNotifications', [
    EventMediator,
    uiBootstrap,
    ngSanitize,
]);

messageNotifications.directive('messageNotifications', messageNotificationsDirective);
messageNotifications.directive('bindCompiledHtml', bindCompiledHtmlDirective);

export default messageNotifications.name;
