import angular from 'angular';

import shared from '../../components/components';

import ChangeTeacherPasswordController from './change-teacher-password-controller';

const changeTeacherPassword = angular.module('changeTeacherPassword', [shared]);
changeTeacherPassword.controller(
    'ChangeTeacherPasswordController',
    ChangeTeacherPasswordController
);

export default changeTeacherPassword.name;
