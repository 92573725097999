import adminPermissions from '../admin/admin-permissions';
import paymentPermissions from '../payment/payment-permissions';

/**
 * The complete set of group based permissions.
 * Represented as an object of:
 * { permissionName: permissionCheckFunction }
 */
const groupPermissionMap = _.assign(
    {},
    adminPermissions.groupPermissions,
    paymentPermissions.groupPermissions
);

/**
 * The complete set of general permissions.
 * Permissions that are not specific to a group but can exist _anywhere_ in the preferences object.
 * Represented as an object of:
 * { permissionName: permissionCheckFunction }
 */
const generalPermissionMap = _.assign(
    {},
    adminPermissions.generalPermissions,
    paymentPermissions.generalPermissions
);

/**
 * Transforms the given permission map of `{ permissionName: permissionFunction }`
 * into an array of permission names that are enabled for the given role and preferences.
 */
const buildPermissionsForMap = _.curry((permissionMap, role, preferences) => {
    return _(permissionMap)
        .mapValues(permissionFn => permissionFn(role, preferences))
        .pickBy(val => val)
        .keys()
        .value();
});

/*
 * Creates a keyed object of { groupId: [permissions] }
 * For example:
 * {
 *   '1': ['bulk-upload-teachers'],
 *   '2': [],
 * }
 */
function buildPermissionsForGroups(role, rawPreferences) {
    return _.mapValues(rawPreferences, buildPermissionsForMap(groupPermissionMap, role));
}

const buildGeneralPermissions = buildPermissionsForMap(generalPermissionMap);

/**
 * Creates an object of the shape:
 * {
 *   groupPermissions: {},
 *   generalPermissions: []
 * }
 * The `groupPermissions` are the result of `buildPermissionsForGroups` and the `generalPermissions`
 * are the result of `buildGeneralPermissions`.
 */
function buildPermissions(role, rawPreferences) {
    return {
        groupPermissions: buildPermissionsForGroups(role, rawPreferences),
        generalPermissions: buildGeneralPermissions(role, rawPreferences),
    };
}

export default /* @ngInject */ () => {
    return {
        buildPermissions,
    };
};
