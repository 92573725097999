import _ from 'lodash';
import { signInStatus } from './login-constants';

export default class LoginController {
    // @ngInject
    constructor(
        authService,
        ravenService,
        $q,
        $state,
        $log,
        $window,
        urls,
        eventMediator,
        $scope,
        googleSignInFactory,
        userSetupFactory
    ) {
        this.authService = authService;
        this.ravenService = ravenService;
        this.$q = $q;
        this.$state = $state;
        this.$log = $log;
        this.$window = $window;
        this.eventMediator = eventMediator;
        this.$scope = $scope;
        this.googleSignInFactory = googleSignInFactory;
        this.userSetupFactory = userSetupFactory;

        this.displayTeacherTrialLink = false;
        this.teacherTrialUrl = urls.teacherTrial;

        this.username = '';
        this.password = '';
        this.isSSOTooltipOpen = false;
        this.isLoginDisabled = false;

        authService.clearStorage();

        googleSignInFactory.renderButtonAndWaitForToken(
            'g-signin2',
            this.handleTokenSuccess.bind(this),
            this.renderErrorMessage.bind(this)
        );
    }

    loginUser() {
        this.form.showErrors = true;
        if (!this.form.$valid) return this.$q.reject();

        let user;
        const loginAndEulaEtc = this.authService
            .login(this.username, this.password)
            .then(_user => (user = _user))
            .then(() => this.$log.debug(`Login successful for user [${this.username}]`))
            .catch(rej => {
                const errors = _.get(rej, 'data.errors');
                this.$log.debug(`Login failed for user [${this.username}]`);
                try {
                    const { status } = rej;
                    if (status == 401) {
                        this.form.username.$error.badCredentials = true;
                    } else if (status == 403 || status == 403.6) {
                        this.form.username.$error.forbidden = true;
                    } else {
                        this.form.username.$error.other = true;
                    }
                } catch (e) {
                    // We are unsure of how an 'undefined' form is happening,
                    // but Sentry is reporting such errors,
                    // so we are logging the issue,
                    // in hopes of gathering more actionable information.
                    this.ravenService.captureFormException(e, this, errors, user);
                }
                return this.$q.reject(errors);
            })
            .then(() => user);

        return this.$q((resolve, reject) => {
            loginAndEulaEtc.catch(reject);
            this.eventMediator.subscribe(this.$scope, 'login', (evt, { user }) => {
                // Allow redirect underneath the EULA
                const redirectComplete = this.authService.redirect(user.getHomeState());

                this.$q
                    .all([loginAndEulaEtc, redirectComplete])
                    .then(() => {
                        this.eventMediator.emit('login-complete');
                        resolve(user);
                    })
                    .catch(reject);
            });
        });
    }

    handleTokenSuccess(tokenInfo) {
        this.isLoginDisabled = true;
        this.eventMediator.emit('messageNotification:removeAll', {});
        return this.googleSignInFactory
            .signInWithToken(tokenInfo)
            .then(this.handleSignInSuccess.bind(this))
            .catch(this.renderErrorMessage.bind(this))
            .finally(() => (this.isLoginDisabled = false));
    }

    handleSignInSuccess(response) {
        const loginActions =
            response.type === signInStatus.authenticated
                ? this.redirectUserToHomePage(response.user)
                : this.getAdditionalInfoForUser(response);
        return loginActions.then(() => this.eventMediator.emit('login-complete'));
    }

    renderErrorMessage(err) {
        if (_.get(err, 'message')) {
            this.eventMediator.emit('messageNotification', {
                type: 'danger',
                message: err.message,
            });
        }
    }

    redirectUserToHomePage(user) {
        const { toState, toParams } = user.getHomeState();
        return this.$state.go(toState, toParams);
    }

    getAdditionalInfoForUser({ userData }) {
        return this.userSetupFactory
            .showUserSetupModal(userData)
            .then(result => this.redirectUserToHomePage(result.user));
    }

    showSSOTooltip() {
        this.isSSOTooltipOpen = true;
    }

    hideSSOTooltip() {
        this.isSSOTooltipOpen = false;
    }
}
