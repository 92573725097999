import fp from 'lodash/fp';

function sortClassesByMembershipStart(classes = []) {
    return fp.flow(fp.sortBy('membership_state.start_date'), fp.reverse)(classes);
}

export default class StudentController {
    // @ngInject
    constructor(studentClassesData, studentService, eventMediator) {
        this.studentService = studentService;
        this.eventMediator = eventMediator;

        this.classCode = '';
        this.classes = sortClassesByMembershipStart(studentClassesData);
    }

    handleJoinClassSuccess(joinedClass) {
        this.classes = fp.flow(fp.uniqBy(currClass => currClass.id), sortClassesByMembershipStart)([
            joinedClass,
            ...this.classes,
        ]);

        this.classCode = '';

        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message: 'You joined a new class!',
        });
    }

    handleJoinClassFailure(rejection) {
        let errorMessage =
            'There was a problem enrolling you in this class. Please check your class code and try again.';
        if (_.has(rejection, 'errors')) {
            const collectedFieldErrors = _.map(
                _.get(rejection.errors, 'field', {}),
                (v, k) => `${k}: ${v.join(', ')}`
            );
            const collectedGlobalErrors = _.get(rejection.errors, 'global', []);

            errorMessage = collectedGlobalErrors.concat(collectedFieldErrors).join('; ');
        }

        this.eventMediator.emit('messageNotification', {
            type: 'danger',
            message: errorMessage,
        });
    }

    joinClass() {
        if (!this.classCode) return;

        return this.studentService
            .addClass(this.classCode)
            .then(this.handleJoinClassSuccess.bind(this))
            .catch(this.handleJoinClassFailure.bind(this));
    }
}
