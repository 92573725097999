/**
 * Very generic assignment tile that is basically UI only.
 * This is the basis for the teacher and student assignment tiles.
 */
class AssignmentTileController {
    // @ngInject
    constructor() {}
}

export default {
    templateUrl: 'assignment/assignment-tile/assignment-tile-component.html',
    controller: AssignmentTileController,
    bindings: {
        tileCssClasses: '<',
        assignment: '<',
        assignmentType: '<',
        assignmentState: '<',
        assignmentStateType: '<',
        showAssignmentEdit: '<',
        showAssignmentType: '<',
        editIconSrc: '<',
        onLaunchAssignment: '&',
        onAssignmentEdit: '&',
    },
};
