import angular from 'angular';

import usageReportFormComponent from './usage-report-form/usage-report-form-component';
import usageReportFormHelper from './usage-report-form/usage-report-form-helper';

const componentsModule = angular.module('usageReport.components', []);
componentsModule.component('usageReportForm', usageReportFormComponent);
componentsModule.factory('usageReportFormHelper', usageReportFormHelper);

export default componentsModule.name;
