import _ from 'lodash';

function getInstructorWizardModelFromConfig({
    instructorMultipleSchools,
    // instructorPasswordChange, ALWAYS TRUE (as of now)
    instructorPasswordDefault,
    instructorPasswordGenerate,
    instructorPasswordLengthMinimum,
}) {
    return {
        password: instructorPasswordDefault,
        generatePasswords: instructorPasswordDefault ? false : instructorPasswordGenerate || false,
        specifyOnePassword: !!instructorPasswordDefault,
        lessThanMinimum: instructorPasswordDefault ? undefined : instructorPasswordLengthMinimum,
        createDuplicateMemberships: instructorMultipleSchools || false,
    };
}

function getStudentWizardModelFromConfig({
    studentIdentifierType,
    studentPasswordChange,
    studentPasswordDefault,
    studentPasswordGenerate,
    studentSisIdentifierColumn,
    studentUsernameSuffix,
    studentPasswordLengthMinimum,
    studentCanReceiveEmails,
}) {
    return {
        password: studentPasswordDefault,
        generatePasswords: studentPasswordDefault ? false : studentPasswordGenerate || false,
        specifyOnePassword: !!studentPasswordDefault,
        lessThanMinimum: studentPasswordDefault ? undefined : studentPasswordLengthMinimum,
        signInWithEmail: studentIdentifierType === 'email',
        usernameSuffix: studentUsernameSuffix,
        includeUsernameSuffix: !!studentUsernameSuffix,
        includesStudentIdNumber: studentSisIdentifierColumn || false,
        changePassword: studentPasswordChange || false,
        canReceiveEmails: studentCanReceiveEmails,
    };
}

function getWizardModelFromConfig(config = {}, accountType) {
    if (!Object.keys(config).length) return {};

    if (accountType === 'teacher') return getInstructorWizardModelFromConfig(config);
    if (accountType === 'student') return getStudentWizardModelFromConfig(config);
    return {};
}

function getSharedConfigFromWizardModel({
    filename,
    generatePasswords,
    password,
    lessThanMinimum,
}) {
    return {
        filename_descriptive: filename,
        password_default: password,
        password_generate: generatePasswords,
        password_length_minimum: password ? undefined : lessThanMinimum,
    };
}

function getConfigFromWizardModel(model) {
    return _.assign({}, getSharedConfigFromWizardModel(model), {
        instructor_multiple_schools: model.createDuplicateMemberships,
        instructor_password_change: true,
    });
}

function getStudentConfigFromWizardModel(model) {
    return _.assign({}, getSharedConfigFromWizardModel(model), {
        student_identifier_type: model.signInWithEmail ? 'email' : 'username',
        student_can_receive_email: model.signInWithEmail ? model.canReceiveEmails : null,
        student_username_suffix: model.usernameSuffix,
        student_include_sis_identifier: model.includesStudentIdNumber,
        student_password_change: model.changePassword,
    });
}

function objectToCamelCase(object) {
    return _.mapKeys(object, (val, key) => _.camelCase(key));
}

// @ngInject
export default function bulkUploadService(
    $q,
    $timeout,
    bulkUploadService,
    spreadsheetUploaderFactory
) {
    function transformProgress(order) {
        const upload = _.get(order, 'uploads[0]', {});
        return {
            processed: upload.records_processed_count,
            total: upload.records_total_count,
        };
    }

    function pollOrderStatusUntilProcessed(order, progressCallback) {
        const { code, status, license_data } = order;
        if (status === 'over_license_limit') {
            return $q.reject({
                type: 'over_license_limit',
                message: `This upload exceeds your total limit of ${license_data.learner_license_total} students.
                     Please remove some accounts and try again.`,
            });
        }

        if (status !== 'processing') return $q.resolve(order);

        progressCallback(transformProgress(order));
        return $timeout(() => {
            return bulkUploadService
                .getBulkUploadOrder(code)
                .then(order => pollOrderStatusUntilProcessed(order, progressCallback));
        }, 1000);
    }

    function pollOrder(order, progressCallback = _.noop, minPollCount = 2000) {
        const minProcessingTimeout = $timeout(minPollCount);

        // to support cancelling (since you may navigate away from bulk upload while polling)
        // we use $q.defer so we can expose a `cancel` function in the resulting object
        const deferred = $q.defer();

        $q
            .all([pollOrderStatusUntilProcessed(order, progressCallback), minProcessingTimeout])
            .then(res => deferred.resolve(res[0]), err => deferred.reject(err));
        return {
            promise: deferred.promise,
            cancel: () => deferred.reject({ type: 'cancel' }),
        };
    }

    function createUploadData(accountData, config) {
        return _.assign({}, accountData, {
            config,
            // a temp flag used by the backend
            // goal: to deploy bulk upload features while ensuring prod bulk upload behavior doesn't change
            version: 2,
        });
    }

    function createTeacherAccountsInBulk(wizardModel, accountData) {
        const config = getConfigFromWizardModel(wizardModel);
        const uploadData = createUploadData(accountData, config);
        return bulkUploadService.createTeacherAccountsInBulk(uploadData);
    }

    function createStudentAccountsInBulk(wizardModel, accountData) {
        const config = getStudentConfigFromWizardModel(wizardModel);
        const uploadData = createUploadData(accountData, config);
        return bulkUploadService.createStudentAccountsInBulk(uploadData);
    }

    const createAccountsMap = {
        teacher: createTeacherAccountsInBulk,
        student: createStudentAccountsInBulk,
    };

    function transformHeaderErrors(rawHeaderErrors) {
        const missingHeaders = _.sortBy(
            rawHeaderErrors.missing_headers,
            bulkUploadService.getColumnOrder
        );
        const unexpectedHeaders = _(rawHeaderErrors.unexpected_headers)
            .map(objectToCamelCase)
            .sortBy(x => bulkUploadService.getColumnOrder(x.columnKey))
            .value();
        const duplicatedHeaders = _(rawHeaderErrors.duplicated_headers)
            .map(objectToCamelCase)
            .sortBy(x => bulkUploadService.getColumnOrder(x.columnKey))
            .value();

        return { missingHeaders, unexpectedHeaders, duplicatedHeaders };
    }

    function transformCreateAccountsError(err) {
        const rawHeaderErrors = _.pick(_.get(err, ['field']), [
            'missing_headers',
            'unexpected_headers',
            'duplicated_headers',
        ]);

        if (!_.isEmpty(rawHeaderErrors)) {
            return {
                type: 'header-validation',
                data: transformHeaderErrors(rawHeaderErrors),
            };
        }

        return {
            type: 'unknown',
            message: 'An error occurred. Please contact a customer support specialist.',
        };
    }

    function createAccountsInBulk(accountType, wizardModel, accountData) {
        const createAccounts = createAccountsMap[accountType];

        if (!createAccounts) {
            return $q.reject(`Cannot create accounts for unknown accountType: ${accountType}`);
        }

        return createAccounts(wizardModel, accountData).catch(
            _.flow(transformCreateAccountsError, $q.reject)
        );
    }

    function handleCreateAccountsFromWorkbookError(err) {
        if (_.some(err, x => _.has(x, 'messageKey'))) {
            const { errors } = spreadsheetUploaderFactory.standardizeErrorFormat(err);
            const message = _.get(errors, '[0].uiMessage');
            return {
                type: 'processing-error',
                message,
            };
        }

        return err;
    }

    function createAccountsFromWorkbook(accountType, groupId, model) {
        return spreadsheetUploaderFactory
            .processWorkbook(model.fileData.name, groupId, model.workbook)
            .then(data => createAccountsInBulk(accountType, model, data))
            .catch(_.flow(handleCreateAccountsFromWorkbookError, $q.reject));
    }

    return {
        pollOrder,
        getWizardModelFromConfig,
        createAccountsFromWorkbook,

        // currently only exposed for testing - plan to move out
        getConfigFromWizardModel,
        getStudentConfigFromWizardModel,
    };
}
