import _ from 'lodash';

class MultiSchoolAdminHomeController {
    // @ngInject
    constructor(schools) {
        this.schools = schools;

        const max = _.sumBy(this.schools, 'license.max');
        const in_use = _.sumBy(this.schools, 'license.in_use');

        this.license = { max, in_use };
        this.showMaxLicense = _.some(this.schools, { showMaxLicense: true });
    }
}

export default MultiSchoolAdminHomeController;
