import _ from 'lodash';

const NAVIGATION_EVENT = '$stateChangeStart';
const ADMIN_TEST = /^admin\./i;

class NavigationNetController {
    // @ngInject
    constructor($scope, eventMediator, $uibModal, $state) {
        this.$scope = $scope;
        this.eventMediator = eventMediator;
        this.$uibModal = $uibModal;
        this.$state = $state;

        eventMediator.subscribe($scope, NAVIGATION_EVENT, this._navigationNet.bind(this));
    }

    _forceNavigate(toState, toParams) {
        this.eventMediator.unsubscribe(this.$scope, NAVIGATION_EVENT);
        this.$state.go(toState, toParams);
    }

    _presentModal(toState, toParams, message) {
        this.$uibModal
            .open({
                templateUrl: 'admin/school/components/navigation-net/navigation-net-modal.html',
                resolve: {
                    message: _.constant(message),
                },
                controller: /* @ngInject */ ($scope, message) => {
                    $scope.message = message;
                },
            })
            .result.then(() => {
                this._forceNavigate(toState, toParams);
            });
    }

    _navigationNet(event, toState, toParams) {
        if (!ADMIN_TEST.test(toState.name)) return;
        event.preventDefault();

        this.onNavigate()
            .then(message => {
                this._presentModal(toState, toParams, message);
            })
            .catch(() => {
                this._forceNavigate(toState, toParams);
            });
    }
}

const navigationNetComponent = {
    // template: `<div></div>`,
    controller: NavigationNetController,
    bindings: {
        onNavigate: '&',
    },
};

export default navigationNetComponent;
