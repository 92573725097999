import angular from 'angular';
import uiRouter from 'angular-ui-router';
import EventMediator from 'gong-event-mediator';
import urlConfig from '../url-config/url-config';
import studentClass from './student-class/student-class';

import studentRoutes from './student-routes';
import StudentController from './student-controller';
import studentService from './student-service';

const student = angular.module('student', [uiRouter, urlConfig, EventMediator, studentClass]);
student.config(studentRoutes);

student.controller('StudentController', StudentController);
student.factory('studentService', studentService);

export default student.name;
