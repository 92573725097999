import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';

import utilsForm from '../../../../utils-form/utils-form';
import navigationNet from '../navigation-net/navigation-net';
import loginIdentity from '../login-identity/login-identity';

import createAccountComponent from './create-account-component';

const createAccountForm = angular.module('createAccountForm', [
    utilsForm,
    eventMediator,
    uiRouter,
    navigationNet,
    loginIdentity,
    uiBootstrap,
]);
createAccountForm.component('createAccount', createAccountComponent);

export default createAccountForm.name;
