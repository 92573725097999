import angular from 'angular';
import ngStorage from 'ngstorage';
import eventMediator from 'gong-event-mediator';

import userFactory from './user-factory';
import preferencesFactory from './preferences-factory';
import userPermissions from './user-permissions';

const user = angular.module('user', [ngStorage.name, eventMediator]);

user.factory('user', userFactory);
user.factory('preferences', preferencesFactory);
user.factory('userPermissions', userPermissions);

export default user.name;
