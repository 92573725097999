import angular from 'angular';
import EventMediator from 'gong-event-mediator';

import interactionShieldComponent from './interaction-shield-component';
import interactionShieldService from './interaction-shield-service';

const interactionShield = angular.module('interactionShield', [EventMediator]);

interactionShield.component('interactionShield', interactionShieldComponent);
interactionShield.factory('interactionShieldService', interactionShieldService);

export default interactionShield.name;
