import _ from 'lodash';
import * as L from 'partial.lenses';
import { COMPLEX_ROLES } from '../user/user-factory';

export const ADMIN_VIEW_TEACHERS = 'admin-view-teachers';
export const ADMIN_VIEW_ADMINISTRATORS = 'admin-view-administrators';
export const ADMIN_VIEW_USAGE_REPORT = 'admin-view-usage-report';

const {
    IMPLEMENTATION_SPECIALIST,
    DISTRICT_ADMIN,
    SINGLE_SCHOOL_ADMIN,
    MULTI_SCHOOL_ADMIN,
    LTI_ADMIN,
} = COMPLEX_ROLES;

export const preferences = {
    displayInstructor: 'feature.admin-display-instructor',
    displayAdministrator: 'feature.admin-display-administrator',
    displayUsageReport: 'feature.consumer-admin-reports',
};

const standaloneAdmin = [
    IMPLEMENTATION_SPECIALIST,
    DISTRICT_ADMIN,
    SINGLE_SCHOOL_ADMIN,
    MULTI_SCHOOL_ADMIN,
];

/**
 * Looks across all groups in a preferences object to see if a given preference is enabled.
 * This can probably be moved to a util file eventually.
 */
function isPreferenceEnabledForAnyGroup(preferenceName, preferences) {
    return L.any(x => x, [L.values, preferenceName], preferences);
}

const hasPreferenceAndRoleWithDefault = _.curry(
    (valueIfNoEntry, preference, roles, role, groupPreferences) => {
        const hasPreference = _.get(groupPreferences, [preference], valueIfNoEntry);

        if (!hasPreference) {
            return false;
        }

        return roles.includes(role);
    }
);

const hasPreferenceAndRole = hasPreferenceAndRoleWithDefault(true);

export function hasViewAdminUsagePermission(role, rawPreferences) {
    const hasPreference = isPreferenceEnabledForAnyGroup(
        preferences.displayUsageReport,
        rawPreferences
    );

    const allowedRoles = [LTI_ADMIN, ...standaloneAdmin];
    return hasPreference && allowedRoles.includes(role);
}

export const hasViewTeachersPermission = hasPreferenceAndRole(
    preferences.displayInstructor,
    standaloneAdmin
);

export const hasViewAdministratorsPermission = hasPreferenceAndRole(
    preferences.displayAdministrator,
    standaloneAdmin
);

export default {
    groupPermissions: {
        [ADMIN_VIEW_TEACHERS]: hasPreferenceAndRole(preferences.displayInstructor, standaloneAdmin),
        [ADMIN_VIEW_ADMINISTRATORS]: hasPreferenceAndRole(
            preferences.displayAdministrator,
            standaloneAdmin
        ),
    },
    generalPermissions: {
        [ADMIN_VIEW_USAGE_REPORT]: hasViewAdminUsagePermission,
    },
};
