import _ from 'lodash';
import changeCase from 'change-case-object';

import { signInErrorCodes, googleSignInErrors, googleTokenErrorCodes } from '../login-constants';

export default /* @ngInject */ ($http, $q, $uibModal, urls, gapi, authService) => {
    function renderButton(id, success, failure) {
        gapi.signin2.render(id, {
            longtitle: true,
            onsuccess: success,
            onfailure: failure,
        });
    }

    function transformTokenFailure(response) {
        const message =
            response.error === googleTokenErrorCodes.popupClosed
                ? ''
                : 'Oops! We are having trouble accessing your Google account.';

        return { type: response.error, message };
    }

    /**
     * We are immediately signing the user out of Google so that they need to re-auth each time
     * they visit the sign in page. Basically, we just want to get the token, sign them out,
     * and then authenticate them to RA via the token. From that point on, RA manages their auth/session.
     * The `token` is the JWT token which contains needed info about the user.
     * The `accessToken` is an auth token that our API can use to go back to Google if needed for
     * additional info. In some cases, the JWT does not contain first, last, email and our API will
     * use the accessToken to attempt to load this server side.
     */
    function signOutAndReturnToken(googleUser) {
        gapi.auth2.getAuthInstance().signOut();
        const authReponse = googleUser.getAuthResponse(true);
        return {
            token: authReponse.id_token,
            accessToken: authReponse.access_token,
        };
    }

    /**
     * Provides the ability to render the Google OAuth button and report back when the user signs in.
     * https://developers.google.com/identity/sign-in/web/sign-in
     * https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapisignin2renderid-options
     */
    function renderButtonAndWaitForToken(id, success, failure) {
        const fullSuccess = _.flow(signOutAndReturnToken, success);
        const fullFailure = _.flow(transformTokenFailure, failure);
        return renderButton(id, fullSuccess, fullFailure);
    }

    function loginWithGoogleTokenFailure(response) {
        const status = _.get(response, 'status');
        if (status == 403 || status == 403.6) {
            return $q.reject(googleSignInErrors[signInErrorCodes.badConnectionOrNetwork]);
        }

        if (status === 401) {
            return $q.reject(googleSignInErrors[signInErrorCodes.badCredentials]);
        }

        return $q.reject(googleSignInErrors[signInErrorCodes.unknown]);
    }

    function signInWithToken(tokenInfo) {
        const url = `${urls.base}/ra/identity/by-google`;
        return $http({
            url,
            method: 'POST',
            data: changeCase.snakeCase(tokenInfo),
        })
            .then(authService.validateAuthResponse())
            .catch(loginWithGoogleTokenFailure);
    }

    return {
        renderButtonAndWaitForToken,
        signInWithToken,
    };
};
