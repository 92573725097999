import { isCurrentTeacherTrialUser } from '../payment/payment-authorization';

class FooterController {
    // @ngInject
    constructor($scope, urls, user, eventMediator) {
        this.$scope = $scope;
        this.eventMediator = eventMediator;
        this.urls = urls;
        this.year = new Date().getFullYear();
        this.user = user;

        this.onUserChange();
        this.eventMediator.subscribe($scope, 'login', this.onUserChange.bind(this));
        this.eventMediator.subscribe($scope, 'logout', this.onUserChange.bind(this));
    }

    isAuthenticated() {
        return this.user.isAuthenticated();
    }

    onUserChange() {
        this.helpUrl =
            this.user && isCurrentTeacherTrialUser(this.user)
                ? this.urls.teacherTrialHelp
                : this.urls.help;
    }
}

const footer = {
    templateUrl: 'footer/footer.html',
    controller: FooterController,
};

export default footer;
