import angular from 'angular';

import shared from './components/components';
import schoolDashboard from './dashboard/school-dashboard';
import students from './students/students';
import teachers from './teachers/teachers';
import admins from './admin/admin';

const school = angular.module('school', [shared, students, teachers, schoolDashboard, admins]);

export default school.name;
