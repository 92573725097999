import _ from 'lodash';

export default /* @ngInject */ () => {
    /**
     * The FULL list of status values including those that only exist from the legacy process.
     */
    const orderStatus = {
        processing: 'processing',
        successful: 'successful',
        unsuccessful: 'unsuccessful',
        deleting: 'deleting',
        deleted: 'deleted',
        received: 'received',
        validating: 'validating',
        created: 'created',
        invalid: 'invalid',
        canceled: 'canceled',
    };

    /**
     * The list of statuses that are essentially ignored now.
     * These were only produced by the legacy bulk upload process but since the data still exists
     * in the database we need to account for them (by filtering them out).
     * At somepoint if we completely delete this old data from the database we can also delete
     * the code that accounts for these statuses in this file.
     */
    const legacyStatuses = [
        orderStatus.received,
        orderStatus.validating,
        orderStatus.created,
        orderStatus.invalid,
        orderStatus.canceled,
    ];

    const uploadTypeMap = {
        instructor: 'Teacher',
        learner: 'Student',
    };

    function transformDownloadHistoryItem(item) {
        return {
            downloadType: item.download_type,
            created: item.created,
            name: `${item.identity_first_name} ${item.identity_last_name}`.trim(),
        };
    }

    function getDownloadHistory(order) {
        return _.map(order.downloads, transformDownloadHistoryItem);
    }

    function transformOrder(order) {
        const upload = _.get(order, 'uploads[0]', {});
        const uploadType = _.toLower(upload.membership_type);
        const code = order.identifier;
        const config = order.config;

        return {
            id: order.id,
            ownerId: order.owner_id,
            uploadId: upload.id,
            uploadType,
            code,
            codeDescription: `${uploadTypeMap[uploadType]} - ${code}`,
            config,
            fileNameDescriptive: _.get(order, 'config.filename_descriptive') || upload.filename,
            created: upload.created,
            status: order.status,
            downloads: getDownloadHistory(order),
            canBeDeleted:
                order.status === orderStatus.successful && upload.records_created_count > 0,
            recordsTotalCount: upload.records_total_count,
            recordsCreatedCount: upload.records_created_count,
            recordsCreatedIdentityCount: upload.records_created_identity_count,
            recordsCreatedMembershipOnlyCount: upload.records_created_membership_only_count,
            recordsDeletedIdentityCount: upload.records_deleted_identity_count,
            recordsDeletedMembershipOnlyCount: upload.records_deleted_membership_only_count,
            recordsDeletedCount: upload.records_deleted_count,
            recordsUnableToBeDeletedCount: upload.records_unable_to_be_deleted_count,
            recordsInvalidCount: upload.records_invalid_count,
            recordsProcessedCount: upload.records_processed_count,
            recordsUnchangedCount: upload.records_unchanged_count,
        };
    }

    function orderIsLegacyStatus(order) {
        const status = order.status;
        return _.includes(legacyStatuses, status);
    }

    function transformOrders(orders) {
        return _(orders)
            .reject(orderIsLegacyStatus)
            .map(transformOrder)
            .value();
    }

    return {
        orderStatus,
        transformOrder,
        transformOrders,
    };
};
