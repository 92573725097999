import _ from 'lodash';

function getDefaultState(initialModel = {}) {
    return [
        {
            title: 'Step 1 of 4. Check your spreadsheet',
            model: initialModel,
        },
        {
            title: 'Step 2 of 4. Select Account Creation Settings',
            model: initialModel,
        },
        {
            title: 'Step 3 of 4. Select Password Creation Settings',
            model: initialModel,
        },
        {
            title: 'Step 4 of 4. Upload Your File',
            model: initialModel,
        },
    ];
}

const wizardConfigByType = {
    student: {
        templateUrl:
            '/downloadables/templates/bulk-upload/Revision Assistant Account Upload Template - Students.xlsx',
        isChangePasswordAvailable: true,
        requireDataWaiver: true,
    },
    teacher: {
        templateUrl:
            '/downloadables/templates/bulk-upload/Revision Assistant Account Upload Template - Teachers.xlsx',
        isChangePasswordAvailable: false,
        requireDataWaiver: false,
    },
};

function getWizardConfigByType(type, bulkUploadService) {
    return _.assign({}, wizardConfigByType[type], {
        spreadsheetHeaders: bulkUploadService[`get${_.startCase(type)}Headers`](),
    });
}

function updateStateAt(index, model, currentState) {
    return currentState.map((step, stateIndex) => {
        if (index !== stateIndex) {
            return step;
        }

        return _.assign({}, step, { model });
    });
}

class BulkUploadWizardController {
    // @ngInject
    constructor(bulkUploadService) {
        this.bulkUploadService = bulkUploadService;
    }

    $onInit() {
        this.currentIndex = 0;
        this.state = getDefaultState(this.initialModel);
    }

    $onChanges() {
        this.config = getWizardConfigByType(this.accountType, this.bulkUploadService);
    }

    saveAndMoveToIndex(index, model = {}) {
        this.state = updateStateAt(this.currentIndex, model, this.state);
        this.currentIndex = index;
    }

    completeWizard(model) {
        this.state = updateStateAt(this.currentIndex, model, this.state);
        const finalModel = _.reduce(this.state, (acc, x) => _.assign({}, acc, x.model), {});
        this.onComplete({ model: finalModel });
    }
}

export default {
    bindings: {
        accountType: '<',
        school: '<',
        onComplete: '&',
        initialModel: '<',
    },
    controller: BulkUploadWizardController,
    templateUrl: 'admin/bulk-upload/bulk-upload-wizard/bulk-upload-wizard.html',
};
