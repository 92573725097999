import angular from 'angular';
import uiRouter from 'angular-ui-router';
import urlConfig from '../url-config/url-config';
import EventMediator from 'gong-event-mediator';

import ExpirationPendingModalController from './expiration-pending-modal/expiration-pending-modal-controller';
import expirationPendingModalFactory from './expiration-pending-modal/expiration-pending-modal-factory';
import PaymentFormController from './form/payment-form-controller';
import PaymentPendingController from './pending/payment-pending-controller';
import paymentRoutes from './payment-routes';
import paymentService from './payment-service';
import teacherTrialPaymentBannerComponent from './components/teacher-trial-payment-banner-component';

import login from '../login/login';
import user from '../user/user';

const payment = angular.module('payment', [uiRouter, urlConfig, EventMediator, login, user]);
payment.config(paymentRoutes);
payment.controller('ExpirationPendingModalController', ExpirationPendingModalController);
payment.factory('expirationPendingModalFactory', expirationPendingModalFactory);
payment.controller('PaymentFormController', PaymentFormController);
payment.controller('PaymentPendingController', PaymentPendingController);
payment.service('paymentService', paymentService);
payment.component('teacherTrialPaymentBanner', teacherTrialPaymentBannerComponent);

export default payment.name;
