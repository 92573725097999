class CreateTeacherAccountController {
    // @ngInject
    constructor(adminService, school) {
        this.adminService = adminService;
        this.school = school;

        this.data = {
            useEmail: true,
        };
    }

    createTeacherAccount(schoolId, data) {
        return this.adminService.createTeacherAccount(schoolId, data);
    }

    removeTeacherAccount(schoolId, identityId) {
        return this.adminService.removeTeacherById(schoolId, identityId);
    }
}

export default CreateTeacherAccountController;
