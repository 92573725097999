export default class LoginSacController {
    /* @ngInject */
    constructor($state, authService, sacData, $window, $location, eventMediator, loginFactory) {
        this.$state = $state;
        this.authService = authService;
        this.sacData = sacData;
        this.history = $window.history;
        this.$location = $location;
        this.eventMediator = eventMediator;
        this.loginFactory = loginFactory;

        this.onInit();
    }

    onInit() {
        // this state wasn't always populating browser history
        // but it needs to be in the history to block the user from hitting
        // the back button and winding up back in the provider
        this.history.pushState({}, null, this.$location.absUrl());

        return this.authService
            .loginFromSacToken(this.sacData.token)
            .then(() => this.loginFactory.processRedirectData(this.sacData.redirectData))
            .then(() => this.eventMediator.emit('login-complete'))
            .catch(() => this.$state.go('login'));
    }
}
