export default class ChangePasswordModalController {
    // @ngInject
    constructor($http, $log, $uibModalInstance, urls, user, utils, eventMediator, authService) {
        this.$http = $http;
        this.$log = $log;
        this.$uibModalInstance = $uibModalInstance;
        this.urls = urls;
        this.userFactory = user;
        this.user = user.getUser();
        this.eventMediator = eventMediator;
        this.newPassword = '';
        this.newPasswordConfirm = '';
        this.userService = user;
        this.authService = authService;
    }
    resolve() {
        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message: 'Your password has been changed!',
        });
        this.eventMediator.emit('user.change_password.changed');
        this.$uibModalInstance.close();
    }
    reject(rej) {
        this.$log.debug('<ChangePasswordModalController> password change rejected');
        this.$log.debug(rej);
        this.form.showErrors = true; //validation will show continuously unless flag is set
    }
    save() {
        if (this.form.$valid) {
            return this.changeUserPassword(angular.copy(this.newPassword))
                .then(
                    function() {
                        this.resolve();
                    }.bind(this)
                )
                .catch(this.reject.bind(this));
        } else {
            this.form.showErrors = true; //validation will show continuously unless flag is set
        }
    }
    changeUserPassword(password) {
        if (this.user.change_password) {
            return this.authService
                .changeUserPassword(password)
                .then(this.login.bind(this))
                .catch(this.reject('password change failed'));
        } else {
            this.$log.debug('<ChangePasswordModalController> credentials ok');
            return this.$q.resolve(true);
        }
    }
    login() {
        this.$log.debug(this.user);
        return this.authService
            .login(this.userService.getIdentifier(), this.newPassword)
            .then(ok => ok)
            .catch(() => {
                //login attempt failed
                this.authService.logOut();
                return this.reject('login failed');
            });
    }
}
