import _ from 'lodash';

function getDefaultModel() {
    return {
        createDuplicateMemberships: null,
    };
}

class AccountCreationSettingsController {
    $onInit() {
        const initialModel = _.pick(this.initialModel, Object.keys(getDefaultModel()));
        this.model = _.assign(getDefaultModel(), initialModel);
    }

    back() {
        this.onBack({ model: this.model });
    }

    saveAndContinue() {
        if (this.form.$valid) {
            this.onSaveAndContinue({ model: this.model });
        }
    }
}

export default {
    bindings: {
        initialModel: '<',
        onBack: '&',
        onSaveAndContinue: '&',
    },
    controller: AccountCreationSettingsController,
    templateUrl:
        'admin/bulk-upload/bulk-upload-wizard/account-creation-settings/account-creation-settings.html',
};
