import angular from 'angular';
import EventMediator from 'gong-event-mediator';

import assignmentSettingsModalComponent from './assignment-settings-modal-component';
import assignmentSettingsModalFactory, {
    AssignmentSettingsModalWrapperController,
} from './assignment-settings-modal-factory';

import teacherShared from '../../teacher/shared/shared';

const assignmentSettingModal = angular.module('assignmentSettingModal', [
    teacherShared,
    EventMediator,
]);
assignmentSettingModal.component('assignmentSettingsModal', assignmentSettingsModalComponent);
assignmentSettingModal.controller(
    'AssignmentSettingsModalWrapperController',
    AssignmentSettingsModalWrapperController
);
assignmentSettingModal.factory('assignmentSettingsModalFactory', assignmentSettingsModalFactory);

export default assignmentSettingModal.name;
