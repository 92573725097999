import angular from 'angular';

import createTeacherAccount from './create-teacher-account/create-teacher-account';
import createTeacherAccountSuccess from './create-teacher-account-success/create-teacher-account-success';
import changeTeacherPassword from './change-teacher-password/change-teacher-password';
import editTeacherAccount from './edit-teacher-account/edit-teacher-account';
import viewTeacherAccount from './view-teacher-account/view-teacher-account';
import teacherAccounts from './teacher-accounts/teacher-accounts';

const teachers = angular.module('teachers', [
    createTeacherAccount,
    createTeacherAccountSuccess,
    changeTeacherPassword,
    editTeacherAccount,
    viewTeacherAccount,
    teacherAccounts,
]);

export default teachers.name;
