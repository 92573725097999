class CreateAccountSuccessController {
    // @ngInject
    constructor() {}

    getStateParams() {
        return JSON.stringify(_.pick(this.userData, ['schoolId', 'districtId']));
    }
}

const createAccountSuccessComponent = {
    templateUrl:
        'admin/school/components/create-account-success/create-account-success-component.html',
    controller: CreateAccountSuccessController,
    bindings: {
        label: '@',
        accountRosterState: '@',
        createAccountState: '@',
        bodyCopy: '<',
        userData: '<',
    },
};

export default createAccountSuccessComponent;
