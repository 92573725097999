export default function bulkDeletionModalFactory($filter, $uibModal) {
    'ngInject';

    return {
        confirmSpreadsheetCancellation() {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                template: `
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"></button>
                            <div class="modal-header">
                                <h1>Are you sure?</h1>
                            </div>
                            <div class="modal-body">
                                <p>
                                    The spreadsheet will be deleted.
                                    <br />
                                    You cannot undo this action.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <div class="buttons-centered-container">
                                    <div class="buttons-centered">
                                        <button class="btn btn-default" ng-click="$dismiss()">Cancel</button>
                                        <button class="btn btn-primary" ng-click="$close()">Delete Spreadsheet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `,
            }).result;
        },
        confirmAccountDeletion(recordsCreatedCount) {
            let numberFormatter = $filter('number');
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                windowClass: 'wide-content',
                template: `
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"></button>
                            <div class="modal-header">
                                <h1>Are you sure?</h1>
                            </div>
                            <div class="modal-body">
                                <p>
                                    <span ng-if="${recordsCreatedCount} !== 1">
                                        All
                                        ${numberFormatter(recordsCreatedCount)}
                                        accounts added from this upload will be removed for this school.
                                    </span>
                                    <span ng-if="${recordsCreatedCount} === 1">
                                        All accounts added from this upload will be removed for this school.
                                    </span>
                                    <br />
                                    You cannot undo this action.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <div class="buttons-centered-container">
                                    <div class="buttons-centered">
                                        <button class="btn btn-default" ng-click="$dismiss()">Go Back</button>
                                        <button class="btn btn-primary" ng-click="$close()">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `,
            }).result;
        },
        alertUnableToCancelSpreadsheet() {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                template: `
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"></button>
                            <div class="modal-header">
                                <h1>Sorry!</h1>
                            </div>
                            <div class="modal-body">
                                <p>The system was unable to remove the spreadsheet.</p>
                            </div>
                            <div class="modal-footer">
                                <div class="buttons-centered-container">
                                    <div class="buttons-centered">
                                        <button class="btn btn-primary" ng-click="$dismiss()">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `,
            }).result;
        },
        alertUnableToDeleteAccounts(accountType) {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                template: `
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"></button>
                            <div class="modal-header">
                                <h1>Sorry!</h1>
                            </div>
                            <div class="modal-body">
                                <p>
                                    Looks like some of these ${accountType} have already signed in,
                                    so you can no longer delete these accounts.
                                </p>
                                <p>
                                    Please contact your customer support specialist for assistance.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <div class="buttons-centered-container">
                                    <div class="buttons-centered">
                                        <button class="btn btn-primary" ng-click="$dismiss()">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `,
            }).result;
        },
    };
}
