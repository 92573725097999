import angular from 'angular';
import ngStorage from 'ngstorage';
import uiBootstrap from 'angular-ui-bootstrap';
import EventMediator from 'gong-event-mediator';

import urlConfig from '../../url-config/url-config';
import user from '../../user/user';
import utils from '../../utils/utils';
import utilsForm from '../../utils-form/utils-form';
import interactionShield from '../../interaction-shield/interaction-shield';
import sentry from '../../sentry/sentry';

import ChangePasswordModalController from './change-password-modal-controller';
import authService from './auth-service';
import EulaModalController from './eula-modal-controller';
import UserSetupController from './user-setup-controller';
import loginModalFactory from './login-modal-factory';
import userSetupFactory from './user-setup-factory';
import eulaSso from './eula-sso/eula-sso-component';
import roleSelectionComponent from './role-selection/role-selection-component';
import schoolSelectionComponent from './school-selection/school-selection-component';

const loginShared = angular.module('loginShared', [
    utilsForm,
    urlConfig,
    uiBootstrap,
    user,
    utils,
    EventMediator,
    ngStorage.name,
    interactionShield,
    sentry,
]);

loginShared.controller('EulaModalController', EulaModalController);
loginShared.controller('UserSetupController', UserSetupController);
loginShared.controller('ChangePasswordModalController', ChangePasswordModalController);
loginShared.service('authService', authService);
loginShared.factory('loginModalFactory', loginModalFactory);
loginShared.factory('userSetupFactory', userSetupFactory);
loginShared.component('eulaSso', eulaSso);
loginShared.component('roleSelection', roleSelectionComponent);
loginShared.component('schoolSelection', schoolSelectionComponent);

export default loginShared.name;
