import _ from 'lodash';

class AdminViewAccountController {
    // @ngInject
    constructor($stateParams) {
        // $state.go with option {reload: true} isn't working to repopulate the target user with updated data
        // the targetUserOverride parameter is being used as a hack (hopefully only short-term)
        this.targetUser = $stateParams.targetUserOverride || this.targetUser;
    }

    $onInit() {
        // TODO update teacher and student account viewing to use group rather than school
        if (this.group || !this.schoolId) return;
        this.group = {
            id: this.schoolId,
            type: 'school',
        };
    }

    getStateParams(params) {
        return JSON.stringify(
            _.assign(
                {
                    [`${this.group.type}Id`]: this.group.id,
                },
                params
            )
        );
    }

    _getUISref(state) {
        return `${state}(${this.getStateParams({ identityId: this.targetUser.identity_id })})`;
    }

    getChangePasswordUISref() {
        return this._getUISref(this.changePasswordState);
    }

    getEditUISref() {
        return this._getUISref(this.editState);
    }

    getViewAccountsUISref() {
        return `${this.viewAccountsState}(${this.getStateParams()})`;
    }
}

const adminViewAccountComponent = {
    templateUrl: 'admin/school/components/admin-view-account/admin-view-account.html',
    controller: AdminViewAccountController,
    bindings: {
        label: '@',
        editState: '@',
        changePasswordState: '@',
        viewAccountsState: '@',
        targetUser: '<',
        schoolId: '<',
        group: '<',
    },
};

export default adminViewAccountComponent;
