export default class SpecialistHomeController {
    // @ngInject
    constructor(user, districts) {
        this.districts = districts;
        this.disabledDistricts = user.getUser().licenses.reduce((map, license) => {
            map[license.id] = license.products.some(
                product => product.product_type === 'Revision Assistant - Integration'
            );
            return map;
        }, {});
    }
}
