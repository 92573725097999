// TODO add spread to project
import _ from 'lodash';

const COLUMNS = {
    firstName: 'first_name',
    lastName: 'last_name',
    emailOrUsername: 'email_or_username',
    email: 'email',
    username: 'username',
    password: 'password',
    studentId: 'student_id',
};

const COLUMNS_SORT_ORDER = [
    COLUMNS.firstName,
    COLUMNS.lastName,
    COLUMNS.emailOrUsername,
    COLUMNS.email,
    COLUMNS.username,
    COLUMNS.password,
    COLUMNS.studentId,
];

const studentHeaderDisplay = {
    [COLUMNS.firstName]: 'Student First Name',
    [COLUMNS.lastName]: 'Student Last Name',
    [COLUMNS.emailOrUsername]: 'Email Address or Username',
    [COLUMNS.email]: 'Email Address',
    [COLUMNS.username]: 'Username',
    [COLUMNS.password]: 'Password (Optional); minimum of 8 characters',
    [COLUMNS.studentId]: 'Student ID (Optional); cannot be a Social Security Number (SSN)',
};

const teacherHeaderDisplay = {
    [COLUMNS.firstName]: 'Teacher First Name',
    [COLUMNS.lastName]: 'Teacher Last Name',
    [COLUMNS.email]: 'Email Address',
    [COLUMNS.password]: 'Password (Optional); minimum of 8 characters',
};

/**
 * Returns an int for the column to aid in sorting collections of columns
 */
function getColumnOrder(columnName) {
    return COLUMNS_SORT_ORDER.indexOf(columnName);
}

/**
 * Given an array of header "keys" returns the same set of keys, but in sorted order
 */
function sortHeaderKeys(keys) {
    return _.sortBy(keys, getColumnOrder);
}

function getStudentHeaders() {
    return sortHeaderKeys([
        COLUMNS.firstName,
        COLUMNS.lastName,
        COLUMNS.emailOrUsername,
        COLUMNS.password,
        COLUMNS.studentId,
    ]).map(x => _.get(studentHeaderDisplay, x));
}

function getTeacherHeaders() {
    return sortHeaderKeys([
        COLUMNS.firstName,
        COLUMNS.lastName,
        COLUMNS.email,
        COLUMNS.password,
    ]).map(x => _.get(teacherHeaderDisplay, x));
}

// @ngInject
export default function bulkUploadService(
    urls,
    $http,
    $q,
    postDownloadFactory,
    bulkUploadOrderTransformer
) {
    function createAccountsInBulk(data, membership_type) {
        const dataWithMembership = _.assign({}, data, { membership_type });
        const url = `${urls.base}/ra/idbatch/orders`;
        return $q((resolve, reject) => {
            $http({ url, method: 'POST', data: dataWithMembership })
                .then(res => resolve(res.data))
                .catch(res => reject(_.get(res, 'data.errors'), {}));
        });
    }

    function getBulkUploadOrdersForGroups(groupIds, origin = 'bulk upload') {
        const url = `${urls.base}/ra/idbatch/orders`;
        const params = { group_id: groupIds, origin };
        return $http({ url, method: 'GET', params })
            .then(res => res.data.orders)
            .catch(rej => $q.reject(rej.data.errors));
    }

    // TODO: remove when bulk upload config options are returned as preferences
    function getConfigByAccounType(ordersPromise, accountType) {
        return ordersPromise.then(orders => {
            const order = _.find(orders, ['uploadType', accountType]);
            return _.get(order, 'config', {});
        });
    }

    return {
        getBulkUploadOrder(orderCode) {
            const url = `${urls.base}/ra/idbatch/orders/${orderCode}`;
            return $q((resolve, reject) => {
                $http({ url, method: 'GET' })
                    .then(res => resolve(res.data))
                    .catch(res => reject(res.data.errors));
            });
        },
        deleteBulkUploadOrder(orderCode) {
            const url = `${urls.base}/ra/idbatch/orders/${orderCode}/undo`;
            return $http({ url, method: 'POST' })
                .then(res => res.data)
                .catch(rej => $q.reject(rej.data.errors));
        },
        getBulkUploadOrdersForGroups,
        getAllBulkUploadOrdersForGroup(groupId) {
            const uploadsV1 = getBulkUploadOrdersForGroups([groupId]);
            const uploadsV2 = getBulkUploadOrdersForGroups([groupId], 'bulk upload two');

            return $q
                .all([uploadsV1, uploadsV2])
                .then(([v1, v2]) => _.concat(v2, v1))
                .then(bulkUploadOrderTransformer.transformOrders);
        },
        downloadOutcomeFiles(uploadId, types = []) {
            const url = `${urls.base}/ra/idbatch/uploads/${uploadId}/sheet`;
            const formModifier = (elCreator, form) => {
                const inputs = types.map(type => {
                    return elCreator('<input />')
                        .attr({ type: 'hidden', name: 'sheet_type' })
                        .val(type);
                });

                inputs.forEach(input => form.append(input));
                return form;
            };
            return postDownloadFactory.downloadWithFormModifications(formModifier, url);
        },
        createStudentAccountsInBulk(data) {
            return createAccountsInBulk(data, 'Learner');
        },
        createTeacherAccountsInBulk(data) {
            return createAccountsInBulk(data, 'Instructor');
        },
        getStudentHeaders,
        getTeacherHeaders,
        getColumnOrder,
        sortHeaderKeys,
        // TODO: remove when bulk upload config options are returned as preferences
        getStudentConfig(groupId) {
            return getConfigByAccounType(
                this.getAllBulkUploadOrdersForGroup(groupId),
                'learner'
            ).then(config => {
                return {
                    studentCanReceiveEmails: config.student_can_receive_email,
                    studentIdentifierType: config.student_identifier_type,
                    studentPasswordChange: config.student_password_change,
                    studentPasswordDefault: config.password_default,
                    studentPasswordGenerate: config.password_generate,
                    studentSisIdentifierColumn: config.student_include_sis_identifier,
                    studentUsernameSuffix: config.student_username_suffix,
                    studentPasswordLengthMinimum: config.password_length_minimum,
                };
            });
        },
        // TODO: remove when bulk upload config options are returned as preferences
        getTeacherConfig(groupId) {
            return getConfigByAccounType(
                this.getAllBulkUploadOrdersForGroup(groupId),
                'instructor'
            ).then(config => {
                return {
                    instructorMultipleSchools: config.instructor_multiple_schools,
                    instructorPasswordChange: config.instructor_password_change,
                    instructorPassword_default: config.password_default,
                    instructorPasswordGenerate: config.password_generate,
                    instructorPasswordLengthMinimum: config.password_length_minimum,
                };
            });
        },
    };
}
