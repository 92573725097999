import angular from 'angular';

import spreadsheetFactory from './spreadsheet-factory';
import spreadsheetUploaderFactory from './spreadsheet-uploader-factory';

const spreadsheet = angular.module('spreadsheet', []);

spreadsheet.factory('spreadsheetFactory', spreadsheetFactory);
spreadsheet.factory('spreadsheetUploaderFactory', spreadsheetUploaderFactory);

export default spreadsheet.name;
