import _ from 'lodash';
import changeCase from 'change-case-object';

import { userSetupErrors } from '../login-constants';

export default /* @ngInject */ ($http, $q, $uibModal, urls, authService) => {
    function verifySetupInfoError(response) {
        const status = _.get(response, 'status');
        if (status == 400) {
            const errors = _.get(response, 'data.errors', {});
            return $q.reject({
                type: 'validation-error',
                errors: changeCase.camelCase(errors),
            });
        }

        return $q.reject(userSetupErrors.unknown);
    }

    function isLearnerWithEmptyGroup({ role, membershipGroupIds }) {
        return role === 'Learner' && _.isEmpty(membershipGroupIds);
    }

    /**
     * In the case where the UI handles both role AND school selection we need to make sure and
     * auto populate the user's that select Learner since they won't choose a school in the UI.
     * This will essentially make their learner membership be applied to the group that matched
     * their SSO domain name.
     */
    function ensureDefaultGroupIsSet(setupInfo) {
        const membershipGroupIds = isLearnerWithEmptyGroup(setupInfo)
            ? [setupInfo.domainGroupId]
            : setupInfo.membershipGroupIds;
        return _.assign({}, setupInfo, { membershipGroupIds });
    }

    /**
     * Right now, this sends up either an in progress or completed `setupInfo` to the API.
     * The result can be a few things identified by a `type` property on the result:
     * Errors (via `.catch`):
     *  'error' - this is a 500 (or similar) and is unexpected
     *  'validation-error' - something in the `setupInfo` is invalid - teacherCode is an example
     * Success: (via `then`):
     *  'requires-setup' - the populated `setupInfo` is valid, but is incomplete
     *  'authenticated' - `setupInfo` is valid and complete, an identity has been created.
     */

    function verifySetupInfo(setupInfo) {
        return $http({
            url: `${urls.base}/ra/identity/finish-google-setup`,
            method: 'POST',
            data: changeCase.snakeCase(ensureDefaultGroupIsSet(setupInfo)),
        })
            .then(response => authService.validateAuthResponse(response))
            .catch(verifySetupInfoError);
    }

    /**
     * Shows a modal that walks the user through setup and resolves with an authenticated user.
     */
    function showUserSetupModal(userData) {
        return $uibModal.open({
            animation: false,
            keyboard: false,
            backdrop: 'static',
            windowClass: 'modal-fullscreen modal-dark',
            templateUrl: 'login/shared/user-setup.html',
            controller: 'UserSetupController as $ctrl',
            resolve: {
                userData: _.constant(userData),
            },
        }).result;
    }

    return {
        verifySetupInfo,
        showUserSetupModal,
    };
};
