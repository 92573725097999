export default class DistrictAdminHomeController {
    // @ngInject
    constructor(district, showGoogleInfo, adminService, districtModalFactory) {
        this.district = district;
        this.showGoogleInfo = showGoogleInfo;
        this.adminService = adminService;
        this.districtModalFactory = districtModalFactory;
    }

    regenerateCode() {
        return this.districtModalFactory
            .confirmDistrictCodeRegeneration()
            .then(() => this.adminService.regenerateDistrictCode(this.district.id))
            .then(district => {
                this.district.district_code = district.additional_data.district_code;
            });
    }
}
