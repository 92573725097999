export default function passwordMatchDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            compareTo: '=passwordMatch',
        },
        link: function(scope, elem, attr, ngModel) {
            ngModel.$validators.passwordMatch = passwordConfirm => {
                return passwordConfirm == scope.compareTo;
            };
        },
    };
}
