import _ from 'lodash';

class CreateAdminAccountSuccessController {
    // @ngInject
    constructor($stateParams, group) {
        this.groupType = group.type;
        this.label = `${this.groupType} administrator`;
        this.data = _.assign(
            {},
            _.pick($stateParams, ['email', 'password', `${this.groupType}Id`]),
            {
                firstName: $stateParams.first_name,
                lastName: $stateParams.last_name,
            }
        );
        this.bodyCopy = `Please share the password with the new ${this
            .label}. The user can change the password when he or she logs into Revision Assistant for the first time. You may view or edit ${$stateParams.first_name}’s account at any time.`;
    }
}

export default CreateAdminAccountSuccessController;
