import React from 'react';
import ReactDOM from 'react-dom';
import { DatePicker } from 'frontend-ra-shared';
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';

class TiDateController {
    // @ngInject
    constructor($scope, $element) {
        this.$scope = $scope;
        this.$element = $element;
        this.minDate = $scope.minDate || null;
        this.maxDate = $scope.maxDate || null;

        // allows programatically clearing the date by setting ng-model specifically to `null`
        // basically, if the bound date changes to null we clear the date fields
        $scope.$watch(
            () => this.ngModel.$modelValue,
            newVal => {
                this.isValid = true;
                this.internalDate = newVal;
                this.update();
            }
        );

        $scope.$watch(
            () => this.internalDate,
            () => {
                if (this.internalDate) {
                    this.isValid = true;
                    this.update();
                }
            },
            true
        );
    }

    setUpNgModel(ngModel) {
        this.ngModel = ngModel;
        this.ngModel.$validators.tiDate = () => this.isValid;
    }

    update() {
        if (this.isValid) {
            this.ngModel.$setViewValue(this.internalDate);
        } else {
            this.ngModel.$validate();
            this.ngModel.$setViewValue(null);
        }
    }

    onDateChange(date) {
        this.isValid = true;
        this.internalDate = date;
        this.update();
    }

    onInvalid() {
        this.isValid = false;
        this.internalDate = null;
        this.update();
    }

    render(element) {
        ReactDOM.render(
            <DatePicker
                initialDate={this.internalDate == '' ? null : this.internalDate}
                minDate={this.minDate}
                maxDate={this.maxDate}
                onChange={this.onDateChange.bind(this)}
                onInvalid={this.onInvalid.bind(this)}
            />,
            element[0]
        );
    }
}

// @ngInject
function tiDate($timeout) {
    return {
        require: 'ngModel',
        scope: {
            minDate: '=',
            maxDate: '=',
        },
        controller: TiDateController,
        controllerAs: 'tiDateController',
        priority: 0,
        link: function(scope, element, attrs, ngModel) {
            scope.tiDateController.setUpNgModel(ngModel);

            // We need to allow one digest loop to pull the current
            // value from ngModel. Without this, `initialDate` will
            // always be null on the initial render.
            //
            // $onInit does not work for the render because it fires
            // before link.
            $timeout(() => {
                scope.tiDateController.render(element);
            });
        },
    };
}

const tiDateModule = angular.module('tiDate', [uiBootstrap]);
tiDateModule.directive('tiDate', tiDate);
export default tiDateModule.name;
