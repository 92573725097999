export const POLLING_CANCELLED = 'cancelled';
export const POLLING_COUNT_EXCEEDED = 'count-exceeded';
export const POLLING_FORBIDDEN = 'forbidden';

// @ngInject
export default function paymentService(urls, $http, $log, $q, $timeout) {
    const baseURL = `${urls.base}`;

    const getExpirationPendingModalName = () => {
        const url = `${baseURL}/ra/admin/orders/tt-expiration-modal`;
        return $q((resolve, reject) => {
            $http.get(url).then(
                successResponse => {
                    $log.debug('<paymentService.getExpirationPendingModal> received modal name');
                    resolve(successResponse.data.modal);
                },
                errorResponse => {
                    $log.debug(
                        '<paymentService.getExpirationPendingModal> error fetching modal name',
                        errorResponse
                    );
                    reject(null);
                }
            );
        });
    };

    const dismissExpirationPendingModal = modalName => {
        const url = `${baseURL}/ra/admin/orders/tt-expiration-modal`;
        return $q((resolve, reject) => {
            const data = { modal_name: modalName };
            $http.post(url, data).then(
                successResponse => {
                    $log.debug(
                        '<paymentService.dismissExpirationPendingModal> modal was dismissed'
                    );
                    resolve(successResponse);
                },
                errorResponse => {
                    $log.debug(
                        '<paymentService.dismissExpirationPendingModal> error dismissing modal'
                    );
                    reject(errorResponse);
                }
            );
        });
    };

    const getSalesContact = () => {
        return $q.resolve('ratrials@turnitin.com');
    };

    const getStripeCheckoutInformation = () => {
        const url = `${baseURL}/ra/admin/orders/tt-stripe-information`;
        return $q((resolve, reject) => {
            $http.get(url).then(
                successResponse => {
                    $log.debug('<paymentService.getStripeCheckoutInformation> success');
                    resolve(successResponse.data);
                },
                errorResponse => {
                    $log.debug('<paymentService.getStripeCheckoutInformation> error');
                    reject(errorResponse);
                }
            );
        });
    };

    const submitPayment = data => {
        const url = `${baseURL}/ra/admin/orders`;
        return $q((resolve, reject) => {
            $http.post(url, data).then(
                successResponse => {
                    $log.debug('<paymentService.submitPayment> payment submitted');
                    resolve(successResponse.data.order_number);
                },
                errorResponse => {
                    $log.debug('<paymentService.submitPayment> error encountered');
                    reject(errorResponse);
                }
            );
        });
    };

    const checkOrderStatus = orderNumber => {
        const url = `${baseURL}/ra/admin/orders/${orderNumber}`;
        return $http.get(url);
    };

    // Doug helped Kevin write this code so he probably has better insight into it.
    const pollOrderStatus = orderNumber => {
        const minDelayInterval = 1000;
        const maxPollCount = 10;
        let cancel;

        const result = $q((resolve, reject) => {
            let cancelOnNextTick = false;

            cancel = () => {
                cancelOnNextTick = true;
                reject({ type: POLLING_CANCELLED });
            };

            const poller = count => {
                if (count >= maxPollCount) {
                    return reject({ type: POLLING_COUNT_EXCEEDED });
                }

                const request = checkOrderStatus(orderNumber);
                const minDelay = $timeout(minDelayInterval);

                $q.all([request, minDelay]).then(
                    ([successResponse]) => {
                        if (cancelOnNextTick) {
                            return;
                        }

                        if (successResponse.status !== 200) {
                            return poller(count + 1);
                        }

                        return resolve(successResponse.data);
                    },
                    errorResponse => {
                        $log.debug(errorResponse);
                        return reject({ type: POLLING_FORBIDDEN });
                    }
                );
            };

            poller(0);
        });

        return {
            result,
            cancel,
        };
    };

    return {
        getExpirationPendingModalName,
        dismissExpirationPendingModal,
        getSalesContact,
        getStripeCheckoutInformation,
        submitPayment,
        checkOrderStatus,
        pollOrderStatus,
    };
}
