// @ngInject
export default function loginRoutes($stateProvider) {
    $stateProvider.state('terms-of-service', {
        url: '/terms-of-service',
        templateUrl: 'terms-of-service/terms-of-service.html',
        data: {
            isPublic: true,
        },
    });
}
