import angular from 'angular';
import uiRouter from 'angular-ui-router';
import EventMediator from 'gong-event-mediator';

// TODO: Remove the login tight coupling. See notes on header directive.
import loginShared from '../login/shared/shared';
import urlConfig from '../url-config/url-config';
import user from '../user/user';
import provider from '../provider/provider';
import formUtils from '../form-utils/form-utils';
import onboarding from '../onboarding/onboarding';
import adminSidenav from '../admin/admin-sidenav/admin-sidenav';

import headerDirective from './header-directive';
import roleDrawer from '../role-drawer/role-drawer';
import relaunchOnboardingTooltipComponent from './relaunch-onboarding-tooltip-component.js';

const header = angular.module('header', [
    user,
    uiRouter,
    loginShared,
    urlConfig,
    roleDrawer,
    EventMediator,
    provider,
    formUtils,
    onboarding,
    adminSidenav,
]);

header.directive('appHeader', headerDirective);
header.component('relaunchOnboardingTooltip', relaunchOnboardingTooltipComponent);

export default header.name;
