/**
 * Provides a UI to generate a district usage report.
 * There are two categories of admins that will access this page.
 * 1. Admins with multiple districts
 *     - Implementation Specialists across multiple districts
 *     - District Admins across multiple districts
 * 2. Admins with a single district
 *     - District admins with 1 district access
 *     - Single school admins
 *     - Multi school admins
 * Users in group 1 are given a dropdown to select a district.
 * Users in group 2 will NOT see a dropdown and the form will render immediately.
 */
export default class UsageReportController {
    /* @ngInject */
    constructor(
        user,
        eventMediator,
        usageReportFactory,
        usageReportDownloadFactory,
        usageReportFormHelper,
        interactionShieldService,
        availableDistricts
    ) {
        this.user = user;
        this.eventMediator = eventMediator;
        this.usageReportFactory = usageReportFactory;
        this.usageReportDownloadFactory = usageReportDownloadFactory;
        this.usageReportFormHelper = usageReportFormHelper;
        this.interactionShieldService = interactionShieldService;
        this.availableDistricts = availableDistricts;

        this.pageTitle = user.isSchoolAdministrator() ? 'School Reports' : 'District Reports';

        this.selectedDistrictId = null;
        this.isDistrictSelectVisible = _.size(availableDistricts) > 1;

        // if the user only has 1 district they will NOT see the district select
        // and we'll render the form for them for that district.
        if (!this.isDistrictSelectVisible) {
            const district = _.head(availableDistricts);
            this.selectedDistrictId = district.id;
            usageReportFactory
                .fetchUsageReportParams(district.id, user)
                .then(this.renderReport.bind(this));
        }
    }

    renderReport(reportParams) {
        this.reportParams = reportParams;

        this.usageReportFormModel = this.usageReportFormHelper.createModel(this.user, reportParams);
    }

    handleSelectedDistrictChange() {
        if (!this.selectedDistrictId) {
            return (this.usageReportFormModel = null);
        }

        return this.fetchReportParamsAndRender(this.selectedDistrictId);
    }

    fetchReportParamsAndRender(districtId) {
        return this.usageReportFactory
            .fetchUsageReportParams(districtId, this.user)
            .then(this.renderReport.bind(this));
    }

    handleFormReset() {
        this.usageReportFormModel = this.usageReportFormHelper.createModel(
            this.user,
            this.reportParams
        );
    }

    handleFormSubmit(model) {
        const { model: newModel, isValid } = this.usageReportFormHelper.validate(
            model,
            this.usageReportFormModel
        );
        this.usageReportFormModel = newModel;

        if (isValid) {
            this.interactionShieldService.startLoading({ isSemiTransparent: true });
            return this.usageReportDownloadFactory
                .download(this.selectedDistrictId, model)
                .catch(err => this.emitReportDownloadError(err.message))
                .finally(() => this.interactionShieldService.finishLoading());
        }
    }

    emitReportDownloadError(message) {
        this.eventMediator.emit('messageNotification', {
            type: 'danger',
            message,
        });
    }
}
