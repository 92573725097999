import _ from 'lodash';
import changeCase from 'change-case-object';

import { signInStatus } from '../login-constants';
import { isExpiredTeacherTrialUser } from '../../payment/payment-authorization';

// TODO: This amount of parameters is usually a code smell. Refactor when the authentication logic is figured out.
export default function authService(
    $http,
    urls,
    $q,
    user,
    $state,
    loginModalFactory,
    $log,
    $location,
    $sessionStorage,
    $rootScope,
    interactionShieldService,
    eventMediator,
    ravenService
) {
    'ngInject';

    const setRoleFromQuery = () => {
        const queryData = $location.search();
        const role = _.get(queryData, 'role', undefined);
        if (!role) return;

        interactionShieldService.raiseTransitionShield();
        user.setCurrentRole(role);
        interactionShieldService.lowerTransitionShield();
    };

    const performLoginPostActions = user => {
        const userData = user.getUser();

        if (!userData.agreed_to_eula) {
            return loginModalFactory
                .eulaPrompt()
                .then(() => ($sessionStorage.token = user.getToken()));
        } else if (userData.change_password) {
            return loginModalFactory
                .changePasswordPrompt()
                .then(() => ($sessionStorage.token = user.getToken()));
        } else {
            return $q.when(() => ($sessionStorage.token = user.getToken()));
        }
    };
    this.performLoginPostActions = performLoginPostActions;

    const processSuccessfulLogin = userData => {
        user.hydrate(userData);
        $sessionStorage.token = user.getToken();
        ravenService.setUser(user.getUser());
        setRoleFromQuery();
        return user;
    };

    const handleSuccessfulLogin = userData => {
        user = processSuccessfulLogin(userData);

        eventMediator.emit('login', { user });

        return performLoginPostActions(user).then(() => {
            return userData;
        });
    };

    const authenticate = (url, data) => {
        return $http({ url, method: 'POST', data })
            .then(res => {
                $log.debug('<authService> login resolved', res.data);
                return handleSuccessfulLogin(res.data);
            })
            .then(() => user);
    };

    /**
     * Takes the response of an authentication request OR a user setup request
     * and checks to see if the user has any remaining setup.
     * Right now, this is only implemented by SSO but we'd like to extend this regular sign in.
     * If the user requires further setup, you'll get back a type of `signInStatus.requiresSetup`.
     * Otherwise, the user will be passed through to the auth service and you'll get back
     * a type of `signInStatus.authenticated`.
     */
    const validateAuthResponse = _.curry(response => {
        const userData = response.data;
        const remainingSetup = _.get(userData, 'remaining_setup', []);
        if (!_.isEmpty(remainingSetup)) {
            return $q.resolve({
                type: signInStatus.requiresSetup,
                userData: changeCase.camelCase(userData),
            });
        }

        return handleSuccessfulLogin(userData).then(() => ({
            type: signInStatus.authenticated,
            user,
        }));
    });

    this.handleSuccessfulLogin = handleSuccessfulLogin;
    this.validateAuthResponse = validateAuthResponse;

    this.login = (identifier, password) => {
        const url = `${urls.base}/ra/identity/login`;
        return authenticate(url, { identifier, password }).then(() => user);
    };

    this.loginFromSacToken = sac_token => {
        if (user.isAuthenticated()) return $q.resolve(user);

        const url = `${urls.base}/ra/identity/by_sac_token`;
        return authenticate(url, { sac_token })
            .catch(res => $q.reject(_.get(res, 'data.errors')))
            .then(() => user);
    };

    this.clearStorage = () => {
        $sessionStorage.$reset();
    };

    this.logOut = () => {
        eventMediator.emit('logout');
        $sessionStorage.$reset();
        user.logout();
        ravenService.unsetUser();
    };

    this.logOutAndRedirect = () => {
        this.logOut();
        $state.go('login');
    };

    this.isSession = () => {
        return !!$sessionStorage.token;
    };

    this.refreshAuthentication = () => {
        const login_token = $sessionStorage.token;
        if (!login_token) return $q.reject('User cannot be authenticated');

        return authenticate(`${urls.base}/ra/identity/by_token`, { login_token })
            .catch(res => $q.reject(_.get(res, 'data.errors')))
            .then(() => user.getUser());
    };

    // TODO: This method has too much logic.
    this.handleForbiddenStatus = (toState, toParams) => {
        if (user.isAuthenticated()) return $q.reject();

        const login_token = $sessionStorage.token;
        if (!login_token) {
            return $state.go('login').then(() => $q.reject());
        }

        return authenticate(`${urls.base}/ra/identity/by_token`, { login_token })
            .catch(res => $q.reject(_.get(res, 'data.errors')))
            .then(() => this.redirect({ toState, toParams }));
    };

    this.redirect = ({ toState, toParams } = {}) => {
        if (!user.isAuthenticated()) {
            return this.handleForbiddenStatus(toState, toParams);
        }

        let reload = true;

        if (isExpiredTeacherTrialUser(user)) {
            if (user.isLearner()) {
                const message =
                    'Your trial is up! Boo! Ask your teacher to subscibe to Revision Assistant.';
                eventMediator.emit('messageNotification', { type: 'danger', message });
                $sessionStorage.$reset();
                toState = 'login';
                toParams = {};
                reload = false;
            } else {
                toState = 'payment';
                toParams = { trialExpired: true };
            }
        }

        if (!toState && !toParams) {
            const homeState = user.getHomeState();
            toParams = homeState.toParams;
            toState = homeState.toState;
        }

        return $state.go(toState, toParams, { reload: reload });
    };

    this.changePassword = (password, email, reset_token) => {
        return $http({
            url: `${urls.base}/ra/identity/password_reset`,
            method: 'POST',
            data: { password, email, reset_token },
        }).then(res => processSuccessfulLogin(res.data));
    };

    this.changeUserPassword = password => {
        return $http({
            url: `${urls.base}/ra/identity/password`,
            method: 'PUT',
            data: password,
        }).then(res => {
            $log.debug('<AuthService> changeUserPassword');
            $log.debug(res);
            return res.data;
        });
    };
}
