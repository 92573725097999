import angular from 'angular';
import uiRouter from 'angular-ui-router';
import urlConfig from '../url-config/url-config';
import EventMediator from 'gong-event-mediator';

import adminRoutes from './admin-routes';
import adminSidenav from './admin-sidenav/admin-sidenav';
import adminHome from './home/home';
import usageReport from './usage-report/usage-report';
import adminShared from './shared/shared';
import login from '../login/login';
import school from './school/school';
import bulkUpload from './bulk-upload/bulk-upload';
import hightlightTerm from '../components/highlight-term/highlight-term';

const admin = angular.module('admin', [
    uiRouter,
    urlConfig,
    EventMediator,
    adminSidenav,
    adminHome,
    usageReport,
    adminShared,
    login,
    school,
    bulkUpload,
    hightlightTerm,
]);
admin.config(adminRoutes);

export default admin.name;
