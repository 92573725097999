import angular from 'angular';

import textUtils from '../../../text-utils/text-utils';
import bulkUpload from '../../bulk-upload/bulk-upload';

import SchoolDashboardController from './school-dashboard-controller';
import studentLicensesBar from '../../student-licenses-bar/student-licenses-bar';
import googleSsoBar from '../../google-sso-bar/google-sso-bar';

const schoolDashboard = angular.module('schoolDashboard', [
    textUtils,
    bulkUpload,
    studentLicensesBar,
    googleSsoBar,
]);
schoolDashboard.controller('SchoolDashboardController', SchoolDashboardController);

export default schoolDashboard.name;
