//remotes
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import urlConfig from '../url-config/url-config';
import EventMediator from 'gong-event-mediator';
//locals
import demoShared from './shared/shared';

//module
const demo = angular.module('demo', [uiRouter, urlConfig, demoShared, EventMediator]);

//export
export default demo.name;
