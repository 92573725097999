import _ from 'lodash';

const ACCOUNT_LIMIT = 5000;

let niceMessages = {
    'cannot load file': _.constant('Unable to load file'),
    'no identities': _.constant(
        'Oops! The uploaded file contains no data. Please fix and try again.'
    ),
    'too many identities': args => {
        return (
            `Oops! This spreadsheet has more than ${args.limit} accounts. ` +
            'Break it up into multiple files and upload each separately.'
        );
    },
    'select a file': _.constant('You must select at least one file for upload.'),
    unknown: _.constant('An error occurred. Please contact a customer support specialist.'),
    'maximum status polling timeout reached': _.constant(
        'This spreadsheet is taking a while to upload.'
    ),
};

const SHEET_INDEX = 0; // Data must be on first sheet. (zero-based index)

function getNiceMessageForError(error) {
    const messageFn = _.get(niceMessages, [error.messageKey], _.constant(false));
    return messageFn(error.args);
}

export default function spreadsheetUploaderFactory($q, numberFilter, spreadsheetFactory) {
    'ngInject';

    function getAccountLimit() {
        return ACCOUNT_LIMIT;
    }

    function workbookToJson(filename, schoolId, workbook) {
        const sheetData = spreadsheetFactory.workbookToJson(workbook, [SHEET_INDEX])[SHEET_INDEX];

        let clientData = {
            columns: sheetData.columns,
            identities: sheetData.rowData,
        };

        return {
            filename,
            group_id: schoolId,
            client_data: clientData,
        };
    }

    function standardizeErrorFormat(errors = [{ messageKey: 'unknown' }]) {
        const matched = _(errors)
            .compact()
            .map(e => {
                let uiMessage =
                    e.uiMessage ||
                    getNiceMessageForError(e) ||
                    e.detail ||
                    niceMessages.unknown(e.args);
                return _.assign({}, e, { uiMessage });
            })
            .uniq()
            .value();

        return { errors: matched };
    }

    function processWorkbook(filename, schoolId, workbook) {
        let uploadData = workbookToJson(filename, schoolId, workbook);
        let identities = uploadData.client_data.identities || [];

        let errors = [];
        if (identities.length == 0) {
            errors.push({ messageKey: 'no identities' });
        } else if (identities.length > ACCOUNT_LIMIT) {
            const uploaded = numberFilter(identities.length);
            const limit = numberFilter(ACCOUNT_LIMIT);
            errors.push({ messageKey: 'too many identities', args: { uploaded, limit } });
        }

        return $q((resolve, reject) => {
            return errors.length > 0 ? reject(errors) : resolve(uploadData);
        });
    }

    return {
        getAccountLimit,
        standardizeErrorFormat,
        processWorkbook,
        loadFileForUpload(fileData, filename, schoolId) {
            return spreadsheetFactory
                .loadFileAsWorkbook(fileData)
                .catch(browserEvent =>
                    $q.reject([{ rawError: browserEvent, messageKey: 'cannot load file' }])
                )
                .then(workbook => this.processWorkbook(filename, schoolId, workbook))
                .catch(errors => $q.reject(standardizeErrorFormat(errors)));
        },
    };
}
