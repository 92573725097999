import angular from 'angular';
import uiRouter from 'angular-ui-router';

import user from '../../user/user';
import userAccount from '../../user-account/user-account';

import StudentLicensesBarComponent from './student-licenses-bar-component';

const studentLicensesBar = angular.module('studentLicensesBar', [uiRouter, user, userAccount]);
studentLicensesBar.component('studentLicensesBar', StudentLicensesBarComponent);

export default studentLicensesBar.name;
