import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { PromotionBanners } from 'frontend-ra-shared';

class PromotionBannersController {
    // @ngInject
    constructor(promotionsFactory, $sce, $element) {
        this.promotionsFactory = promotionsFactory;
        this.$element = $element;
    }

    $onInit() {
        this.promotions = this.promotionsFactory.getPromotions(this.groupIds);
    }

    handleDismissPromotion(promoName) {
        this.promotionsFactory.markPromotionDismissed(promoName);
        this.promotions = this.promotionsFactory.getPromotions(this.groupIds);
        this.renderReact();
    }

    renderReact() {
        const component = (
            <IntlProvider locale="en">
                <PromotionBanners
                    sourceImgDir="/images/ra-shared"
                    onDismissPromotion={promoName => this.handleDismissPromotion(promoName)}
                    promotions={this.promotions}
                    assignmentTypes={this.assignmentTypes}
                />
            </IntlProvider>
        );
        ReactDOM.render(component, this.$element[0]);
    }

    $postLink() {
        this.renderReact();
    }
}

export default {
    controller: PromotionBannersController,
    template: '<div class="promotion-banners"></div>',
    bindings: {
        groupIds: '<',
        assignmentTypes: '<',
    },
    controllerAs: '$ctrl',
};
