import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'frontend-ra-shared';

class TooltipController {
    /* @ngInject */
    constructor($scope, $element, $timeout, $document) {
        this.$scope = $scope;
        this.$element = $element;
        this.$timeout = $timeout;
        this.$document = $document;

        $scope.$watch('$ctrl.isOpen', this.handleIsOpenChange.bind(this));
    }

    handleIsOpenChange() {
        return this.isOpen ? this.renderTooltip() : this.closeTooltip();
    }

    getReactAttachPoint() {
        return this.$element[0].querySelector('.tooltip-react-target');
    }

    getTarget() {
        return this.target
            ? this.$document[0].querySelector(this.target)
            : this.$element[0].querySelector('.tooltip-component-target');
    }

    toggleTooltip(toggleVal) {
        const isOpen = _.isUndefined(toggleVal) ? !this.isOpen : toggleVal;

        if (!isOpen && _.isFunction(this.onClose)) this.onClose();

        // force a digest since this is sometimes called from React
        this.$timeout(() => {
            this.isOpen = isOpen;
        });
    }

    closeTooltip() {
        ReactDOM.unmountComponentAtNode(this.getReactAttachPoint());
    }

    renderTooltip() {
        ReactDOM.render(
            <div>
                <Tooltip
                    id="ra-tooltip-root"
                    className="ra-tooltip"
                    getTarget={this.getTarget.bind(this)}
                    getPlacement={_.constant(this.placement)}
                    offset={this.offset || 10}
                >
                    <div className="tooltip-header">
                        <button
                            className="close-button"
                            onClick={this.toggleTooltip.bind(this, false)}
                        />
                    </div>
                    <h2 className="tooltip-title">{this.title}</h2>
                    <div
                        className="tooltip-content"
                        dangerouslySetInnerHTML={{ __html: this.body }}
                    />
                    <button
                        className="btn btn-compact btn-primary tooltip-ok"
                        onClick={this.toggleTooltip.bind(this, false)}
                    >
                        OK
                    </button>
                </Tooltip>
            </div>,
            this.getReactAttachPoint()
        );
    }
}

export default {
    transclude: true,
    template: `
        <span>
            <a ng-click="$ctrl.toggleTooltip()">
                <span class="tooltip-component-target">
                    <ng-transclude></ng-transclude>
                </span>
            </a>
            <span class="tooltip-react-target"></span>
        </span>`,
    controller: TooltipController,
    bindings: {
        title: '@',
        body: '@',
        placement: '@',
        target: '<',
        offset: '<',
        isOpen: '<',
        onClose: '&',
    },
};
