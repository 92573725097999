import _ from 'lodash';
import { isCurrentTeacherTrialUser } from '../../../payment/payment-authorization';

export default class SchoolDashboardController {
    // @ngInject
    constructor(
        $q,
        $state,
        $window,
        eventMediator,
        urls,
        bulkDeletionModalFactory,
        school,
        bulkUploadService,
        isBulkUploadV2Available,
        ordersV2,
        showGoogleInfo,
        fteLimitReached,
        user
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$window = $window;
        this.eventMediator = eventMediator;
        this.urls = urls;
        this.bulkDeletionModalFactory = bulkDeletionModalFactory;
        this.school = school;
        this.bulkUploadService = bulkUploadService;
        this.isBulkUploadAvailable = isBulkUploadV2Available;
        this.bulkUploadOrders = ordersV2;
        this.isTeacherTrial = isCurrentTeacherTrialUser(user);
        this._showGoogleInfo = showGoogleInfo;
        this.fteLimitReached = fteLimitReached;
    }

    showGoogleInfo() {
        return !!(this._showGoogleInfo && this.districtCode());
    }

    isDeletable(order) {
        return order.canBeDeleted;
    }

    deleteOrderV2(order) {
        return this.bulkDeletionModalFactory
            .confirmAccountDeletion(order.recordsCreatedCount)
            .then(() => {
                return this.bulkUploadService
                    .deleteBulkUploadOrder(order.code)
                    .then(() => {
                        const message = 'Removing these accounts. This may take a few minutes.';
                        let args = { type: 'success', message, postStateChange: true };
                        this.eventMediator.emit('messageNotification', args);
                        this.$state.reload();
                    })
                    .catch(errors => {
                        if (errors.length && errors[0].detail.match(/already logged in/)) {
                            return this.bulkDeletionModalFactory
                                .alertUnableToDeleteAccounts(order.uploadType)
                                .finally(() => {
                                    this.$state.reload();
                                    return this.$q.reject();
                                });
                        } else {
                            let args = {
                                type: 'danger',
                                message: 'An error occurred.',
                                postStateChange: true,
                            };
                            this.eventMediator.emit('messageNotification', args);
                            this.$state.reload();
                            return this.$q.reject();
                        }
                    });
            });
    }

    districtName() {
        return _.get(this.school, 'parent.name');
    }

    districtCode() {
        return _.get(this.school, 'parent.district_code');
    }

    handleViewDetail(order) {
        const params = { orderId: order.id };
        const routeName =
            order.uploadType === 'learner'
                ? 'admin.school.students.bulk-upload-order.details'
                : 'admin.school.teachers.bulk-upload-order.details';
        return this.$state.go(routeName, params);
    }

    handleContactSupport() {
        this.$window.open(this.urls.support);
    }
}
