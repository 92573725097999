import _ from 'lodash';

const MODES = {
    READ: 'READ',
    UPDATE: 'WRITE',
};

export default class TeacherClassEditController {
    // @ngInject
    constructor(
        $stateParams,
        eventMediator,
        TeacherClass,
        utils,
        user,
        teacherService,
        notifyDataLossService,
        $scope
    ) {
        this.$stateParams = $stateParams;
        this.eventMediator = eventMediator;
        this.teacherClassOrig = _.cloneDeep(TeacherClass); //backup copy for cancel
        this.teacherClass = TeacherClass;
        this.gradeOptions = utils.classGradeOptions;
        this.subjectOptions = utils.classSubjectOptions;
        this.notifyDataLossService = notifyDataLossService;
        this.$scope = $scope;

        this.otherText = {};
        this.setMode(MODES.READ);
        this.schools = user.getMultipleMemberships('Instructor');
        this.teacherService = teacherService;
    }

    emitMessage(type, message) {
        this.eventMediator.emit('messageNotification', {
            type,
            message,
        });
    }

    resolve() {
        this.emitMessage('success', 'Your Class Settings were updated!');
        this.setMode(MODES.READ);
    }

    isOther(value) {
        return value === 'Other';
    }

    setMode(mode) {
        if (mode === MODES.READ) {
            if (this.dataComparator) {
                this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);
            }

            if (this.isOther(this.teacherClass.grade)) {
                this.teacherClass.grade = this.otherText.grade;
            }

            if (this.isOther(this.teacherClass.subject)) {
                this.teacherClass.subject = this.otherText.subject;
            }
        } else if (mode === MODES.UPDATE) {
            this.dataComparator = this.notifyDataLossService.anticipateDataLoss(
                this.$scope,
                this,
                'teacherClass'
            );

            if (
                this.isOther(this.teacherClass.grade) ||
                !_.includes(this.gradeOptions, this.teacherClass.grade)
            ) {
                this.otherText.grade = _.cloneDeep(this.teacherClass.grade);
                this.teacherClass.grade = 'Other';
            }

            if (
                this.isOther(this.teacherClass.subject) ||
                !_.includes(this.subjectOptions, this.teacherClass.subject)
            ) {
                this.otherText.subject = _.cloneDeep(this.teacherClass.subject);
                this.teacherClass.subject = 'Other';
            }
        }

        this.mode = mode;
    }

    cancel() {
        this.setMode(MODES.READ);
        this.teacherClass = _.cloneDeep(this.teacherClassOrig);
    }

    save() {
        if (this.mode === MODES.UPDATE && this.form.$valid) {
            if (this.isOther(this.teacherClass.grade)) {
                this.teacherClass.grade = this.otherText.grade;
            }

            if (this.isOther(this.teacherClass.subject)) {
                this.teacherClass.subject = this.otherText.subject;
            }

            this.teacherService
                .updateClassById(this.$stateParams.classId, this.teacherClass)
                .then(this.resolve.bind(this))
                .catch(() => {
                    this.emitMessage('danger', 'Your class could not be created.');
                    this.form.showErrors = true;
                });
        } else {
            this.emitMessage('danger', 'Please complete all required fields.');
            this.form.showErrors = true;
        }
    }

    getCurrentSchoolName() {
        return _.find(this.schools, school => school.id == this.teacherClass.school_id).name;
    }
}
