import Raven from 'raven-js';
import _ from 'lodash';

export default function($log, $injector) {
    'ngInject';

    const service = this;

    service.Raven = Raven;

    service.getConfigUrl = function() {
        let config;

        if (__PRODUCTION__) {
            config = 'https://4a6052b983fb46e283a4a527d14467fe@app.getsentry.com/70107';
        }

        if (__STAGING__) {
            config = 'https://75a3ed545de1491993b06a9da535cfc2@app.getsentry.com/70109';
        }

        return config;
    };

    service.getConfigData = function() {
        return { release: __APP_CONSUMER_VERSION__ };
    };

    service.init = function() {
        const configUrl = service.getConfigUrl();
        if (configUrl) {
            Raven.config(service.getConfigUrl(), service.getConfigData())
                .addPlugin(require('raven-js/plugins/angular'), angular)
                .install();
        }
    };
    service.init();

    let user = null;
    // methods
    // setup: map User data to Raven user object
    service.setUser = function(userData) {
        if (userData) {
            user = _.cloneDeep(userData);
            user.name = `${user.first_name} ${user.last_name}`;
        }

        return Raven.setUser(user);
    };

    service.unsetUser = () => (user = null);

    // helper: return Raven tags for current $state
    service.getTags = function() {
        const $state = $injector.get('$state');

        const tags = {
            page: $state.current.name,
        };

        return tags;
    };

    // wrappers
    service.captureException = function(e, sentryMessageOptions) {
        // Note: sentryMessageOptions are documented here:
        // https://docs.sentry.io/clients/javascript/usage
        // Arbitrary data can be passed in via an 'extra' property.

        $log.debug('ravenService.captureException');
        service.setUser();

        Raven.captureException(e, sentryMessageOptions);
    };

    service.captureMessage = function(msg, sentryMessageOptions) {
        // Note: sentryMessageOptions are documented here:
        // https://docs.sentry.io/clients/javascript/usage
        // Arbitrary data can be passed in via an 'extra' property.

        $log.debug('ravenService.captureMessage');
        service.setUser();
        const message = msg || 'Default';
        const resultObj = sentryMessageOptions || { tags: service.getTags() };

        $log.debug(message);
        $log.debug(resultObj);
        Raven.captureMessage(message, resultObj);
    };

    service.captureFormException = function(e, $ctrl, promiseErrors, user) {
        function isKnownSensetiveField(_value, key) {
            // SECURITY WARNING!!!
            // For any form that this logic is used on, you MUST verify
            // that this list is exhaustive, AND keep it up-to-date.
            // This is a "blacklist" approach, which is generally BAD PRACTICE,
            // but since we have a lot of open questions on this error,
            // we are trying to capture as much info as possible,
            // and just omit the stuff we know to be a security/personal info leak.
            const sensetiveFields = ['^\\$', 'name', 'username', 'email', 'password', 'confirm'];
            return key.toLowerCase().search(new RegExp(sensetiveFields.join('|'))) >= 0;
        }

        const $state = $injector.get('$state');

        const loggableData = {
            // Be careful not to expose security and personal info!
            promiseErrors: _.map(promiseErrors || [], 'detail'),
            state: { current: _.get($state, 'current') },
            userHomeState: user ? user.getHomeState() : null, // DO NOT expose detailed user info
            form: $ctrl.form ? _.omitBy($ctrl.form, isKnownSensetiveField) : $ctrl.form,
        };

        this.captureException(e, { extra: { $ctrl: loggableData } });
    };
}
