import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { TeacherOnboardingCarousel } from 'frontend-ra-shared';

class TeacherOnboardingCarouselController {
    // @ngInject
    constructor($element, $scope, $timeout, user) {
        this.$element = $element;
        this.$scope = $scope;
        this.$timeout = $timeout;

        this.showAdminSlide = user.hasAdministratorRole();
    }

    renderReact() {
        const sourceImageDirs = {
            carouselDir: '/images/ra-shared',
            iconDir: '/images/ra-shared',
        };
        // TODO put IntlProvider somewhere else?
        const component = (
            <IntlProvider locale="en">
                <TeacherOnboardingCarousel
                    onDone={() => {
                        this.onDone();
                        this.$timeout(() => this.$scope.$digest());
                    }}
                    onViewLater={() => {
                        this.onViewLater();
                        this.$timeout(() => this.$scope.$digest());
                    }}
                    sourceImgDirs={sourceImageDirs}
                    isModal
                    showAdminSlide={this.showAdminSlide}
                />
            </IntlProvider>
        );
        ReactDOM.render(component, this.$element[0]);
    }

    $postLink() {
        this.renderReact();
    }
}

export default {
    controller: TeacherOnboardingCarouselController,
    template: '<div class="teacher-onboarding-carousel"></div>',
    bindings: {
        onViewLater: '&',
        onDone: '&',
    },
};
