import angular from 'angular';
import uiRouter from 'angular-ui-router';

import providerMod from '../../provider/provider';
import user from '../../user/user';

import ArchivedClassesController from './archived-classes-controller';

const archivedClasses = angular.module('archivedClasses', [uiRouter, providerMod, user]);
archivedClasses.controller('ArchivedClassesController', ArchivedClassesController);

export default archivedClasses.name;
