function ApiActionDirective(urls, $http, $state) {
    'ngInject';

    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            // {
            //   url, (sans urls.base)
            //   method,
            //   data
            // }

            scope.data = angular.fromJson(attrs.apiAction);

            scope.getTransformedData = function() {
                let tempData = angular.copy(scope.data);

                tempData.url = urls.base + tempData.url;
                tempData.method = tempData.method.toUpperCase();

                return tempData;
            };

            scope.doAction = function() {
                $http(scope.getTransformedData()).then(() => {
                    $state.go($state.current, {}, { reload: true });
                });
            };

            element.bind('click', scope.doAction);
        },
    };
}

export default ApiActionDirective;
