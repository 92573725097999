import _ from 'lodash';

export default function specialistSidenavStore($rootScope, eventMediator) {
    'ngInject';

    let specialistSidenavData = {};

    eventMediator.subscribe($rootScope, 'logout', () => {
        specialistSidenavData = {};
    });

    return {
        getSpecialistSidenavData() {
            return _.cloneDeep(specialistSidenavData);
        },
        updateSchool(school) {
            specialistSidenavData = _.assign({}, specialistSidenavData, { school });
        },
        updateDistrict(district) {
            specialistSidenavData = _.assign({}, specialistSidenavData, { district });
        },
    };
}
