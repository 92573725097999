import {
    INDIVIDUAL_TEACHER_ACCOUNT_USERS,
    PAYMENT_VIEW_ADMINISTRATORS,
    TEACHER_TRIAL_USERS,
} from './payment-permissions';

export const isCurrentTeacherTrialUser = user => {
    return (
        (user.hasGeneralPermission(TEACHER_TRIAL_USERS) ||
            user.hasGeneralPermission(INDIVIDUAL_TEACHER_ACCOUNT_USERS)) &&
        !user.hasExpiredLicense()
    );
};

export const isExpiredTeacherTrialUser = user => {
    if (user.hasGeneralPermission(TEACHER_TRIAL_USERS)) {
        return user.hasExpiredLicense();
    }
    return false;
};

export const isAuthorizedForTeacherTrialPayment = user => {
    const isAdmin = user.hasAdministratorRole();
    const hasPermission = user.hasGeneralPermission(PAYMENT_VIEW_ADMINISTRATORS);
    return isAdmin && hasPermission;
};
