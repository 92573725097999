import angular from 'angular';
import uiRouter from 'angular-ui-router';

import assignment from '../../assignment/assignment';
import provider from '../../provider/provider';
import textUtils from '../../text-utils/text-utils';

import StudentClassDirective from './student-class-directive';
import studentAssignmentTile from './student-assignment-tile-component';

const studentClass = angular.module('studentClass', [assignment, uiRouter, textUtils, provider]);
studentClass.directive('studentClass', StudentClassDirective);
studentClass.component('studentAssignmentTile', studentAssignmentTile);

export default studentClass.name;
