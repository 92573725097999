const emptyMessages = {
    all: 'There are currently no assignments for this class.',
    open: 'There are no open assignments for this class.',
    closed: 'There are no closed assignments for this class.',
};

class StudentClassController {
    // @ngInject
    constructor(assignmentFactory, providerService) {
        this.assignmentFactory = assignmentFactory;
        this.providerService = providerService;
        this.assignments = this.sortAssignments(this.raClass.assignments);
        this.selectedFilter = 'all';
        this.emptyMessage = emptyMessages[this.selectedFilter];
    }

    filterAssignments(state) {
        this.setEmptyMessage(state);
        this.assignments = this.assignmentFactory.filterAssignments(
            state,
            this.raClass.assignments
        );
    }

    setEmptyMessage(state) {
        this.emptyMessage = emptyMessages[state];
    }

    sortAssignments(assignments) {
        return this.assignmentFactory.sortAssignments(assignments);
    }

    launchAssignment(assignmentId) {
        this.providerService.launchAssignmentById(assignmentId, 'Learner');
    }
}

function StudentClassDirective() {
    return {
        templateUrl: 'student/student-class/student-class-directive.html',
        scope: {},
        bindToController: {
            raClass: '=',
        },
        controller: StudentClassController,
        controllerAs: 'studentClassController',
    };
}

export default StudentClassDirective;
