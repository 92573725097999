export default class CreateAccountsController {
    /* @ngInject */
    constructor(
        $q,
        $state,
        $stateParams,
        eventMediator,
        specialistService,
        spreadsheetUploaderFactory,
        school,
        order
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$stateParams = $stateParams;

        this.eventMediator = eventMediator;
        this.specialistService = specialistService;
        this.spreadsheetUploaderFactory = spreadsheetUploaderFactory;

        this.order = order;
        this.school = school;

        const changed = order.uploads.length > 1 ? 'Current Upload' : 'Original';
        const em = `<em class="change-indicator">${changed}</em>`;
        let mostRecentUpload = order.uploads[order.uploads.length - 1];
        this.filename = `${mostRecentUpload.filename} <span class="sidenote">- ${em}</span>`;
    }

    createWithNewUpload(file) {
        const orderCode = this.order.code;
        const schoolId = this.school.id;
        return this.spreadsheetUploaderFactory
            .loadFileForUpload(file, file.name, schoolId)
            .catch(errorResult => this.displayErrorNotifications(errorResult.errors))
            .then(uploadData => {
                return this.specialistService
                    .reuploadSpreadsheet(orderCode, uploadData)
                    .catch(rejectionData => this.displayErrorNotifications(rejectionData.errors));
            })
            .then(uploadResponse => {
                const uploadId = uploadResponse.id;
                return this.specialistService
                    .createAccounts(orderCode)
                    .catch(rejData =>
                        this._handleProcessingFailure(rejData, schoolId, orderCode, uploadId)
                    );
            })
            .then(() => this.$state.go('specialist.create-accounts.processing', { orderCode }));
    }

    processExistingUpload() {
        const orderCode = this.order.code;
        return this.specialistService
            .createAccounts(orderCode)
            .catch(data => this.displayErrorNotifications(data.errors))
            .then(() => this.$state.go('specialist.create-accounts.processing', { orderCode }));
    }

    _isValidationFailure(error) {
        return _.get(error, 'code') === '/probs/rac-identitybatch/RIB-012';
    }

    _handleProcessingFailure(data, schoolId, orderCode, uploadId) {
        let firstError = _.get(data, 'errors.length', 0) ? data.errors[0] : null;
        if (this._isValidationFailure(firstError)) {
            let stateParams = { schoolId, orderCode, uploadId };
            this.$state.go('admin.school.bulk-upload.validation-summary', stateParams);
            return this.$q.reject(data.errors);
        } else {
            return this.displayErrorNotifications(data.errors);
        }
    }

    displayErrorNotifications(errors) {
        _(errors || [])
            .map(error => {
                const defaultMessage =
                    'An error occurred.  Please contact a customer support specialist.';
                return this._isValidationFailure(error)
                    ? `Oops! This spreadsheet contains errors.
                Review the errors from the Account Actions panel
                or choose Select File to upload an amended spreadsheet.`
                    : error.uiMessage || defaultMessage;
            })
            .forEach(message => {
                this.eventMediator.emit('messageNotification', { type: 'danger', message });
            });
        return this.$q.reject(errors);
    }
}
