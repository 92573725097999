import angular from 'angular';

import truncateTextDirective from './truncate-text-directive';
import titleCaseDirective from './title-case-directive';
import pluralize from './pluralize-factory';

const textUtils = angular.module('textUtils', []);
textUtils.directive('truncateText', truncateTextDirective);
textUtils.directive('titleCase', titleCaseDirective);
textUtils.factory('pluralize', pluralize);

export default textUtils.name;
