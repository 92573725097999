// @ngInject
export default function specialistRoutes($stateProvider) {
    $stateProvider
        .state('specialist', {
            abstract: true,
            url: '/specialist',
            template: ` <div class="specialist">
                      <specialist-sidenav class="sidenav"></specialist-sidenav>
                      <div class="main-content">
                        <div class="container">
                          <div class="row">
                            <div class="col-xs-10 col-xs-offset-1">
                              <ui-view></ui-view>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> `,
            data: {
                isAuthorized(user) {
                    return user.hasImplementationSpecialistRole();
                },
            },
        })
        .state('specialist.districts', {
            abstract: true,
            url: '/districts',
            template: `<ui-view></ui-view>`,
        })
        .state('specialist.districts.index', {
            url: '',
            templateUrl: 'specialist/district/district.html',
            controller: 'SpecialistHomeController',
            controllerAs: '$ctrl',
            resolve: {
                districts: /* @ngInject */ specialistService => {
                    return specialistService.getDistricts();
                },
            },
        })
        .state('specialist.create-accounts', {
            abstract: true,
            url: '/create-accounts/:orderCode',
            template: '<ui-view />',
            resolve: {
                order: /* @ngInject */ (specialistService, $stateParams) => {
                    return specialistService.getOrder($stateParams.orderCode);
                },
                school: /* @ngInject */ (order, specialistService, specialistSidenavStore) => {
                    return specialistService.getSchoolById(order.group_id).then(school => {
                        specialistSidenavStore.updateSchool(school);
                        return school;
                    });
                },
                district: /* @ngInject */ (school, adminService, specialistSidenavStore) => {
                    return adminService.getDistrictById(school.parent.id).then(district => {
                        specialistSidenavStore.updateDistrict(district);
                        return district;
                    });
                },
            },
        })
        .state('specialist.create-accounts.index', {
            url: '',
            templateUrl: 'specialist/create-accounts/create-accounts.html',
            controller: 'CreateAccountsController',
            controllerAs: '$ctrl',
            data: {
                isAuthorized(user) {
                    return user.hasImplementationSpecialistRole();
                },
            },
        })
        .state('specialist.create-accounts.processing', {
            url: '/processing',
            templateUrl: 'specialist/create-accounts/create-accounts-processing.html',
            controller: /* @ngInject */ function(school) {
                this.school = school;
            },
            controllerAs: '$ctrl',
        });
}
