export default function districtModalFactory($filter, $uibModal) {
    'ngInject';

    return {
        confirmDistrictCodeRegeneration() {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                template: `
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"></button>
                            <div class="modal-header">
                                <h1>Are you sure?</h1>
                            </div>
                            <div class="modal-body">
                                <p>
                                    If you refresh, the current District Code will not work for users
                                    who have not created their accounts yet.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <div class="buttons-centered-container">
                                    <div class="buttons-centered">
                                        <button class="btn btn-default" ng-click="$dismiss()">Cancel</button>
                                        <button class="btn btn-primary" ng-click="$close()">Yes, generate a new code.</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        `,
            }).result;
        },
    };
}
