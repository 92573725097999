//npm imports
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import _ from 'lodash';

//local imports
import { isExpiredTeacherTrialUser } from './payment/payment-authorization';
import admin from './admin/admin';
import apiAction from './api-action/api-action';
import authorization from './authorization/authorization';
import changePassword from './components/change-password/change-password';
import demo from './demo/demo';
import footer from './footer/footer';
import header from './header/header';
import integrations from './integrations/integrations';
import interactionShield from './interaction-shield/interaction-shield';
import interceptors from './interceptors/interceptors';
import login from './login/login';
import messageNotifications from './message-notifications/message-notifications';
import onboarding from './onboarding/onboarding';
import payment from './payment/payment';
import roleDrawer from './role-drawer/role-drawer';
import sentry from './sentry/sentry';
import specialist from './specialist/specialist';
import spreadsheet from './spreadsheet/spreadsheet';
import student from './student/student';
import teacher from './teacher/teacher';
import termsOfService from './terms-of-service/terms-of-service';
import tooltip from './components/tooltip/tooltip';
import uiSrefIf from './components/ui-sref-if/ui-sref-if';

//module
const app = angular.module('frontend-ra-consumer', [
    admin,
    apiAction,
    authorization,
    changePassword,
    demo,
    footer,
    header,
    integrations,
    interactionShield,
    interceptors,
    login,
    messageNotifications,
    onboarding,
    payment,
    roleDrawer,
    sentry,
    specialist,
    spreadsheet,
    student,
    teacher,
    termsOfService,
    tooltip,
    uiBootstrap,
    uiRouter,
    uiSrefIf,
]);

//config
app.config(function($logProvider, $stateProvider, $urlRouterProvider) {
    if (!__PRODUCTION__) {
        $logProvider.debugEnabled(true);
    }

    $urlRouterProvider.otherwise($injector => {
        const user = $injector.get('user');
        const authService = $injector.get('authService');

        return user.isAuthenticated() ? authService.redirect() : '/login';
    });
});

app.run(
    /* @ngInject */ (
        $rootScope,
        $state,
        $log,
        authService,
        authorizationFactory,
        user,
        $anchorScroll
    ) => {
        $rootScope.$on('$stateChangeSuccess', () => $anchorScroll());

        $rootScope.$on('$stateChangeStart', (evt, toState, toParams) => {
            const isPublic = _.get(toState, 'data.isPublic', false);
            if (isPublic) {
                return;
            }

            if (!user.isAuthenticated()) {
                evt.preventDefault();
                return authService
                    .refreshAuthentication()
                    .then(() => $state.go(toState, toParams))
                    .catch(() => $state.go('login'));
            }

            const { isAuthorized, message } = authorizationFactory.isAuthorizedForState(
                toState,
                user,
                toParams
            );
            if (!isAuthorized) {
                console.error(message);
                evt.preventDefault();
                return $state.go('unauthorized');
            }

            if (isExpiredTeacherTrialUser(user) && !_.startsWith(toState.name, 'payment')) {
                evt.preventDefault();
                $log.debug('Redirecting user to trial expired page.');
                $state.go('payment', { trialExpired: true });
            }
        });

        $rootScope.$on(
            '$stateChangeError',
            (evt, toState, toParams, fromState, fromParams, error) => {
                $log.error('An unhandled routing error occurred', error);
            }
        );
    }
);

app.run(function($log, $rootScope, $state, $window) {
    /* Google Analytics Block */
    /* eslint-disable */
    $window.tracking = true;

    (function(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
            i[r] ||
            function() {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })($window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */
    if ($window.tracking) {
        ga('create', 'UA-45031362-3', 'auto');
    }

    $rootScope.trackPageview = function() {
        if ($window.ga) {
            if (__PRODUCTION__) {
                return $window.ga('send', 'pageview', { page: 'rac.' + $state.current.name });
            } else {
                $log.debug('<app> trackPageview');
                $log.debug({ page: 'rac.' + $state.current.name });
                return $window.tracking;
            }
        } else {
            $log.debug("<app> Didn't have GA, disabling pageview tracking");
            $window.tracking = false;
            return $window.tracking;
        }
    };
    $rootScope.$on('$stateChangeSuccess', $rootScope.trackPageview);
});

export default app.name;
