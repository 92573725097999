import angular from 'angular';

import createAdminAccount from './create-admin-account/create-admin-account';
import createAdminAccountSuccess from './create-admin-account-success/create-admin-account-success';
import changeAdminPassword from './change-admin-password/change-admin-password';
import editAdminAccount from './edit-admin-account/edit-admin-account';
import viewAdminAccount from './view-admin-account/view-admin-account';
import adminAccounts from './admin-accounts/admin-accounts';

const admins = angular.module('admins', [
    createAdminAccount,
    createAdminAccountSuccess,
    changeAdminPassword,
    editAdminAccount,
    viewAdminAccount,
    adminAccounts,
]);

export default admins.name;
