import angular from 'angular';
import uiRouter from 'angular-ui-router';
import EventMediator from 'gong-event-mediator';

import adminShared from '../../../shared/shared';
import accounts from '../../components/accounts/accounts';

import StudentAccountsController from './student-accounts-controller';

const studentAccounts = angular.module('studentAccounts', [
    adminShared,
    accounts,
    uiRouter,
    EventMediator,
]);
studentAccounts.controller('StudentAccountsController', StudentAccountsController);

export default studentAccounts.name;
