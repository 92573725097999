import angular from 'angular';
import uiRouter from 'angular-ui-router';
import eventMediator from 'gong-event-mediator';

import fileInput from '../../components/file-input/file-input';
import specialistShared from '../shared/shared';
import spreadsheet from '../../spreadsheet/spreadsheet';

import CreateAccountsController from './create-accounts-controller';

const createAccounts = angular.module('specialistCreateAccounts', [
    uiRouter,
    specialistShared,
    fileInput,
    spreadsheet,
    eventMediator,
]);
createAccounts.controller('CreateAccountsController', CreateAccountsController);

export default createAccounts.name;
