import _ from 'lodash';

// @ngInject
export default function specialistService(urls, $http, $q) {
    return {
        getDistricts() {
            let url = `${urls.base}/ra/admin/groups`;
            let params = {
                membership_type: 'Implementation Specialist',
                group_type: 'District',
            };
            return $http({ url, method: 'GET', params })
                .then(res => {
                    let groupMemberships = res.data.group_memberships || [];
                    return _.map(groupMemberships, 'group');
                })
                .catch(rej => $q.reject(rej.data));
        },
        getOrder(orderCode) {
            return $http({
                method: 'GET',
                url: `${urls.base}/ra/idbatch/orders/${orderCode}`,
            }).then(res => {
                let order = res.data;
                // Temporary for transitional renaming
                order.code = order.code || order.identifier;
                return order;
            });
        },
        getSchoolById(schoolId) {
            return $q((resolve, reject) => {
                const url = `${urls.base}/ra/admin/school/${schoolId}`;
                return $http({ url, method: 'GET' })
                    .then(res => resolve(res.data))
                    .catch(rej => reject(rej.data));
            });
        },
        reuploadSpreadsheet(orderCode, data) {
            return $http({
                method: 'POST',
                url: `${urls.base}/ra/idbatch/orders/${orderCode}/uploads`,
                data,
            })
                .then(res => res.data)
                .catch(rej => $q.reject(rej.data));
        },
        createAccounts(orderCode) {
            return $http({
                method: 'POST',
                url: `${urls.base}/ra/idbatch/orders/${orderCode}/process`,
            })
                .then(res => res.data)
                .catch(rej => $q.reject(rej.data));
        },
    };
}
