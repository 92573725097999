import _ from 'lodash';

const MAXIMUM_FIRSTNAME_LENGTH = 50;
const MAXIMUM_LASTNAME_LENGTH = 50;
const MAXIMUM_EMAIL_LENGTH = 100;
const MAXIMUM_USERNAME_LENGTH = 75;
const MINIMUM_PASSWORD_LENGTH = 8;

const ERROR_CONTENT = {
    alert: {
        username:
            'Sorry! There is an existing account that uses this username. Please enter a different username.',
        email:
            'Sorry! There is an existing account that uses this email. Please enter a different email address.',
    },
    firstName: {
        required: 'Please enter a first name.',
    },
    lastName: {
        required: 'Please enter a last name.',
    },
    useEmail: {
        required: 'Please indicate if student will use an email address or username.',
    },
    username: {
        required: 'Please enter a username.',
        invalid: 'Please enter a different username.',
    },
    email: {
        required: 'Please enter an email address.',
        invalid: 'Please enter a different email address.',
    },
    usePassword: {
        required: 'Required.',
    },
    password: {
        invalid: `Password must contain at least ${MINIMUM_PASSWORD_LENGTH} characters.`,
    },
    confirmPassword: {
        invalid: 'Password does not match.',
    },
};

const EMPTY_DATA = {
    firstName: '',
    lastName: '',
    useEmail: undefined,
    username: '',
    email: '',
    usePassword: undefined,
    password: '',
    confirmPassword: '',
};

class CreateAccountController {
    // @ngInject
    constructor(eventMediator, $scope, $state, $stateParams, $q, $uibModal) {
        this.eventMediator = eventMediator;
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$q = $q;
        this.$uibModal = $uibModal;

        this.data = this._getCleanData();
        this.errors = _.cloneDeep(ERROR_CONTENT);
        this.maxFirstNameLength = MAXIMUM_FIRSTNAME_LENGTH;
        this.maxLastNameLength = MAXIMUM_LASTNAME_LENGTH;
        this.maxEmailLength = MAXIMUM_EMAIL_LENGTH;
        this.maxUsernameLength = MAXIMUM_USERNAME_LENGTH;
        this.minPasswordLength = MINIMUM_PASSWORD_LENGTH;
        this.disableNavigationNet = false;

        this.showUsername = !!this.showUsername;
    }

    $onInit() {
        // TODO update teacher and student account creation to use group rather than school
        if (this.group || !this.school) return;
        this.group = this.school;
    }

    navigationHandler() {
        return this.$q((resolve, reject) => {
            if (this.disableNavigationNet || angular.equals(this.data, this._getCleanData())) {
                reject();
            }

            resolve(
                'You aren’t finished setting up this account. If you leave this page, this account will not be created.'
            );
        });
    }

    _getCleanData() {
        return _.merge({}, this.defaultData, EMPTY_DATA);
    }

    _determineErrorFromFailedPost(errors) {
        _.forEach(errors, error => {
            let errorType;
            if (error.title === 'User with email exists') {
                errorType = 'email';
            } else if (error.title === 'User with username exists') {
                errorType = 'username';
            } else if (error.title === 'User already exists') {
                errorType = this.data.useEmail === false ? 'username' : 'email';
            }

            if (!errorType) return;
            this.$scope.createAccount[errorType].$error.invalid = true;
            this.eventMediator.emit('messageNotification', {
                type: 'danger',
                message: ERROR_CONTENT.alert[errorType],
            });
        });
    }

    _createPassword(length) {
        let password = '';
        const WHITELIST = '2346789aAbBcCdDeEfFgGhHjJkKlLmMnNpPqQrRtTuUvVwWxXyYzZ';

        while (password.length < length) {
            let index = Math.floor(Math.random() * WHITELIST.length);
            password += WHITELIST.charAt(index);
        }

        return password;
    }

    _getFormattedData() {
        return {
            first_name: this.data.firstName,
            last_name: this.data.lastName,
            username: this.data.useEmail === false ? this.data.username : '',
            password:
                this.data.usePassword === true
                    ? this.data.password
                    : this._createPassword(MINIMUM_PASSWORD_LENGTH),
            email: this.data.email,
        };
    }

    _showAccountExistsModal(data) {
        return this.$uibModal.open({
            templateUrl: 'admin/school/components/create-account/account-exists-modal.html',
            resolve: {
                account: _.constant(_.pick(data, ['first_name', 'last_name'])),
                message: _.constant(
                    this.group.type === 'district'
                        ? `${data.first_name} ${data.last_name} is already a member of this district, ${this
                              .group.name}.`
                        : `${data.first_name} ${data.last_name} is already a member of a school in this district, and will also be added to ${this
                              .group.name}.`
                ),
            },
            controller: /* @ngInject */ function(account, message) {
                this.account = account;
                this.message = message;
            },
            controllerAs: '$ctrl',
        }).result;
    }

    submit() {
        if (this.$scope.createAccount.$valid === true) {
            const params = {
                schoolId: this.$stateParams.schoolId,
                groupId: this.group.id,
                identityId: null,
                data: this._getFormattedData(),
            };
            this.createAccount(params)
                .then(res => {
                    if (res.account_exists) {
                        _.assign(params.data, res.identity, {
                            password: `password has not been changed`,
                        });
                        params.identityId = res.identity.id;

                        return this._showAccountExistsModal(params.data);
                    }
                })
                .catch(rej => {
                    if (params.identityId) {
                        const accountRemovalProps = _.pick(params, [
                            'schoolId',
                            'groupId',
                            'identityId',
                        ]);
                        this.removeAccount(accountRemovalProps);
                    }

                    return this.$q.reject(rej);
                })
                .then(() => {
                    this.disableNavigationNet = true;
                    this.$state.go(
                        this.successState,
                        _.assign({}, params.data, { [`${this.group.type}Id`]: params.groupId }),
                        { reload: true }
                    );
                })
                .catch(this._determineErrorFromFailedPost.bind(this));
        } else {
            this.eventMediator.emit('messageNotification', {
                type: 'danger',
                message: 'Please complete all required fields.',
            });
        }
    }
}

const createAccountComponent = {
    templateUrl: 'admin/school/components/create-account/create-account-component.html',
    controller: CreateAccountController,
    bindings: {
        label: '@',
        cancelState: '@',
        successState: '@',
        showUsername: '@',
        defaultData: '<',
        school: '<',
        group: '<',
        createAccount: '&',
        removeAccount: '&',
    },
};

export default createAccountComponent;
