class AdminSidenavController {
    // @ngInject
    constructor(eventMediator, $scope, $state, adminSidenavStore, user, authorizationFactory) {
        this.$state = $state;
        this.adminSidenavStore = adminSidenavStore;
        this.user = user;
        this.authorizationFactory = authorizationFactory;
        this.adminSidenavStore = adminSidenavStore;

        this.adminSidenavData = adminSidenavStore.getAdminSidenavData();
        this.linksDisplay = adminSidenavStore.getLinks();
        this.selectionStates = {
            student: adminSidenavStore.isStudentRouteSelected(),
            teacher: adminSidenavStore.isTeacherRouteSelected(),
            school_admin: adminSidenavStore.isSchoolAdminRouteSelected(),
            district_admin: adminSidenavStore.isDistrictAdminRouteSelected(),
        };
        eventMediator.subscribe($scope, '$stateChangeSuccess', () => {
            this.adminSidenavData = this.adminSidenavStore.getAdminSidenavData();
            this.linksDisplay = this.adminSidenavStore.getLinks();
            this.selectionStates = {
                student: adminSidenavStore.isStudentRouteSelected(),
                teacher: adminSidenavStore.isTeacherRouteSelected(),
                school_admin: adminSidenavStore.isSchoolAdminRouteSelected(),
                district_admin: adminSidenavStore.isDistrictAdminRouteSelected(),
            };
        });
    }

    isActiveNav(routeName, compareStrict) {
        if (compareStrict) return this.$state.is(routeName);
        return this.$state.includes(routeName);
    }

    isSchoolSelected() {
        return this.$state.is('admin.school.index');
    }
}

let adminSidenavComponent = {
    templateUrl: 'admin/admin-sidenav/admin-sidenav-component.html',
    controller: AdminSidenavController,
    bindings: {},
};

export default adminSidenavComponent;
