const noFileSelected = 'No file selected.';

class FileInputChangeCheckerController {
    /* @ngInject */
    constructor() {
        this.isChanged = false;
        this.files = [];
        this.filename = this.initialFilename || noFileSelected;
    }

    handlePrimaryAction() {
        if (this.isChanged) {
            this.onChanged({ file: this.files[0] });
        } else {
            this.onUnchanged();
        }
    }
}

function fileInputChangeChecker() {
    'ngInject';

    return {
        restrict: 'E',
        controller: FileInputChangeCheckerController,
        controllerAs: '$ctrl',
        bindToController: true,
        scope: {
            onUnchanged: '&',
            onChanged: '&',
            primaryButtonText: '@',
            initialFilename: '@',
        },
        template: `
            <div class="file-upload">
                <div class="form-group selected-file">
                    <div>
                        <p class="file-selected" ng-bind-html="$ctrl.filename"></p>
                    </div>
                </div>
                <div class="form-group buttons tight-to-form">
                    <label class="btn btn-default btn-file">
                        Select File
                        <input type="file" file-input="$ctrl.files" accept=".xls,.xlsx,.ods">
                    </label>
                    <button class="btn btn-primary" ng-bind="$ctrl.primaryButtonText" ng-click="$ctrl.handlePrimaryAction()"></button>
                </div>
            </div>
        `,
        link(scope, element, attributes, controller) {
            scope.$watch('$ctrl.files', function(newVal, oldVal) {
                if (newVal === oldVal) return;
                controller.isChanged = true;
                controller.filename = controller.files[0].name || noFileSelected;
            });
        },
    };
}

export default fileInputChangeChecker;
