import assignmentStates from '../assignment-states';

export default function(ASSIGNMENT_TYPES, ASSIGNMENT_TYPE_NAMES, assignmentTypesFactory) {
    'ngInject';

    const DEFAULT_STATE = 'all';

    function isAssignmentVisible(state, assignment) {
        const assignmentState = assignment.state.toLowerCase();

        return (
            assignmentState !== assignmentStates.deleted &&
            (state.toLowerCase() === DEFAULT_STATE || assignmentState === state.toLowerCase())
        );
    }

    return {
        DEFAULT_STATE,
        isAssignmentVisible,
        filterAssignments(state, assignments) {
            if (!assignments) return [];
            return state && state.toLowerCase() !== DEFAULT_STATE
                ? assignments.filter(assignment => isAssignmentVisible(state, assignment))
                : _.cloneDeep(assignments);
        },
        sortAssignments(assignments) {
            const stateOrder = [
                assignmentStates.open,
                assignmentStates.closed,
                assignmentStates.archived,
            ];
            // order by created date, then due date, finally by state.
            return _(assignments)
                .sortBy('created')
                .sortBy(assignment => {
                    return assignment.due_date || '9999-99-99';
                })
                .sortBy(assignment => {
                    return stateOrder.indexOf(assignment.state);
                })
                .value();
        },
        getAssignmentTypeName(assignmentType) {
            // TODO: This should go away once the old assignment types are completely gone.
            const type = assignmentTypesFactory.convertLegacyToCurrentAssignmentType(
                assignmentType
            );
            // const name = ASSIGNMENT_TYPE_NAMES[assignmentType.toUpperCase()];
            const name = ASSIGNMENT_TYPE_NAMES[type];

            if (!name) {
                const errorMessage =
                    `There is no assignment type of ${assignmentType}!` +
                    `The possible types are ${JSON.stringify(ASSIGNMENT_TYPES)}`;
                throw new Error(errorMessage);
            }

            return name;
        },
    };
}
