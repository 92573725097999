export default class TeacherAccountsController {
    // @ngInject
    constructor(teachers, $stateParams, $state, adminService, eventMediator, school) {
        this.teachers = teachers;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.adminService = adminService;
        this.eventMediator = eventMediator;
        this.school = school;

        const groupId = this.school.id;
        this.createAccountRef = `admin.school.teachers.create-account({ schoolId: ${groupId} })`;
        this.createBulkAccountsRef = `admin.school.teachers.bulk-upload({ schoolId: ${groupId} })`;
    }

    handleRemove(teacher) {
        this.adminService.removeTeacherById(this.school.id, teacher.identity_id).then(success => {
            this.fireRemoveNotification(teacher, success.members);
            this.$state.go(this.$state.current, this.$stateParams, { reload: true });
        });
    }

    fireRemoveNotification(teacher, members) {
        let membershipId = _.get(members, '[0].id', undefined);
        if (!membershipId) return;

        const undoData = {
            url: `/ra/admin/school/${this.school.id}/membership/${membershipId}/restart`,
            method: 'POST',
            data: { id: teacher.id },
        };

        const message = `You have successfully removed <strong>${_.escape(
            teacher.first_name + ' ' + teacher.last_name
        ).trim()}</strong>'s teacher account.<a api-action=${angular.toJson(undoData)}>Undo</a>`;

        this.eventMediator.emit('messageNotification', {
            type: 'success',
            message,
            postStateChange: true,
        });
    }

    handlePageChange(page, search) {
        let params = { page: page, search: search, count: 20 };
        this.adminService.getTeacherAccountsBySchool(this.school.id, params).then(teachers => {
            let currentPage = teachers.page ? teachers.page.current : undefined;
            this.teachers = teachers;
            this.$state.go(
                '.',
                { page: currentPage, search: search },
                { reload: false, notify: false }
            ); // This is to
        });
    }

    getManageAccountSref(accountId) {
        return `admin.school.teachers.teacher.detail({ schoolId: ${this.school
            .id}, identityId: ${accountId} })`;
    }
}
