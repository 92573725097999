class CreateStudentAccountController {
    // @ngInject
    constructor(adminService, school) {
        this.adminService = adminService;
        this.school = school;

        this.data = {};
    }

    createStudentAccount(schoolId, data) {
        return this.adminService.createStudentAccount(schoolId, data);
    }

    removeStudentAccount(schoolId, identityId) {
        return this.adminService.removeStudentById(schoolId, identityId);
    }
}

export default CreateStudentAccountController;
