export default class ExpirationPendingModalController {
    // @ngInject
    constructor(modalNameToShow, paymentService, pluralize, $log, $state, $uibModalInstance) {
        this.paymentService = paymentService;
        this.$log = $log;
        this.$state = $state;
        this.$uibModalInstance = $uibModalInstance;
        this.modalNameToShow = modalNameToShow;

        const modalDetails = {
            'teacher-trial-5-days-remaining': {
                image: '/images/payment/modal-book-climber.svg',
                imageStyle: {},
                header: 'Your trial ends in 5 days.',
            },
            'teacher-trial-3-days-remaining': {
                image: '/images/payment/modal-reader.svg',
                imageStyle: { width: '80%' },
                header: 'Your trial ends in 3 days.',
            },
            'teacher-trial-1-day-remaining': {
                image: '/images/payment/modal-graduate.svg',
                imageStyle: {
                    width: '50%',
                    'padding-left': '40px',
                    'margin-bottom': '-40px',
                },
                header: 'Your trial ends in 1 day.',
            },
        };

        const details = modalDetails[this.modalNameToShow];
        this.modalImage = details.image;
        this.modalImageStyle = details.imageStyle;
        this.modalHeader = details.header;
        this.modalBody = 'But you can keep the story going!';
        this.buttonText = 'Subscribe';
    }

    goToPaymentPage() {
        this.$log.debug('Going to payment page.');
        this.dismiss();
        this.$state.go('payment');
    }

    dismiss() {
        this.$log.debug('User has seen modal', this.modalNameToShow);
        this.paymentService
            .dismissExpirationPendingModal(this.modalNameToShow)
            .finally(() => this.$uibModalInstance.close());
    }
}
