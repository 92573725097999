import angular from 'angular';
import EventMediator from 'gong-event-mediator';

import urlConfig from '../url-config/url-config';
import user from '../user/user';

import footerComponent from './footer-component';

const footer = angular.module('footer', [urlConfig, user, EventMediator]);
footer.component('appFooter', footerComponent);

export default footer.name;
