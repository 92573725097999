import angular from 'angular';

import user from '../user/user';
import login from '../login/login';
import authTokenInterceptor from './auth-token-interceptor';
import pearlyGatesInterceptor from './pearly-gates-interceptor';
import newTiiHeaderServicesHeaderInterceptor from './new-tii-services-header-interceptor';

const interceptors = angular.module('interceptors', [user, login]);

interceptors.config(function($httpProvider) {
    $httpProvider.interceptors.push(authTokenInterceptor);
    $httpProvider.interceptors.push(pearlyGatesInterceptor);
    $httpProvider.interceptors.push(newTiiHeaderServicesHeaderInterceptor);
});

export default interceptors.name;
