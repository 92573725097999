import angular from 'angular';
import uiRouter from 'angular-ui-router';
import urlConfig from '../url-config/url-config';
import EventMediator from 'gong-event-mediator';

import specialistRoutes from './specialist-routes';

import specialistShared from './shared/shared';
import specialistSidenav from './specialist-sidenav/specialist-sidenav';
import login from '../login/login';

import specialistDistrictHome from './district/district';
import createAccounts from './create-accounts/create-accounts';

const specialist = angular.module('specialist', [
    uiRouter,
    urlConfig,
    EventMediator,
    specialistDistrictHome,
    specialistShared,
    specialistSidenav,
    createAccounts,
    login,
]);
specialist.config(specialistRoutes);

export default specialist.name;
