class CreateStudentAccountSuccessController {
    // @ngInject
    constructor($stateParams) {
        this.data = {
            username: $stateParams.username,
            email: $stateParams.email,
            password: $stateParams.password,
            firstName: $stateParams.first_name,
            lastName: $stateParams.last_name,
            schoolId: $stateParams.schoolId,
        };
        this.bodyCopy = `Please share the password with the student or the student’s instructor. The user can change the password when he or she logs into Revision Assistant for the first time. You may view or edit ${$stateParams.first_name}’s account at any time.`;
    }
}

export default CreateStudentAccountSuccessController;
