export default function searchValidation() {
    return {
        isValidLengthSearch(searchInput) {
            return searchInput ? searchInput.length > 2 : false;
        },
        isSearchInputValid(searchInput, searchInputLast) {
            let si = this.isValidLengthSearch(searchInput);
            let sil = this.isValidLengthSearch(searchInputLast);
            // if the term is > 2 characters,
            // or we've just cleared the search
            return si || sil;
        },
    };
}
