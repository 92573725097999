export default class OnboardingService {
    // @ngInject
    constructor(user, preferences, userAccountService) {
        this.user = user;
        this.preferences = preferences;
        this.userAccountService = userAccountService;
    }

    userHasOnboarding() {
        return this.userHasTeacherOnboarding() || this.userHasAdministratorOnboarding();
    }

    userHasTeacherOnboarding() {
        return this.user.isInstructor() && this.preferences.isTeacherOnboardingEnabled();
    }

    userHasAdministratorOnboarding() {
        return this.user.isAdministrator() && this.preferences.isAdministratorOnboardingEnabled();
    }

    userHasCompletedOnboarding() {
        if (this.userHasAdministratorOnboarding())
            return this.preferences.isAdministratorOnboardingComplete();
        else if (this.userHasTeacherOnboarding())
            return this.preferences.isTeacherOnboardingComplete();
        return false;
    }

    markTeacherOnboardingComplete() {
        this.preferences.setTeacherOnboardingComplete(true);
        this.userAccountService.storePreferences({ 'onboarding.teacher.complete': true });
    }

    markAdministratorOnboardingComplete() {
        this.preferences.setAdministratorOnboardingComplete(true);
        this.userAccountService.storePreferences({ 'onboarding.administrator.complete': true });
    }
}
