const HELLIP = '...';
const REG_EXP = /\s+\w*$/; // the whitespace before the last truncated word

function truncateText(text, length) {
    let truncated = text;
    if (truncated.length <= length) return truncated;

    const trueLength = length - HELLIP.length;
    truncated = truncated.substr(0, trueLength);

    if (!/\s+/.test(text.substr(trueLength, 1))) {
        truncated = truncated.replace(REG_EXP, '');
    }

    truncated = truncated.trim() + HELLIP;

    return truncated;
}

export default function() {
    return {
        restrict: 'A',
        scope: {
            text: '@truncateText',
            length: '@length',
        },
        template: '{{truncated}}',
        link(scope) {
            scope.length = scope.length ? parseInt(scope.length) : 76;
            scope.truncated = truncateText(scope.text, scope.length);

            scope.$watch(
                scope => scope.text,
                (newVal, oldVal, scope) => {
                    scope.truncated = truncateText(newVal, scope.length);
                }
            );
        },
    };
}
