import angular from 'angular';
import eventMediator from 'gong-event-mediator';
import urlConfig from '../url-config/url-config';
import assignmentTileModule from './assignment-tile/assignment-tile';
import assignmentShared from './shared/shared';

import { ASSIGNMENT_TYPES, ASSIGNMENT_TYPE_NAMES } from './assignment-constants';
import assignmentFactory from './assignment-factory';
import assignmentFilters from './assignment-filters';

const assignment = angular.module('assignment', [
    urlConfig,
    eventMediator,
    assignmentTileModule,
    assignmentShared,
]);
assignment.constant('ASSIGNMENT_TYPES', ASSIGNMENT_TYPES);
assignment.constant('ASSIGNMENT_TYPE_NAMES', ASSIGNMENT_TYPE_NAMES);
assignment.factory('assignmentFactory', assignmentFactory);
assignment.filter('toAssignmentTypeName', assignmentFilters.assignmentTypeNameFilter);

export default assignment.name;
