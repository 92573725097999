import angular from 'angular';
import uiRouter from 'angular-ui-router';
import EventMediator from 'gong-event-mediator';
import uiBootstrap from 'angular-ui-bootstrap';
import angularScroll from 'angular-scroll';

import textUtils from '../../../../text-utils/text-utils';
import user from '../../../../user/user';
import adminShared from '../../../shared/shared';
import raPagination from '../../../../ra-pagination/ra-pagination';

import accountsComponent from './accounts-component';

const accounts = angular.module('accounts', [
    adminShared,
    uiRouter,
    EventMediator,
    uiBootstrap,
    raPagination,
    angularScroll,
    textUtils,
    user,
]);
accounts.component('accounts', accountsComponent);
export default accounts.name;
