import _ from 'lodash';

const minPasswordOptions = {
    yes: '8',
    no: 'no',
};

function getDefaultModel() {
    return {
        generatePasswords: null,
        changePassword: null,
        specifyOnePassword: null,
        lessThanMinimum: null,
        password: null,
    };
}

class PasswordCreationSettingsController {
    // @ngInject
    constructor($scope) {
        this.minPasswordOptions = minPasswordOptions;

        $scope.$watchGroup(
            ['$ctrl.model.generatePasswords', '$ctrl.model.specifyOnePassword'],
            this.setIsChangePasswordVisible.bind(this)
        );
    }

    $onInit() {
        const initialModel = _.pick(this.initialModel, Object.keys(getDefaultModel()));
        this.model = _.assign(getDefaultModel(), initialModel);
    }

    setIsChangePasswordVisible() {
        this.isChangePasswordVisible = this.getChangePasswordVisible(
            this.model,
            this.isChangePasswordAvailable
        );
    }

    getChangePasswordVisible({ generatePasswords, specifyOnePassword }, isChangePasswordAvailable) {
        if (!isChangePasswordAvailable) {
            return false;
        }

        return generatePasswords || specifyOnePassword === false;
    }

    back() {
        this.onBack({ model: this.model });
    }

    /**
     * While a user is interacting with the form we allow some "invalid" states to be produced
     * so that we don't lose data. For example, if you have selected "generate passwords" then
     * there should NOT be a default password. We'll allow that while are you interacting so you
     * don't have to retype things. After you click submit though, we want to make the model correct.
     */
    transformSubmittedModel(initialModel) {
        // default changePassword if it was not explicitly set in the UI
        const changePassword =
            initialModel.changePassword === null ? true : initialModel.changePassword;
        const model = _.assign({}, initialModel, { changePassword });

        if (model.generatePasswords) {
            return _.assign({}, model, {
                specifyOnePassword: null,
                password: null,
                lessThanMinimum: null,
            });
        }

        if (model.specifyOnePassword) {
            return _.assign({}, model, {
                lessThanMinimum: null,
                changePassword: true,
            });
        }

        return _.assign({}, model, { password: null });
    }

    saveAndContinue() {
        if (this.form.$valid) {
            const model = this.transformSubmittedModel(this.model);
            this.onSaveAndContinue({ model });
        }
    }
}

export default {
    bindings: {
        accountTypeName: '<',
        isChangePasswordAvailable: '<',
        initialModel: '<',
        onBack: '&',
        onSaveAndContinue: '&',
    },
    controller: PasswordCreationSettingsController,
    templateUrl:
        'admin/bulk-upload/bulk-upload-wizard/password-creation-settings/password-creation-settings.html',
};
