import angular from 'angular';

import CreateTeacherAccountSuccessController from './create-teacher-account-success-controller';

const createTeacherAccountSuccess = angular.module('createTeacherAccountSuccess', []);
createTeacherAccountSuccess.controller(
    'CreateTeacherAccountSuccessController',
    CreateTeacherAccountSuccessController
);

export default createTeacherAccountSuccess.name;
