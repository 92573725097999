import { transformHomeData, transformArchivedClassData } from './teacher-service-transforms';

// @ngInject
export default function teacherService(urls, $http, $q, userAccountService) {
    return {
        getHomeData() {
            return $http({
                url: `${urls.base}/ra/classes`,
                method: 'POST',
                data: { membership_type: 'Instructor' },
            }).then(transformHomeData);
        },
        getArchivedClasses() {
            return $http({
                url: `${urls.base}/ra/classes/archived`,
                method: 'POST',
                data: { membership_type: 'Instructor' },
            }).then(transformArchivedClassData);
        },
        getClass(classId) {
            return $http({
                method: 'GET',
                url: `${urls.base}/ra/class/${classId}`,
            }).then(res => res.data);
        },
        createClass(data) {
            return $http({
                url: `${urls.base}/ra/class`,
                method: 'POST',
                data,
            }).then(res => res.data);
        },
        updateClassById(id, data) {
            return $http({
                url: `${urls.base}/ra/class/${id}`,
                method: 'PUT',
                data,
            }).then(res => res.data);
        },
        getAssignment(assignmentId) {
            return $http({
                url: `${urls.base}/ra/assignments/${assignmentId}`,
                method: 'GET',
            }).then(res => res.data);
        },
        editAssignment(_data) {
            const data = {
                ..._data,
                due_date: _data.due_date || '',
            };

            return $http({
                url: `${urls.base}/ra/assignments/${data.id}`,
                method: 'PUT',
                data,
            }).then(res => res.data);
        },
        createAssignment(data) {
            return $http({
                url: `${urls.base}/ra/assignments`,
                method: 'POST',
                data,
            })
                .then(res => res.data)
                .catch(rej => rej.data.errors);
        },
        getStudentWork(assignmentId) {
            return $http({
                url: `${urls.base}/ra/assignments/${assignmentId}/student-work`,
                method: 'GET',
            }).then(res => res.data);
        },
        editClass(data) {
            return $http({
                url: `${urls.base}/ra/class/${data.id}`,
                method: 'PUT',
                data,
            }).then(res => res.data);
        },
        getStudentAccount(studentId) {
            return $http({
                url: `${urls.base}/ra/identity/${studentId}`,
                method: 'GET',
            })
                .then(res => res.data)
                .catch(rej => rej.data);
        },
        updateStudentAccount(student) {
            return userAccountService.updateUserAccount(student);
        },
    };
}
