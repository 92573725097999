import angular from 'angular';
import uiRouter from 'angular-ui-router';

import utilsForm from '../../utils-form/utils-form';
import textUtils from '../../text-utils/text-utils';

import HomeController from './home-controller';
import teacherAssignmentTile from './teacher-assignment-tile-component';
import teacherHomePristineComponent from './teacher-home-pristine-component';

import assignment from '../../assignment/assignment';
import payment from '../../payment/payment';
import promotions from '../../components/promotions/promotions';
import user from '../../user/user';
import userAccount from '../../user-account/user-account';
import provider from '../../provider/provider';
import shared from '../../demo/shared/shared';
import assignmentSettingsModal from '../../components/assignment-settings-modal/assignment-settings-modal';

const home = angular.module('home', [
    uiRouter,
    utilsForm,
    textUtils,
    assignment,
    payment,
    promotions,
    user,
    userAccount,
    provider,
    shared,
    assignmentSettingsModal,
]);
home.controller('HomeController', HomeController);
home.component('teacherAssignmentTile', teacherAssignmentTile);
home.component('teacherHomePristine', teacherHomePristineComponent);

export default home.name;
