/**
 * Manages a list of options that a user can select.
 * Provides ability to select all or choose individual options.
 * This component relies on 2-way data binding - so the values you pass in
 * will be directly mutated as the user interacts with the control.
 */
class OptionListController {
    /* @ngInject */
    constructor() {}

    $onChanges(changes) {
        const model = _.get(changes, 'model.currentValue');
        if (model) {
            this.selectedItemsCount = this.getSelectedItemsCount(model.items);
        }
    }

    getSelectedItemsCount(items) {
        return _(items)
            .filter('isSelected')
            .size();
    }

    showList() {
        this.model.areOptionsVisible = true;
    }

    hideList() {
        this.model.areOptionsVisible = false;
    }

    selectAll() {
        this.model.items = _.map(this.model.items, x => _.assign({}, x, { isSelected: true }));
        this.selectedItemsCount = this.getSelectedItemsCount(this.model.items);
    }

    deselectAll() {
        this.showList();
        this.model.items = _.map(this.model.items, x => _.assign({}, x, { isSelected: false }));
        this.selectedItemsCount = this.getSelectedItemsCount(this.model.items);
    }

    handleOptionCheckboxChange() {
        this.model.includeAll = _.every(this.model.items, 'isSelected');
        this.selectedItemsCount = this.getSelectedItemsCount(this.model.items);
    }
}

export default {
    templateUrl: 'utils-form/option-list-component.html',
    controller: OptionListController,
    bindings: {
        model: '<', // { name, areOptionsVisible, includeAll, items: [{ name, isSelected }], validationMessage }
    },
};
