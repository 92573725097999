//remote imports
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ngMessages from 'angular-messages';
import eventMediator from 'gong-event-mediator';
//locals
import urlConfig from '../../url-config/url-config';
import utils from '../../utils/utils';
import user from '../../user/user';
import formUtils from '../../form-utils/form-utils';
import Shared from '../shared/shared';

import TeacherClassAddController from './teacher-class-add-controller';
import TeacherClassCreatedController from './teacher-class-created-controller';
import TeacherClassEditController from './teacher-class-edit-controller';
import TeacherClassRosterController from './teacher-class-roster-controller';
import TeacherClassManageStudentController from './student-accounts/view/teacher-class-manage-student-controller';
import TeacherClassEditStudentController from './student-accounts/edit/teacher-class-edit-student-controller';
import TeacherClassChangePasswordController from './teacher-class-change-password-controller';
import provider from '../../provider/provider';

//module
const teacherClass = angular.module('teacherClass', [
    uiRouter,
    Shared,
    ngMessages,
    eventMediator,
    urlConfig,
    utils,
    user,
    formUtils,
    provider,
]);
//config
//bindings
teacherClass.controller('TeacherClassAddController', TeacherClassAddController);
teacherClass.controller('TeacherClassCreatedController', TeacherClassCreatedController);
teacherClass.controller('TeacherClassEditController', TeacherClassEditController);
teacherClass.controller('TeacherClassRosterController', TeacherClassRosterController);
teacherClass.controller('TeacherClassManageStudentController', TeacherClassManageStudentController);
teacherClass.controller('TeacherClassEditStudentController', TeacherClassEditStudentController);
teacherClass.controller(
    'TeacherClassChangePasswordController',
    TeacherClassChangePasswordController
);
//export
export default teacherClass.name;
