import * as L from 'partial.lenses';

import { COMPLEX_ROLES } from '../user/user-factory';

export const INDIVIDUAL_TEACHER_ACCOUNT_USERS = 'individual-teacher-account-user';
export const TEACHER_TRIAL_USERS = 'teacher-trial-user';
export const PAYMENT_VIEW_ADMINISTRATORS = 'payment-view-administrators';

const { INSTRUCTOR, SINGLE_SCHOOL_ADMIN } = COMPLEX_ROLES;

export const preferences = {
    isOnTeacherTrial: 'feature.license-sends-trial-email',
    isOnIndividualTeacherAccount: 'license.individual-teacher-account',
};

/**
 * Looks across all groups in a preferences object to see if a given preference is enabled.
 * This can probably be moved to a util file eventually.
 */
function isPreferenceEnabledForAnyGroup(preferenceName, preferences) {
    return L.any(x => x === true, [L.values, preferenceName], preferences);
}

export function hasViewPaymentFormPermission(role, rawPreferences) {
    const hasPreference = hasTeacherTrialPermission(role, rawPreferences);
    const allowedRoles = [INSTRUCTOR, SINGLE_SCHOOL_ADMIN];
    return hasPreference && allowedRoles.includes(role);
}

export function hasTeacherTrialPermission(role, rawPreferences) {
    return isPreferenceEnabledForAnyGroup(preferences.isOnTeacherTrial, rawPreferences);
}

export function hasIndividualTeacherAccount(role, rawPreferences) {
    return isPreferenceEnabledForAnyGroup(preferences.isOnIndividualTeacherAccount, rawPreferences);
}

export default {
    groupPermissions: {},
    generalPermissions: {
        [PAYMENT_VIEW_ADMINISTRATORS]: hasViewPaymentFormPermission,
        [TEACHER_TRIAL_USERS]: hasTeacherTrialPermission,
        [INDIVIDUAL_TEACHER_ACCOUNT_USERS]: hasIndividualTeacherAccount,
    },
};
