import _ from 'lodash';

export default function accountDisplayFactory() {
    return {
        showMaxLicenses(maxLicenses) {
            // This is a hack. The backend needs a specific number for max licenses
            // to get around that when there are no licenses 999 would be done in the
            // backend as a standin number.
            if (maxLicenses === 999) {
                return false;
            }
            return maxLicenses === 0 || (!!maxLicenses && parseFloat(maxLicenses, 0) >= 0);
        },
        decorateSchool(school) {
            let decoratedSchool = _.cloneDeep(school);
            decoratedSchool.showMaxLicense = this.showMaxLicenses(school.license.max);
            return decoratedSchool;
        },
        decorateDistrict(district) {
            let decoratedDistrict = _.cloneDeep(district);
            decoratedDistrict.showMaxLicense = this.showMaxLicenses(district.license.max);
            decoratedDistrict.institutions = decoratedDistrict.institutions.map(
                this.decorateSchool.bind(this)
            );
            return decoratedDistrict;
        },
    };
}
