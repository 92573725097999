import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';

import urlConfig from '../../url-config/url-config';

import specialistService from './specialist-service';

const specialistShared = angular.module('specialistShared', [urlConfig, uiBootstrap]);

specialistShared.factory('specialistService', specialistService);

export default specialistShared.name;
