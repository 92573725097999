import _ from 'lodash';

export default function postDownloadFactory($q, $document, $timeout, user) {
    'ngInject';

    let currentUser = user;

    function validate(url) {
        return url && url.match(/^https:/);
    }

    function generateHiddenElement(tag, attributes, elementCreator) {
        const elem = elementCreator(tag);
        elem.css({ display: 'none' });
        elem.attr(attributes);
        return elem;
    }

    function triggerAction(element, actionFn, attachToElement, elementRetriever) {
        elementRetriever(attachToElement).append(element);
        actionFn(element[0]);
        $timeout(() => {
            element.remove();
        }, 50); // this is cleanup
    }

    function generateForm(formAttributes, queryArgs, elementCreator) {
        const form = generateHiddenElement('<form></form>', formAttributes, elementCreator);

        Object.keys(queryArgs || {}).forEach(key => {
            const field = elementCreator('<input />');
            field.attr({ type: 'hidden', name: key });
            field.val(queryArgs[key]);
            form.append(field);
        });

        form.submitAndVanish = (attachToElement = $document[0].body) => {
            return triggerAction(form, f => f.submit(), attachToElement, elementCreator);
        };

        return form;
    }

    const downloadWithFormModifications = _.curry(
        (modifierFn, url, elementCreator = angular.element) => {
            if (!validate(url)) {
                return $q.reject(`${url} is not allowed`);
            }

            const form = modifierFn(
                elementCreator,
                generateForm(
                    { action: url, method: 'POST' },
                    { AuthToken: currentUser.getToken() },
                    elementCreator
                )
            );
            form.submitAndVanish();

            return $q.when(url);
        }
    );

    const download = downloadWithFormModifications((elCreator, form) => form);

    return {
        downloadWithFormModifications,
        download,
    };
}
