import { isAuthorizedForTeacherTrialPayment } from '../payment-authorization';

const teacherTrialPaymentBannerComponent = {
    controller: class TeacherTrialPaymentBannerController {
        // @ngInject
        constructor(user, pluralize) {
            // Upon upgrade, the user's school will be placed into its own district
            // and will no longer have the teacher trial feature flag (which exists
            // only on the Teacher Trials district).
            this.canPayForTeacherTrial = isAuthorizedForTeacherTrialPayment(user);

            if (this.showDaysRemainingBanner) {
                this.showDaysRemaining = false;
                const daysRemaining = user.daysLeftOnLicense();
                if (daysRemaining > 0 && daysRemaining <= 5) {
                    this.showDaysRemaining = true;
                    this.daysRemainingLabel = pluralize.pluralizeOrNull(
                        count => `${count} day`,
                        count => `${count} days`,
                        daysRemaining
                    );
                    this.daysRemainingImage = `/images/payment/days-remaining-${daysRemaining}.svg`;
                }
            }
        }
    },
    templateUrl: 'payment/components/teacher-trial-payment-banner-component.html',
    bindings: {
        showDaysRemainingBanner: '<',
    },
};

export default teacherTrialPaymentBannerComponent;
