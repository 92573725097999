import angular from 'angular';
import EventMediator from 'gong-event-mediator';

import urlConfig from '../url-config/url-config';
import user from '../user/user';

import ProviderService from './provider-service';

const provider = angular.module('provider', [urlConfig, EventMediator, user]);
provider.factory('providerService', ProviderService);

export default provider.name;
