class CreateAdminAccountController {
    // @ngInject
    constructor(adminService, group) {
        this.adminService = adminService;
        this.group = group;

        this.groupType = group.type;
        this.label = _.startCase(`${this.groupType} administrator`);
        this.data = { useEmail: true };
    }

    createAdminAccount(groupId, data) {
        return this.adminService.createAdminAccount(groupId, data, this.groupType);
    }

    removeAdminAccount(groupId, identityId) {
        return this.adminService.removeAdminById(groupId, identityId, this.groupType);
    }
}

export default CreateAdminAccountController;
