import angular from 'angular';

import shared from '../../components/components';

import ChangeAdminPasswordController from './change-admin-password-controller';

const changeAdminPassword = angular.module('changeAdminPassword', [shared]);
changeAdminPassword.controller('ChangeAdminPasswordController', ChangeAdminPasswordController);

export default changeAdminPassword.name;
