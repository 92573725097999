import _ from 'lodash';

const MIN_TRANSITION_TIMEOUT = 2000;

class InteractionShieldController {
    // @ngInject
    constructor(eventMediator, $scope, $timeout) {
        this.eventMediator = eventMediator;
        this.$timeout = $timeout;

        this.eventMediator.subscribe($scope, 'raise-shield', this.raiseShield.bind(this));
        this.eventMediator.subscribe($scope, 'lower-shield', this.lowerShield.bind(this));
        this.eventMediator.subscribe($scope, 'toggle-shield', this.toggle.bind(this));
        this.eventMediator.subscribe($scope, 'start-loading-gfx', this.startLoading.bind(this));
        this.eventMediator.subscribe($scope, 'finish-loading-gfx', this.finishLoading.bind(this));

        this.shielded = false;
        this.transitionPromise = undefined;
        this.isSemiTransparent = false;
    }

    isTransitioning() {
        return this.transitionPromise;
    }

    _transitionCleanup() {
        this.$timeout.cancel(this.transitionPromise);
        this.transitionPromise = undefined;
        this.isSemiTransparent = false;
    }

    _transition() {
        if (this.isTransitioning()) {
            this._transitionCleanup();
        }

        this.transitionPromise = this.$timeout(() => {
            this._transitionCleanup();
        }, MIN_TRANSITION_TIMEOUT);
    }

    applyData(data) {
        let transition = _.get(data, 'transition', undefined);
        if (transition && transition === true) {
            this._transition();
        }
    }

    lowerShield(event, data) {
        this.applyData(data);
        this.shielded = false;
    }

    raiseShield(event, data) {
        this.applyData(data);
        this.shielded = true;
    }

    toggle() {
        this.shielded = !this.shielded;
    }

    isShielded() {
        return this.shielded || this.isTransitioning();
    }

    startLoading(evt, data) {
        this.transitionPromise = true;
        this.isSemiTransparent = _.get(data, 'isSemiTransparent', false);
    }

    finishLoading() {
        this._transitionCleanup();
    }
}

const interactionShieldComponent = {
    template: `<div
                    ng-if="$ctrl.isShielded()"
                    class="interaction-shield"
                    ng-class="{ 'in-transition': $ctrl.isTransitioning(), 'is-semi-transparent': $ctrl.isSemiTransparent }"
                    ng-click="$ctrl.eventMediator.emit('lower-shield')">
                    <img src="/images/loading.gif" width="200" ng-if="$ctrl.isTransitioning()"/>
                </div>`,
    controller: InteractionShieldController,
};

export default interactionShieldComponent;
