import angular from 'angular';

import adminShared from '../shared/shared';

import schoolsTableComponent from './schools-table-component';

const schoolsTable = angular.module('schoolsTable', [adminShared]);
schoolsTable.component('schoolsTable', schoolsTableComponent);

export default schoolsTable.name;
