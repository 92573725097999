import { BASIC_ROLES } from '../../user/user-factory.js';

const teacherHomePristineComponent = {
    bindings: {
        defaultSchoolId: '<',
        isTeacherTrial: '<',
        isSalesDemoEnabled: '<',
        onCreateSalesDemo: '&',
        hasArchivedClasses: '<',
    },
    controller: class TeacherHomePristineController {
        // @ngInject

        constructor(interactionShieldService, $timeout, $q, $window, $state, user, urls) {
            this.interactionShieldService = interactionShieldService;
            this.$timeout = $timeout;
            this.$q = $q;
            this.$window = $window;

            this.$state = $state;
            this.user = user;
            this.urls = urls;
        }

        loadYouTubeApi() {
            if (this.$window.YT) return this.$q.resolve();

            const promise = this.$q((resolve, reject) => {
                this.$window.onYouTubeIframeAPIReady = () => {
                    this.player = new YT.Player('youtube-player', {
                        events: {
                            onReady: resolve,
                            onStateChange: reject,
                        },
                    });
                };

                this.$timeout(5000).finally(reject);
            });

            const tag = document.createElement('script');
            tag.id = 'youtube-src';
            tag.src = 'https://www.youtube.com/iframe_api';

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            return promise;
        }

        $onInit() {
            this.interactionShieldService.startLoading();

            this.$q
                .all([this.$timeout(2000), this.loadYouTubeApi()])
                .then(() => (this.hasPlayer = true))
                .finally(() => {
                    this.ready = true;
                    this.interactionShieldService.finishLoading();
                });
        }

        onClickCreateStudentAccounts() {
            this.user.setCurrentRole(BASIC_ROLES.ADMINISTRATOR);
            this.$state.go('admin.school.index', { schoolId: this.defaultSchoolId });
        }
    },
    templateUrl: 'teacher/home/teacher-home-pristine-component.html',
};

export default teacherHomePristineComponent;
