export default {
    bindings: {
        accountTypeName: '<',
        headers: '<',
        templateFileUrl: '<',
        onContinue: '&',
    },
    templateUrl:
        'admin/bulk-upload/bulk-upload-wizard/check-your-spreadsheet/check-your-spreadsheet.html',
};
