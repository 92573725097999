import _ from 'lodash';

/*
  Note: These keys need to match up with the download types
  that are configured in rac-identitybatch
*/
const ACCOUNT_STATUS_LABEL = {
    'created identity': {
        one: '1 new account created',
        other: '{} new accounts created',
    },
    'created membership only': {
        one: '1 existing account was added to this school',
        other: '{} existing accounts were added to this school',
    },
    'review required': {
        one: '1 account needs review',
        other: '{} accounts need review',
    },
    unchanged: {
        one: '1 account not modified',
        other: '{} accounts not modified',
    },
    'deleted identity': {
        one: '1 account deleted',
        other: '{} accounts deleted',
    },
    'deleted membership only': {
        one: '1 existing account was removed from this school',
        other: '{} existing accounts were removed from this school',
    },
    'unable to be deleted': {
        one: '1 account unable to be deleted',
        other: '{} accounts unable to be deleted',
    },
};

class AbuUploadSummaryTableComponentController {
    // @ngInject
    constructor(bulkUploadService, $filter, user) {
        this.bulkUploadService = bulkUploadService;
        this.$filter = $filter;
        this.user = user;
    }

    $onInit() {
        this.showDownloadHistory = this.showDownloadHistory === 'true';
    }

    $onChanges(changes) {
        if (changes && changes.upload) {
            this.accountStatuses = this.getAccountStatuses();
        }
    }

    getAccountStatuses() {
        // TODO ENUM ME
        if (this.upload.status === 'deleted') {
            return {
                'deleted identity': {
                    count: this.upload.recordsDeletedIdentityCount,
                    action: () =>
                        this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, [
                            'deleted_identity',
                        ]),
                    className: 'records-deleted-identity-count',
                },
                'deleted membership only': {
                    count: this.upload.recordsDeletedMembershipOnlyCount,
                    action: () =>
                        this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, [
                            'deleted_membership_only',
                        ]),
                    className: 'records-deleted-membership-count',
                },
                'review required': {
                    count: this.upload.recordsInvalidCount,
                    action: () =>
                        this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, [
                            'review',
                        ]),
                    className: 'records-review-count',
                },
                'unable to be deleted': {
                    count: this.upload.recordsUnableToBeDeletedCount,
                    action: () =>
                        this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, [
                            'unable_to_be_deleted',
                        ]),
                    className: 'records-unable-to-be-deleted-count',
                },
            };
        }

        return {
            'created identity': {
                count: this.upload.recordsCreatedIdentityCount,
                action: () =>
                    this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, [
                        'created_identity',
                    ]),
                className: 'records-created-count',
            },
            'created membership only': {
                count: this.upload.recordsCreatedMembershipOnlyCount,
                action: () =>
                    this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, [
                        'created_membership_only',
                    ]),
                className: 'records-added-count',
            },
            'review required': {
                count: this.upload.recordsInvalidCount,
                action: () =>
                    this.bulkUploadService.downloadOutcomeFiles(this.upload.uploadId, ['review']),
                className: 'records-review-count',
            },
            unchanged: {
                count: this.upload.recordsUnchangedCount,
                action: null,
                className: 'records-unchanged-count',
            },
        };
    }

    getStatusLabelConfig(key) {
        return ACCOUNT_STATUS_LABEL[key];
    }

    getStatusLabelCount(key) {
        return this.accountStatuses[key].count;
    }

    getAccountStatusLabel(key) {
        const count = this.accountStatuses[key].count;
        return ACCOUNT_STATUS_LABEL[key](count > 0 ? count : 0);
    }

    getDownloadHistory(key) {
        const downloadType = _.lowerCase(key);

        return _.flow([
            () => this.upload.downloads,
            downloads => _.find(downloads, ['downloadType', downloadType]),
            download => {
                if (!download) return 'Not downloaded.';

                const name = download.name;
                const date = this.$filter('date')(download.created, 'MMM d y');
                return `Downloaded ${date} by ${name}.`;
            },
        ])();
    }

    hasAccounts(key) {
        return this.accountStatuses[key].count > 0;
    }

    hasAction(key) {
        return !!this.accountStatuses[key].action;
    }

    handleAccountStatusAction(key) {
        const { first_name, last_name } = this.user.getUser();
        this.accountStatuses[key].action().then(() => {
            this.upload.downloads.unshift({
                created: new Date(),
                downloadType: key,
                name: `${first_name} ${last_name}`.trim(),
            });
        });
    }
}

export default {
    bindings: {
        upload: '<',
        showDownloadHistory: '@',
    },
    controller: AbuUploadSummaryTableComponentController,
    templateUrl:
        'admin/bulk-upload/abu-upload-summary-table/abu-upload-summary-table-component.html',
};
