import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { AssignmentSettingsModal } from 'frontend-ra-shared';

import assignmentStates from '../../assignment-states';

const DEFAULT_PROPS = {
    showCloseAssignmentButton: true,
    showSaveAssignmentButton: true,
    showReopenAssignmentButton: false,
    readOnly: false,
};

const PROPS = {
    [assignmentStates.closed]: {
        showCloseAssignmentButton: false,
        showSaveAssignmentButton: false,
        showReopenAssignmentButton: true,
        readOnly: true,
    },
    [assignmentStates.deleted]: {
        showCloseAssignmentButton: false,
        showSaveAssignmentButton: false,
        showReopenAssignmentButton: false,
        readOnly: true,
    },
    [assignmentStates.spotCheckInProgress]: {
        showCloseAssignmentButton: false,
        showSaveAssignmentButton: true,
        showReopenAssignmentButton: false,
        readOnly: false,
    },
};

class AssignmentSettingsModalController {
    // @ngInject
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.handleSaveChanges = this.handleSaveChanges.bind(this);

        if (!this.assignmentDueDate) return;
        const dateParts = this.assignmentDueDate.split('-');
        this.dueDate = new Date(dateParts[0], parseInt(dateParts[1] - 1), dateParts[2]);
    }

    handleSaveChanges(assignment) {
        this.onSaveAssignment({ assignment: assignment });
    }

    renderReact() {
        const component = (
            <IntlProvider locale="en">
                <AssignmentSettingsModal
                    name={this.assignmentName}
                    dueDate={this.dueDate}
                    onSaveAssignment={this.handleSaveChanges}
                    onCloseAssignment={this.onCloseAssignment}
                    onReopenAssignment={this.onReopenAssignment}
                    onDeleteAssignment={this.onDeleteAssignment}
                    {...PROPS[this.assignmentState] || DEFAULT_PROPS}
                />
            </IntlProvider>
        );
        ReactDOM.render(component, this.$element[0]);
    }

    $postLink() {
        this.renderReact();
    }
}

export default {
    controller: AssignmentSettingsModalController,
    template: '<div class="assignment-settings-modal"></div>',
    bindings: {
        assignmentId: '<',
        assignmentName: '<',
        assignmentDueDate: '<',
        assignmentState: '<',
        onSaveAssignment: '&',
        onCloseAssignment: '&',
        onReopenAssignment: '&',
        onDeleteAssignment: '&',
    },
};
