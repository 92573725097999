import angular from 'angular';

import createStudentAccount from './create-student-account/create-student-account';
import createStudentAccountSuccess from './create-student-account-success/create-student-account-success';
import changeStudentPassword from './change-student-password/change-student-password';
import editStudentAccount from './edit-student-account/edit-student-account';
import viewStudentAccount from './view-student-account/view-student-account';
import studentAccounts from './student-accounts/student-accounts';

const students = angular.module('students', [
    studentAccounts,
    createStudentAccount,
    createStudentAccountSuccess,
    changeStudentPassword,
    editStudentAccount,
    viewStudentAccount,
]);

export default students.name;
