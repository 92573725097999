/**
 * Utility functions for working with an assignment.
 * There are separate validate methods for create vs edit because of naming.
 * When creating an assignment there is a `dueDate` field but when editing
 * there is a `due_date` field.
 */
export default () => {
    const hasDueDate = assignmentType => assignmentType !== 'TM';

    const createValidationError = message => ({ message });

    const validate = ({ name, dueDate, formDueDate }) => {
        if (!name) {
            return createValidationError('Please complete all required fields.');
        }

        if (name.length > 100) {
            return createValidationError('The assignment name must be 100 or fewer characters.');
        }

        if (formDueDate && formDueDate.$invalid) {
            return createValidationError(
                'Sorry, the date you have chosen is not valid. Please select a different date.'
            );
        }

        const startOfToday = new Date();
        startOfToday.setHours(0, 0, 0, 0);
        if (dueDate && dueDate < startOfToday) {
            return createValidationError(
                'Please choose a different date. You cannot create an assignment with past date.'
            );
        }

        return null;
    };

    const validateCreate = ({ assignment, formDueDate }) => {
        return validate({
            name: assignment.name,
            dueDate: assignment.dueDate,
            formDueDate,
        });
    };

    const validateEdit = ({ assignment, formDueDate }) => {
        return validate({
            name: assignment.name,
            dueDate: assignment.due_date,
            formDueDate,
        });
    };

    return {
        hasDueDate,
        validateCreate,
        validateEdit,
    };
};
