import angular from 'angular';
import ngSanitize from 'angular-sanitize';

import fileInputDirective from './file-input-directive';
import fileInputChangeCheckerDirective from './file-input-change-checker-directive';
import fileSelect from './file-select-component';

const fileInput = angular.module('fileInput', [ngSanitize]);

fileInput.directive('fileInput', fileInputDirective);
fileInput.directive('fileInputChangeChecker', fileInputChangeCheckerDirective);
fileInput.component('fileSelect', fileSelect);

export default fileInput.name;
