import _ from 'lodash';

function getDefaultModel() {
    return {
        signInWithEmail: null,
        canReceiveEmails: null,
        includeUsernameSuffix: null,
        usernameSuffix: null,
        includesStudentIdNumber: null,
    };
}

class AccountCreationSettingsStudentController {
    $onInit() {
        const initialModel = _.pick(this.initialModel, Object.keys(getDefaultModel()));
        this.model = _.assign(getDefaultModel(), initialModel);
    }

    back() {
        this.onBack({ model: this.model });
    }

    transformSubmittedModel(model) {
        if (model.signInWithEmail) {
            return _.assign({}, model, {
                includeUsernameSuffix: null,
                usernameSuffix: null,
            });
        }

        const noEmailModel = _.assign({}, model, { canReceiveEmails: null });

        if (!model.includeUsernameSuffix) {
            return _.assign({}, noEmailModel, { usernameSuffix: null });
        }

        return noEmailModel;
    }

    saveAndContinue() {
        if (this.form.$valid) {
            const model = this.transformSubmittedModel(this.model);
            this.onSaveAndContinue({ model });
        }
    }
}

export default {
    bindings: {
        initialModel: '<',
        onBack: '&',
        onSaveAndContinue: '&',
    },
    controller: AccountCreationSettingsStudentController,
    templateUrl:
        'admin/bulk-upload/bulk-upload-wizard/account-creation-settings-student/account-creation-settings-student.html',
};
