class AdminChangePasswordController {
    // @ngInject
    constructor($state) {
        this.$state = $state;

        this.targetUser.full_name = `${this.targetUser.first_name} ${this.targetUser.last_name}`;
    }

    $onInit() {
        // TODO update teacher and student account viewing to use group rather than school
        if (this.group || !this.schoolId) return;
        this.group = {
            type: 'school',
            id: this.schoolId,
        };
    }

    _navigateToState(stateName) {
        this.$state.go(stateName, {
            identityId: this.targetUser.identity_id,
            [`${this.group.type}Id`]: this.group.id,
        });
    }

    onSuccess() {
        this._navigateToState(this.successState);
    }

    onCancel() {
        this._navigateToState(this.cancelState);
    }
}

const adminChangePasswordComponent = {
    templateUrl: 'admin/school/components/admin-change-password/admin-change-password.html',
    controller: AdminChangePasswordController,
    bindings: {
        successState: '@',
        cancelState: '@',
        schoolId: '<',
        targetUser: '<',
        group: '<',
    },
};

export default adminChangePasswordComponent;
