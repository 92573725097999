import angular from 'angular';

import adminOnboardingWalkthroughComponent from './admin-onboarding-walkthrough-component';

const adminOnboardingWalkthrough = angular.module('adminOnboardingWalkthrough', []);
adminOnboardingWalkthrough.component(
    'adminOnboardingWalkthrough',
    adminOnboardingWalkthroughComponent
);

export default adminOnboardingWalkthrough.name;
