import _ from 'lodash';

export default class TeacherClassAddController {
    // @ngInject
    constructor(teacherService, $state, eventMediator, utils, user, notifyDataLossService, $scope) {
        this.teacherService = teacherService;
        this.$state = $state;
        this.eventMediator = eventMediator;
        this.notifyDataLossService = notifyDataLossService;
        this.user = user;

        this.gradeOptions = utils.classGradeOptions;
        this.subjectOptions = utils.classSubjectOptions;
        this.teacherClass = {};

        this.dataComparator = this.notifyDataLossService.anticipateDataLoss(
            $scope,
            this,
            'teacherClass'
        );
    }

    emitMessage(type, message) {
        this.eventMediator.emit('messageNotification', {
            type,
            message,
        });
    }

    showValidationErrors(message) {
        this.emitMessage('danger', message);
        this.form.showErrors = true;
    }

    getSchools() {
        if (!this.user.getUser()) return [];

        if (!this.schools) this.schools = this.user.getMultipleMemberships('Instructor');

        return this.schools;
    }

    onSaveClassSuccess(res) {
        this.emitMessage('success', 'Your Class Settings were updated!');

        this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);
        this.$state.go('teacher.class.created', { classId: res.id });
    }

    cancel() {
        this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);
        this.$state.go('teacher.home');
    }

    getValidationMessage(form) {
        if (_.get(form, 'classname.$error.maxlength')) {
            return 'Please limit your class name to 100 characters or fewer.';
        }

        return 'Please complete all required fields.';
    }

    save() {
        if (!this.form.$valid) {
            return this.showValidationErrors(this.getValidationMessage(this.form));
        }

        if (this.teacherClass.grade === 'Other') {
            this.teacherClass.grade = this.otherText.grade;
        }

        if (this.teacherClass.subject === 'Other') {
            this.teacherClass.subject = this.otherText.subject;
        }

        return this.teacherService
            .createClass(this.teacherClass)
            .then(this.onSaveClassSuccess.bind(this))
            .catch(() => this.showValidationErrors('Your class could not be created.'));
    }
}
