import angular from 'angular';

import bulkUploadWizard from './bulk-upload-wizard/bulk-upload-wizard';

import adminBulkUploadStudent from './admin-bulk-upload-student/admin-bulk-upload-student';
import adminBulkUploadTeacher from './admin-bulk-upload-teacher/admin-bulk-upload-teacher';
import abuProgressBar from './abu-progress-bar/abu-progress-bar';
import abuV2 from './admin-bulk-upload-v2/admin-bulk-upload';
import abuOrderOutcome from './abu-order-outcome/abu-order-outcome';
import abuOrderDetail from './abu-order-detail/abu-order-detail';
import abuAccountActions from './abu-account-actions/abu-account-actions';
import displayErrors from './display-errors/display-errors';

const bulkUpload = angular.module('bulkUpload', [
    bulkUploadWizard,
    adminBulkUploadStudent,
    adminBulkUploadTeacher,
    abuProgressBar,
    abuV2,
    abuOrderOutcome,
    abuOrderDetail,
    abuAccountActions,
    displayErrors,
]);

export default bulkUpload.name;
