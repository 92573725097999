export default class TeacherClassChangePasswordController {
    // @ngInject
    constructor($state, Student, TeacherClass) {
        this.$state = $state;
        this.student = Student;
        this.student.full_name = `${this.student.first_name} ${this.student.last_name}`;
        this.teacherClass = TeacherClass;
    }
    onSuccess() {
        this.$state.go('teacher.class.manage-student.info', {
            classId: this.teacherClass.id,
            studentId: this.student.identity_id,
        });
    }
    onCancel() {
        this.$state.go('teacher.class.manage-student.info', {
            classId: this.teacherClass.id,
            studentId: this.student.identity_id,
        });
    }
}
