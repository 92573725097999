class BulkUploadDisplayErrorsController {
    // @ngInject
    constructor($state) {
        this.$state = $state;
    }
}

export default {
    bindings: {
        missingHeaders: '<',
        unexpectedHeaders: '<',
        duplicatedHeaders: '<',
        onDownloadFile: '&',
        onUploadCorrectedFile: '&',
    },
    controller: BulkUploadDisplayErrorsController,
    templateUrl: 'admin/bulk-upload/display-errors/bulk-upload-display-errors.html',
};
