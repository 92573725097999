import angular from 'angular';

import shared from '../shared/shared';

import AdminBulkUploadTeacherController from './admin-bulk-upload-teacher-controller';

const adminBulkUploadTeacher = angular.module('adminBulkUploadTeacher', [shared]);

adminBulkUploadTeacher.controller(
    'AdminBulkUploadTeacherController',
    AdminBulkUploadTeacherController
);

export default adminBulkUploadTeacher.name;
