export default function ProviderService(urls, $http, $q, eventMediator) {
    'ngInject';

    function getLaunchParameters(url, data) {
        return $http({
            url: urls.base + url,
            method: 'POST',
            data: data,
        });
    }

    function launchProvider(res) {
        // TODO should rename this to something more generic
        // we are now launching more than assignments
        eventMediator.emit('launchAssignment');

        const launchForm = angular.element(
            `<form id="launch-form" action="${res.data.info
                .launch_url}" method="POST" encType="application/x-www-form-urlencoded"></form>`
        );

        _.forEach(res.data.params, (value, key) => {
            let elem = angular.element(`<input type="hidden" name="${key}"/>`);
            elem.attr('value', value); // Adding the value attribute later because if something had quotes it would crash because of interpolation.
            launchForm.append(elem);
        });

        launchForm.append(
            angular.element(
                '<button type="submit" class="btn btn-primary">launch assignment</button>'
            )
        );
        launchForm.css('display', 'none');
        angular
            .element(document)
            .find('body')
            .eq(0)
            .append(launchForm);
        launchForm[0].submit();
    }

    return {
        getAssignmentLaunchParameters(assignmentId, role) {
            return getLaunchParameters(`/ra/assignments/${assignmentId}/launch-parameters`, {
                launch_role: role,
            });
        },
        getCreateAssignmentLaunchParameters(classId) {
            return getLaunchParameters(`/ra/assignments/create/launch-parameters`, {
                class_id: classId,
            });
        },
        getReportsLaunchParameters() {
            return getLaunchParameters('/ra/assignments/reports/launch-parameters');
        },
        getDistrictPlanLaunchParameters() {
            return getLaunchParameters('/ra/assignments/districtplans/launch-parameters');
        },
        launchAssignmentById(assignmentId, role) {
            return this.getAssignmentLaunchParameters(assignmentId, role).then(launchProvider);
        },
        launchReports() {
            return this.getReportsLaunchParameters().then(launchProvider);
        },
        launchDistrictPlan() {
            return this.getDistrictPlanLaunchParameters().then(launchProvider);
        },
        launchCreateAssignment(classId) {
            return this.getCreateAssignmentLaunchParameters(classId).then(launchProvider);
        },
    };
}
