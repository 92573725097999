import angular from 'angular';

import bulkUploadService from './bulk-upload-service';
import bulkUploadOrderTransformerFactory from './bulk-upload-order-transformer';

import urlModule from '../../../url-config/url-config';
import adminShared from '../../shared/shared';
import spreadsheet from '../../../spreadsheet/spreadsheet';

const adminBulkUploadShared = angular.module('adminBulkUploadShared', [
    adminShared,
    urlModule,
    spreadsheet,
]);

adminBulkUploadShared.factory('bulkUploadService', bulkUploadService);
adminBulkUploadShared.factory('bulkUploadOrderTransformer', bulkUploadOrderTransformerFactory);

export default adminBulkUploadShared.name;
