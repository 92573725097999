import angular from 'angular';
import _ from 'lodash';
import eventMediator from 'gong-event-mediator';
import uiRouter from 'angular-ui-router';

import user from '../../user/user';

const _2018SATPilot = angular.module('2018SATPilot', [eventMediator, uiRouter, user]);
_2018SATPilot.run(
    /* @ngInject */
    ($rootScope, eventMediator, preferences, user) => {
        function isUserIn2018Pilot() {
            return _.some(user.getGroupIds(), id => preferences.is2018SATPilotEnabled(id));
        }

        $rootScope.$on('$stateChangeSuccess', () => {
            const isInstructorInSatPilot = user.isInstructor() && isUserIn2018Pilot();
            if (!isInstructorInSatPilot) return;

            eventMediator.emit('messageNotification', {
                message: `
                    <strong>Important!</strong> <a
                        class="no-space"
                        target="_blank"
                        href="https://collegeboard.co1.qualtrics.com/jfe/form/SV_004Ix8hmDPIMbbL"
                    >
                        Please click here to provide your contact information.
                    </a>
                `,
                preventCloseAction: true,
            });
        });
    }
);

export default _2018SATPilot.name;
