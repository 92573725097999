import _ from 'lodash';

export default class TeacherClassEditStudentController {
    // @ngInject
    constructor(
        $state,
        eventMediator,
        teacherService,
        teacherClass,
        targetUser,
        notifyDataLossService,
        $scope
    ) {
        this.$state = $state;

        this.eventMediator = eventMediator;
        this.teacherService = teacherService;
        this.notifyDataLossService = notifyDataLossService;

        this.originalTarget = _.cloneDeep(targetUser);
        this.targetUser = _.cloneDeep(targetUser);
        this.targetUser.full_name = `${this.targetUser.first_name} ${this.targetUser.last_name}`;
        this.teacherClass = teacherClass;

        this.showEmail = !!this.originalTarget.email;
        this.showUsername = !!this.originalTarget.username;
        this.requireEmail = !!this.originalTarget.email;
        this.requireUsername = !!this.originalTarget.username;

        this.dataComparator = this.notifyDataLossService.anticipateDataLoss(
            $scope,
            this,
            'targetUser'
        );
    }

    _handleSuccess() {
        const message = `You have successfully updated ${this.targetUser.full_name}'s account!`;
        this.eventMediator.emit('messageNotification', { type: 'success', message });

        this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);

        const stateParams = {
            classId: this.teacherClass.id,
            studentId: this.targetUser.identity_id,
        };
        this.$state.go('teacher.class.manage-student.info', stateParams, { reload: true });
    }

    _getValidationMessages() {
        return {
            'User with username exists': {
                field: 'username',
                key: 'duplicate',
                message:
                    'Sorry! There is an existing account that uses this username. Please enter a different username.',
            },
            'User with email exists': {
                field: 'email',
                key: 'duplicate',
                message:
                    'Sorry! There is an existing account that uses this email. Please enter a different email address.',
            },
            unknown: {
                message: 'There was an error updating this student. Please try again.',
            },
        };
    }

    _handleError(data) {
        let messages = this._getValidationMessages();

        let errors;
        if (data && data.errors) {
            errors = _.map(data.errors, e => {
                return messages[e.title];
            });
            errors = _.compact(errors);
        }

        if (!errors || !errors.length) {
            errors = [messages.unknown];
        }

        // Validation Alerts
        _(errors)
            .filter('message')
            .map('message')
            .forEach(message => {
                this.eventMediator.emit('messageNotification', { type: 'danger', message });
            });

        // Trigger inline validation messages
        _(errors)
            .filter('field')
            .forEach(e => {
                this.form[e.field].$setValidity(e.key, false);
            });

        this.form.showErrors = true;
    }

    _clearValidationErrors() {
        let messages = this._getValidationMessages();
        _.forEach(messages, e => {
            if (e.field && this.form[e.field]) {
                this.form[e.field].$setValidity(e.key, true);
            }
        });
    }

    save() {
        this._clearValidationErrors();
        if (this.form.$valid) {
            return this.teacherService
                .updateStudentAccount(this.targetUser)
                .then(this._handleSuccess.bind(this))
                .catch(this._handleError.bind(this));
        } else {
            const message = 'Please complete all required fields.';
            this.eventMediator.emit('messageNotification', { type: 'danger', message });
            this.form.showErrors = true;
        }
    }

    cancel() {
        this.notifyDataLossService.cancelDataLossAnticipation(this.dataComparator);
        this.$state.go('teacher.class.manage-student.info', this.$state.params, { reload: true });
    }
}
