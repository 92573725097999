import urls from '../url-config/url-config';

/**
 * Possible errors when a user first attempts to authenticate
 */
export const signInErrorCodes = {
    badCredentials: 'bad-credentials',
    badConnectionOrNetwork: 'bad-connection-or-network',
    unknown: 'unknown',
};

/**
 * Possible statuses as a result of successful authentication request
 */
export const signInStatus = {
    requiresSetup: 'requires-setup',
    authenticated: 'authenticated',
};

/**
 * Possible errors while a user is setting up his/her account.
 */
export const userSetupErrorCodes = {
    validationError: 'validation-error',
    unknown: 'unknown',
};

/**
 * Possible errors returned from Google's client lib for sign in.
 * The options are listed below this section in the docs:
 * https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleauthsigninoptions
 */
export const googleTokenErrorCodes = {
    popupClosed: 'popup_closed_by_user',
    accessDenied: 'access_denied',
    immediateFailed: 'immediate_failed',
};

export const googleSignInErrors = {
    [signInErrorCodes.badCredentials]: {
        type: signInErrorCodes.badCredentials,
        message:
            `Oops! Sign in using the fields below.` +
            ` To enroll in Google Sign On, contact your administrator.`,
    },
    [signInErrorCodes.badConnectionOrNetwork]: {
        type: signInErrorCodes.badConnectionOrNetwork,
        message:
            `Oops! There appears to be an issue with your network or internet connection.` +
            ` Please ask a teacher or IT administrator for help.`,
    },
    [signInErrorCodes.unknown]: {
        type: signInErrorCodes.unknown,
        message:
            `Oops! There was a problem signing you in.` +
            ` Please try again or, if this issue continues, visit our` +
            ` <a href='${urls.help}'>Help Center</a>.`,
    },
};

export const userSetupErrors = {
    [userSetupErrorCodes.unknown]: {
        type: userSetupErrorCodes.unknown,
        message:
            `Oops! An error occurred trying to complete your setup.` +
            ` Please try again or, if this issue continues, visit our` +
            ` <a href='${urls.help}'>Help Center</a>.`,
    },
};
