import _ from 'lodash';

const LOWERCASE_WORDS = ['or', 'the', 'a', 'an', 'and'];

function enforceLowercaseWords(text) {
    return _.reduce(
        LOWERCASE_WORDS,
        (transformedText, word) => {
            const regex = new RegExp(`\\b${word}\\b`, 'gi');
            return transformedText.replace(regex, word);
        },
        text
    );
}

function enforceCapitalFirstLetter(text) {
    return _.flow([
        () => text[0],
        firstLetter => firstLetter.toUpperCase(),
        capitalFirstLetter => `${capitalFirstLetter}${text.slice(1)}`,
    ])();
}

export default function() {
    return {
        restrict: 'A',
        scope: true,
        template: '{{ text }}',
        link(scope, element, attrs) {
            scope.text = _.flow([
                () => attrs.titleCase,
                text => text.toLowerCase(),
                text => _.startCase(text),
                enforceLowercaseWords,
                enforceCapitalFirstLetter,
            ])();
        },
    };
}
