const VALID_EMAIL_ADDRESS_TEST = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function emailDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {},
        link: function(scope, elem, attr, ngModel) {
            ngModel.$validators.email = email => {
                const shouldTest =
                    attr.required === true ||
                    (attr.required === false && email && email.trim().length);
                return shouldTest ? VALID_EMAIL_ADDRESS_TEST.test(email) : true;
            };
        },
    };
}
