class SchoolsTableController {
    // @ngInject
    constructor(searchValidation) {
        this.searchValidation = searchValidation;

        this.filteredSchools = this.schools;
        this.searchInput = '';
        this.searchInputLast = '';
    }

    onSearchInputChanged() {
        // filter the items if either current or last search input is valid
        if (this.searchValidation.isSearchInputValid(this.searchInput, this.searchInputLast)) {
            this.filterSchools();
        }

        this.searchInputLast = this.searchInput;
    }

    filterSchools() {
        this.filteredSchools = this.schools.filter(school =>
            _.includes(
                _.toLower(school.name),
                _.toLower(
                    this.searchValidation.isValidLengthSearch(this.searchInput)
                        ? this.searchInput
                        : ''
                )
            )
        );
    }
}

const schoolsTableComponent = {
    templateUrl: 'admin/schools-table/schools-table-component.html',
    controller: SchoolsTableController,
    bindings: {
        schools: '<',
    },
};

export default schoolsTableComponent;
