const LOGIN_STATES = ['login', 'passwordReset', 'terms-of-service', 'google-sign-in'];

import { ADMIN_VIEW_USAGE_REPORT } from '../admin/admin-permissions';
import { isCurrentTeacherTrialUser } from '../payment/payment-authorization';

const ROLES_MAP = {
    Administrator: function(user) {
        if (!user.hasAdministratorRole()) return;

        return user.hasDistrictAdministratorRole()
            ? 'District Administrator'
            : 'School Administrator';
    },
    Instructor: function(user) {
        return user.hasInstructorRole() ? 'Teacher' : undefined;
    },
    Learner: function(user) {
        return user.hasLearnerRole() ? 'Student' : undefined;
    },
    'Implementation Specialist': function(user) {
        return user.hasImplementationSpecialistRole() ? 'Implementation Specialist' : undefined;
    },
};

const getUserRoles = function(user) {
    return _.reduce(
        ROLES_MAP,
        (roles, getLabel, type) => {
            const label = getLabel(user);
            if (!label) return roles;
            return [...roles, { type, label }];
        },
        []
    );
};

class HeaderController {
    // @ngInject
    constructor(
        $scope,
        user,
        $state,
        authService,
        urls,
        eventMediator,
        providerService,
        notifyDataLossService,
        preferences,
        $element,
        onboardingService,
        adminSidenavStore
    ) {
        this.eventMediator = eventMediator;
        this.user = user;
        this.$state = $state;
        this.authService = authService;
        this.urls = urls;
        this.providerService = providerService;
        this.notifyDataLossService = notifyDataLossService;
        this.$element = $element;
        this.$scope = $scope;
        this.onboardingService = onboardingService;
        this.adminSidenavStore = adminSidenavStore;
        this.preferences = preferences;

        this.onUserChange();
        this.eventMediator.subscribe($scope, 'login', this.onUserChange.bind(this));
        this.eventMediator.subscribe($scope, 'logout', this.onUserChange.bind(this));

        this.onRoleChange();
        this.eventMediator.subscribe($scope, 'auth.role.changed', this.onRoleChange.bind(this));

        this.showRelaunchOnboardingTooltip = false;
        this.eventMediator.subscribe(
            $scope,
            'onboarding.header.relaunchability-notification.show',
            () => this.setShowRelaunchOnboardingTooltip(true)
        );
        this.eventMediator.subscribe(
            $scope,
            'onboarding.header.relaunchability-notification.hide',
            () => this.setShowRelaunchOnboardingTooltip(false)
        );
    }

    setShowRelaunchOnboardingTooltip(show) {
        this.showRelaunchOnboardingTooltip = show;
        this.$scope.$digest(); // This seems to be necessary due to React-Angular interaction
    }

    onRoleChange() {
        this.refreshHelpMenu();
    }

    onUserChange() {
        this.refreshRoleDrawer();
        this.refreshHelpMenu();
    }

    refreshHelpMenu() {
        this.showHelpMenu = false;
        this.showRelaunchOnboarding = this.onboardingService.userHasOnboarding();
        this.helpUrl =
            this.user && isCurrentTeacherTrialUser(this.user)
                ? this.urls.teacherTrialHelp
                : this.urls.help;
    }

    refreshRoleDrawer() {
        this.drawer = this.drawer || {};
        this.drawer.expanded = false;
        this.drawer.roles = this.user && this.user.isAuthenticated() ? getUserRoles(this.user) : [];
    }

    isAuthenticated() {
        return !LOGIN_STATES.some(allowedStateName => this.$state.includes(allowedStateName));
    }

    getDisplayName() {
        if (!this.user || !this.user.isAuthenticated()) {
            return 'friend';
        } else if (this.user.hasInstructorRole() || this.user.hasAdministratorRole()) {
            return this.user.getUser().display_name;
        } else {
            return this.user.getUser().first_name;
        }
    }

    getMembershipInfo() {
        if (this.user.isLearner() && this.user.hasInstructorRole()) {
            return '(Student Demo Account)';
        }
        return '';
    }

    displayHome() {
        const usesSidenavInstead = this.user.hasImplementationSpecialistRole();
        const hideHomeButton = usesSidenavInstead || this.drawer.expanded;
        return !hideHomeButton;
    }

    isHome() {
        let home = this.user.getHomeState();
        return home && home.toState ? this.$state.is(home.toState) : false;
    }

    isReports() {
        return this.$state.is('admin.usage-report');
    }

    isAccounts() {
        return (
            this.adminSidenavStore.isStudentRouteSelected() ||
            this.adminSidenavStore.isTeacherRouteSelected() ||
            this.adminSidenavStore.isSchoolAdminRouteSelected() ||
            this.adminSidenavStore.isDistrictAdminRouteSelected()
        );
    }

    goHome() {
        let home = this.user.getHomeState();
        return this.$state.go(home.toState, home.toParams);
    }

    signOut() {
        // Explicitly call notifyDataLossService here.
        // Without the explicit call the user is de-authenticated prior
        // to notifiying of data loss.
        this.notifyDataLossService.navigate().then(() => {
            // TODO: uncouple this and have the logout function be passed as a parameter to the directive with '&'

            this.authService.logOutAndRedirect();
        });
    }

    isActive(icon) {
        if (icon === 'home') {
            return this.isHome();
        }

        if (icon === 'reports') {
            return this.isReports();
        }

        if (icon === 'accounts') {
            return this.isAccounts();
        }

        return false;
    }

    toggleDrawer() {
        if (this.user.isMultiRole()) this.drawerToggleHandler(!this.drawer.expanded);
    }

    drawerToggleHandler(expanded) {
        this.drawer.expanded = expanded;
    }

    switchRole(role) {
        this.user.setCurrentRole(role);
        this.goHome().then(() => this.eventMediator.emit('auth.role.changed.transitioned-home'));
    }

    launchReports() {
        if (this.user.isAdministrator()) {
            return this.launchAdminUsageReports();
        }

        if (this.user.isInstructor()) {
            return this.launchClassReports();
        }
    }

    launchAdminUsageReports() {
        return this.$state.go('admin.usage-report');
    }

    launchClassReports() {
        // Explicitly call notifyDataLossService here.
        // Launching the provider doesn't trigger the event used
        // to indicate state navigation.
        return this.notifyDataLossService.navigate().then(() => {
            return this.providerService.launchReports();
        });
    }

    launchDistrictPlan() {
        // Explicitly call notifyDataLossService here.
        // Launching the provider doesn't trigger the event used
        // to indicate state navigation.
        return this.notifyDataLossService.navigate().then(() => {
            return this.providerService.launchDistrictPlan();
        });
    }

    launchAccounts() {
        this.adminSidenavStore.goToAccountsPage();
    }

    toggleHelpMenu() {
        this.showHelpMenu = !this.showHelpMenu;
    }

    launchQuickstartTour() {
        this.showHelpMenu = false;
        this.eventMediator.emit('onboarding.show');
    }

    showReportsAction() {
        if (this.drawer.expanded) return false;
        if (this.user.isInstructor()) return true;

        if (
            this.user.isAdministrator() &&
            this.user.hasGeneralPermission(ADMIN_VIEW_USAGE_REPORT)
        ) {
            return true;
        }

        return false;
    }

    showArchivedClassesAction() {
        return this.user.isInstructor() && !this.drawer.expanded;
    }

    showAccountsAction() {
        if (this.drawer.expanded) return false;

        if (this.user.isAdministrator()) {
            return true;
        }

        return false;
    }

    showDistrictPlanAction() {
        if (this.drawer.expanded) return false;
        if (this.user.isAdministrator()) {
            return true;
        }

        return false;
    }
}

const header = function() {
    return {
        scope: {},
        templateUrl: 'header/header.html',
        controller: HeaderController,
        controllerAs: '$ctrl',
    };
};

export default header;
