import angular from 'angular';
import uiRouter from 'angular-ui-router';
import EventMediator from 'gong-event-mediator';

import urlConfig from '../url-config/url-config';
import user from '../user/user';
import utils from '../utils/utils';

import loginRoutes from './login-routes';
import LoginController from './login-controller';
import LoginSacController from './login-sac-controller';
import loginShared from './shared/shared';
import changePassword from './password-reset/change-password/change-password';
import UnauthorizedController from './unauthorized-controller';
import passwordReset from './password-reset/password-reset';
import googleSignIn from './google-sign-in/google-sign-in';
import loginFactory from './login-factory';

const login = angular.module('login', [
    loginShared,
    uiRouter,
    urlConfig,
    user,
    passwordReset,
    utils,
    EventMediator,
    changePassword,
    googleSignIn,
]);
login.config(loginRoutes);
login.controller('LoginController', LoginController);
login.controller('LoginSacController', LoginSacController);
login.controller('UnauthorizedController', UnauthorizedController);
login.factory('loginFactory', loginFactory);
login.run(function($rootScope) {
    'ngInject';

    $rootScope.$on('$stateChangeSuccess', (event, toState) => {
        $rootScope.appRootStyleOverrides = _.get(toState, 'data.appRootStyleOverrides', '');
    });
});

export default login.name;
