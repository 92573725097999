/**
 * Customizes an assignment tile for the teacher view.
 * Very similar to a student view, but adds the ability to edit.
 */
class TeacherAssignmentTileController {
    // @ngInject
    constructor($state, assignmentTileHelper) {
        this.$state = $state;
        this.assignmentTileHelper = assignmentTileHelper;
    }

    $onChanges(changes) {
        if (!changes.assignment) return;

        this.tileCssClasses = this.assignmentTileHelper.getAssignmentClasses(this.assignment);
        this.assignmentTypeText = this.assignmentTileHelper.getAssignmentTypeText(this.assignment);
        this.assignmentState = this.assignmentTileHelper.getAssignmentStateText(this.assignment);
        this.assignmentStateType = this.assignmentTileHelper.getAssignmentStateType(
            this.assignment
        );
        this.editIconSrc = this.assignmentTileHelper.getAssignmentTypeGear(
            this.assignment.assignment_type
        );
        this.showAssignmentType = this.assignment.state !== 'closed';
    }

    handleAssignmentEdit() {
        this.onAssignmentEdit({ assignment: this.assignment });
    }

    handleLaunchAssignment() {
        this.launchAssignment({ assignmentId: this.assignment.id });
    }
}

export default {
    templateUrl: 'teacher/home/teacher-assignment-tile-component.html',
    controller: TeacherAssignmentTileController,
    bindings: {
        assignment: '<',
        launchAssignment: '&',
        onAssignmentEdit: '&',
    },
};
