import fp from 'lodash/fp';

class AccountsComponentController {
    // @ngInject
    constructor(
        eventMediator,
        pluralize,
        accountDeletionModal,
        $document,
        user,
        preferences,
        searchValidation
    ) {
        this.eventMediator = eventMediator;
        this.pluralize = pluralize;
        this.accountDeletionModal = accountDeletionModal;
        this.$document = $document;
        this.currentUser = user;
        this.preferences = preferences;
        this.searchValidation = searchValidation;
        this.searchInput = '';
        this.searchInputLast = '';
        this.licenseInUse = 0;
        this.licenseShown = 0;
    }

    $onInit() {
        this.setAccountsPageInfo();
        this.licenseInUse = _.get(this.accountsPage, 'items.total', 0);
        this.licenseShown = _.get(this.accountsPage, 'items.total', 0);
        this.filteredAccounts = this.accounts;

        // This will go away and always be true
        this.bulkUploadIsTurnedOn = this.preferences.isBulkUploadEnabled(this.groupId);

        this.searchInput = '';
        this.searchInputLast = '';
    }

    $onChanges(changes) {
        if (changes.accountsPage) {
            this.setAccountsPageInfo();
            this.licenseShown = _.get(this.accountsPage, 'items.total', 0);
            this.filteredAccounts = this.accounts;
        }
    }

    onSearchInputChanged() {
        // filter the items if either current or last search input is valid
        if (this.searchValidation.isSearchInputValid(this.searchInput, this.searchInputLast)) {
            this.filterAccounts();
        }

        this.searchInputLast = this.searchInput;
    }

    filterAccounts() {
        this.changePage(
            1,
            this.searchValidation.isValidLengthSearch(this.searchInput) ? this.searchInput : ''
        );
    }

    filterStatus() {
        if (this.licenseShown == this.licenseInUse) return '';
        else return this.licenseShown.toString();
    }

    setAccountsPageInfo() {
        this.currentPage = _.get(this.accountsPage, 'page.current', 1);
        this.totalPages = _.get(this.accountsPage, 'page.last', 1);
        this.accounts = _.get(this.accountsPage, 'identities', []);
    }

    removeAccount(account) {
        if (account.identity_id === this.currentUser.getUser().identity_id) return;

        this.accountDeletionModal.presentModal(account, this.role).then(() => {
            this.onRemove({ account });
        });
    }

    changePage(page, search) {
        this.onPageChange({ page, search });
        this.$document.duScrollTopAnimated(0, 400, x => x * (2 - x));
    }

    getAccountsCountLabel() {
        return this.licenseInUse === 1 ? `1 account` : `${this.licenseInUse} accounts`;
    }

    accountType() {
        const label = this.pluralize.pluralizeOrNull(
            () => `${this.role} Record`,
            () => `${this.role} Records`,
            this.accounts.length
        );
        return label;
    }

    hasAccessToBulkUpload() {
        const isV2Enabled = this.preferences.isBulkUploadV2Enabled(this.groupId);

        const hasBulkUploadRole =
            (this.currentUser.isSchoolAdministrator() && isV2Enabled) ||
            this.currentUser.isDistrictAdministrator() ||
            this.currentUser.isImplementationSpecialist();

        const targetUsersCanBeUploaded = fp.some(canUpload => canUpload(this.role))([
            role => role === 'student',
            role => role === 'teacher' && isV2Enabled,
        ]);
        return this.bulkUploadIsTurnedOn && hasBulkUploadRole && targetUsersCanBeUploaded;
    }
}

export default {
    bindings: {
        accountsPage: '<',
        onRemove: '&',
        onPageChange: '&',
        disableAccountCreation: '<',
        createAccountRef: '@',
        createBulkAccountsRef: '@',
        getManageAccountSref: '&', // For declaration: get-manage-account-sref="$ctrl.fnName({accountId})"
        groupId: '<',
        role: '@',
    },
    controller: AccountsComponentController,
    templateUrl: 'admin/school/components/accounts/accounts-component.html',
};
